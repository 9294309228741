import { Component, OnInit } from '@angular/core';
import { HttpclientService } from 'src/app/modules/application/httpclient/httpclient.service';
import { environment } from '../../../../../../../src/environments/environment';

@Component({
  selector: 'app-single-infographics-events',
  templateUrl: './single-infographics-events.component.html',
  styleUrls: ['./single-infographics-events.component.scss']
})
export class SingleInfographicsEventsComponent implements OnInit {

  loaded: boolean;
  eventDatas:  any;
  envSiteName:any;

  constructor(private httpclientService: HttpclientService) {
    this.envSiteName=environment.siteName;
   }

  ngOnInit(): void {
    this.newsEventData();
  }
  newsEventData(): void {
    this.loaded = false;
    this.httpclientService.getHttpclient('https://api.nathanark.com/api/SingleInfographics/GetSingleInfographicsTop3Events?siteName='+this.envSiteName)
      .subscribe(
        items => {
          this.eventDatas = items;
          this.loaded = true;
          //console.log("Event Data:", this.eventDatas);
        });
  }

}
