<div class="row" *ngIf='loaded==false'>
    <div class="col-md-4 pb-5" *ngFor="let d of dumArray">
        <list-content-loader></list-content-loader>
    </div>
</div>
<div class="row">
    
    <div class="col-md-9">
        <h2 class="text-uppercase mb-0 mt-2">C-Suite On Deck</h2>
        <div class="row d-md-flex mt-3" *ngIf="loaded">
            <div class="col-md-6 mb-5" *ngFor="let allcSuiteOnDeckData of allcSuiteOnDeckDatas | paginate : {
        itemsPerPage: pageSize,
        currentPage: page,
        totalItems: count
      };
let i = index
" [class.active]="i == currentIndex">

<a class="cursor-p" href='{{allcSuiteOnDeckData.RouteURL}}' target="_blank">
                <!-- <a class="cursor-p" href='/{{allcSuiteOnDeckData.RouteURL}}/{{allcSuiteOnDeckData.InterviewID}}' target="_blank"> -->
                    <img class="img-fluid pb-2" [src]="allcSuiteOnDeckData.InterviewImage" alt="news image">

                    <h4 class="text-height-2 card-header-height-2 text-uppercase" [innerHTML]="allcSuiteOnDeckData.InterviewTitle">
                    </h4>
                </a>
                    <p class="para-2 mb-1">{{allcSuiteOnDeckData.Name}} | {{allcSuiteOnDeckData.InterviewDate}} </p>
                    <p class="para-1 text-justify mb-1 text-height-4" [innerHTML]="allcSuiteOnDeckData.InterviewDetails"></p>

                    <a class="para-2 para-2-c-2 " href='{{allcSuiteOnDeckData.RouteURL}}' target="_blank">Read More</a>

        </div>
    </div>
            <div class="row">
                <div class="col-12 p-0 text-center" *ngIf="count>10">
                    <pagination-controls previousLabel="Prev" nextLabel="Next" responsive="true" (pageChange)="handlePageChange($event)"></pagination-controls>
                </div>
            </div>
        
    </div>
    <div class="col-md-3">
        <app-spotlight-company></app-spotlight-company>
        <app-events-company></app-events-company>
    </div>
</div>