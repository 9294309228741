import { Component, OnInit } from '@angular/core';
import { HttpclientService } from 'src/app/modules/application/httpclient/httpclient.service';
import { environment } from '../../../../../../src/environments/environment';

@Component({
  selector: 'app-resources-event',
  templateUrl: './resources-event.component.html',
  styleUrls: ['./resources-event.component.scss']
})
export class ResourcesEventComponent implements OnInit {
  loaded: boolean;
  eventDatas:  any;
  envSiteName:any;

  constructor(private httpclientService: HttpclientService) { 
    this.envSiteName=environment.siteName;
  }

  ngOnInit(): void {
    this.newsEventData();
  }
  newsEventData(): void {
    this.loaded = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/GlobalReources/GetTop2GlobalReourcesEvents?siteName='+this.envSiteName)
      .subscribe(
        items => {
          this.eventDatas = items;
          this.loaded = true;
          //console.log("Event Data:", this.eventDatas);
        });
  }
}
