import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-spotlight-guest-authors',
  templateUrl: './spotlight-guest-authors.component.html',
  styleUrls: ['./spotlight-guest-authors.component.scss']
})
export class SpotlightGuestAuthorsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
