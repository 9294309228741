<div class="mid-container">
<h2>
    6 reasons to advertise your brand on
    <span id="sixreasonHeading">The {{domain | uppercase}} {{domain1}}</span>
</h2>
<p class="fontmobile">
    Do you have new announcements, an upcoming event, or a product launch coming soon?
    Are you looking for better ways to target and acquire new accounts? Leverage our
    digital tools and experience to reach and engage a specialized audience for your
    brand and product.
</p>
<div class="features">
    <div class="row">
        <div class="col-md-4 col-sm-6 contentWidth">
            <a class="circle-ico"><i class="fa fa-industry logomobile" aria-hidden="true">
                </i></a>
            <h3>
                Industry Outreach</h3>
            <p class="fontmobile">
                Promote your brand massively by leveraging our industry communities of {{industry_outreach}} professionals
            </p>
        </div>
        <div class="col-md-4 col-sm-6 contentWidth">
            <a class="circle-ico"><i class="fa fa-flask logomobile" aria-hidden="true">
                </i></a>
            <h3>
                Large Specialized Audience</h3>
            <p class="fontmobile">
                Target your potential customers from thousands of relevant decision makers and influencers</p>
        </div>
        <div class="col-md-4 col-sm-6 contentWidth">
            <a class="circle-ico"><i class="fa fa-link logomobile" aria-hidden="true"></i>
            </a>
            <h3>
                Omni-Channel Touchpoints</h3>
            <p class="fontmobile">
                Use multiple marketing channels and touchpoints to engage and nurture your targets</p>
        </div>
    
        <div class="col-md-4 col-sm-6  contentWidth">
            <a class="circle-ico"><i class="fa fa-bolt logomobile" aria-hidden="true"></i>
            </a>
            <h3>
                High Visibility</h3>
            <p class="fontmobile">
                Provide high visibility of your brand with our limited ad inserts per page, direct
                email, and spotlights</p>
        </div>
        <div class="col-md-4 col-sm-6 contentWidth">
            <a class="circle-ico"><i class="fa fa-pie-chart logomobile" aria-hidden="true">
                </i></a>
            <h3>
                Customized Analytics</h3>
            <p class="fontmobile">
                Receive reporting and analytics across all your campaigns and media buys</p>
        </div>
        <div class="col-md-4 col-sm-6 contentWidth">
            <a class="circle-ico"><i class="fa fa-percent logomobile" aria-hidden="true">
                </i></a>
            <h3>
                Optimize ROI</h3>
            <p class="fontmobile">
                Maximize your ROI by using AI-powered tools for media buying recommendations</p>
        </div>
    </div>
</div>
<div class="brands">
    <h3>
        Promote Your Brand Using A Wide Range of Digital Tools</h3>
    <div class="row">
        <div class="col-md-12 col-sm-6 contentWidth bannermobile">
            <img src="https://media7.io/Advertise/PubSite/images/img-1.jpg">
            <h4 class="headingleft">High Traffic Display Ads</h4>
            <p class="fontmobile headingleft">
                Achieve massive brand exposure with over 15 million views per month by leveraging
                banners across our 30+ web properties, ads in direct email, and newsletter advertorials
            </p>
        </div>
        <div class="col-md-12 col-sm-6 contentWidth bannermobile">
            <img src="https://media7.io/Advertise/PubSite/images/img-2.jpg" class="fright">
            <h4 class="headingright">
                Direct Email</h4>
            <p class="headingright fontmobile">
                Reach your target audience directly in their inbox. Run account based marketing
                campaigns and targeting based on job titles, functions, firmographics, geo and several
                more filters.
            </p>
            <br>
        </div>
        <div class="col-md-12 col-sm-6 contentWidth bannermobile">
            <img src="https://media7.io/Advertise/PubSite/images/img-3.jpg">
            <h4 class="headingleft">Content Promotion</h4>
            <p class="fontmobile headingleft">
                Publish your brand and product content - like video, infographics, white papers,
                webinars, blogs – to engage with large audiences and generate inbound leads
            </p>
        </div>
        <div class="col-md-12 col-sm-6 contentWidth bannermobile">
            <img src="https://media7.io/Advertise/PubSite/images/img-4.jpg" class="fright">
            <h4 class="headingright">
                Brand Spotlights
            </h4>
            <p class="headingright fontmobile">
                Promote your brand’s leadership status through exclusive placements on company search,
                product categories and target professionals looking for quotes from you or the competition
            </p>
        </div>
        <div class="col-md-12 col-sm-6 contentWidth bannermobile">
            <img src="https://media7.io/Advertise/PubSite/images/img-5.jpg">
            <h4 class="headingleft">News and PR</h4>
            <p class="fontmobile headingleft">
                Announce new developments and product launches as they happen to the widest audience
                of over 10 million decision makers across 16 industries and market verticals
            </p>
        </div>
        <div class="col-md-12 col-sm-6 contentWidth bannermobile">
            <img src="https://media7.io/Advertise/PubSite/images/img-6.jpg" class="fright">
            <h4 class="headingright">
                Customer Events
            </h4>
            <p class="headingright fontmobile">
                Register new and existing customers to your events - like live webinars, product
                training, conferences and trade shows - to broaden and deepen your customer relationships
            </p>
        </div>
    </div>
</div>
<!-- <div class="community">
        <h4>
            The Community: At A Glance</h4>
        <div class="row">
            <div class="col-md-5 col-sm-6 col-xs-6 contentWidth bannermobile">
                <img id="btmImage1" src="https://media7.io/Advertise/Pubsite/BottomImages/re.report1.jpg"
                    style="border-width:0px;">
                <div class="fleft">
                    <h5 class="contentWidth">
                        News</h5>

                    <p>
                        <label>
                            <span id="lblFeatured">2442</span></label>featured
                    </p>
                    <p>
                        <label>
                            <span id="lblTrending">4207</span></label>trending
                    </p>
                </div>
            </div>
            <div class="col-md-5 col-sm-6 col-xs-6 contentWidth bannermobile">
                <img id="btmImage2" src="https://media7.io/Advertise/Pubsite/BottomImages/re.report2.jpg"
                    style="border-width:0px;">
                <div class="fleft contentWidth">
                    <h5>
                        Companies</h5>

                    <p>
                        <label>
                            <span id="lblcompanies">2019</span></label>companies
                    </p>
                    <p>
                        <label>
                            <span id="lblPeople">2903855</span></label>
                        people
                    </p>
                </div>
            </div>
            <div class="col-md-2 col-sm-2 col-xs-2 dispne">
            </div>
        </div>
        <div class="row">
            <div class="col-md-5 col-sm-6 col-xs-6 contentWidth bannermobile">
                <img id="btmImage3" class="brandscontent"
                    src="https://media7.io/Advertise/Pubsite/BottomImages/re.report3.jpg" style="border-width:0px;">
                <div class="fleft contentWidth">
                    <h5>
                        Events</h5>

                    <p>
                        <label>
                            <span id="lblliveweb">1053</span></label>live webinars
                    </p>
                    <p>
                        <label>
                            <span id="lblconferences">1032</span></label>conferences
                    </p>
                </div>
            </div>
            <div class="col-md-5 col-sm-6 col-xs-6 contentWidth bannermobile">
                <img id="btmImage4" class="brandscontent"
                    src="https://media7.io/Advertise/Pubsite/BottomImages/re.report4.jpg" style="border-width:0px;">
                <div class="fleft contentWidth">
                    <h5>
                        Resources</h5>

                    <p>
                        <label>
                            <span id="lblwhitepapers">1359</span></label>whitepapers
                    </p>
                    <p>
                        <label>
                            <span id="lblondemand">1214</span></label>on-demand webinars
                    </p>

                    <p>
                        <label>
                            <span id="lblvideos">1681</span></label>videos
                    </p>
                    <p>
                        <label>
                            <span id="lblinfographics">903</span></label>infographics
                    </p>

                    <p>
                        <label>
                            <span id="lblblog">3749</span></label>blog articles
                    </p>
                </div>
            </div>
            <div class="col-md-2 col-sm-2 col-xs-2 dispne">
            </div>
        </div>
    </div> -->
</div>