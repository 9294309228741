<h2 class="text-uppercase text-center mb-0 mt-md-3 SpotEveRes">
    Spotlight</h2>
<div class="text-center pt-3 spotDiv spotGDiv" *ngIf="loaded">
    
    <div class="cursor-p" >
        <a href="{{spotLightDatas.URL}}" target="_blank">
            <img [src]="spotLightDatas.ImageUrl" class="img-fluid home-spotlight-logo" alt="Responsive image">
            <h4  class="text-uppercase mt-2 text-center">{{spotLightDatas.Name}}</h4>
        </a>
    </div>
    <p class="text-justify para-1 spotlight-text-height spotlightPara" [innerHTML]="spotLightDatas.Description"></p>
</div>

<div class="text-center ADdiv " id="resospo" style="display:flex; justify-content:center; align-items:center">
    <!-- <iframe scrolling="none" frameborder="0" marginwidth="0" marginheight="0" style='min-height:266px; border: none;' ng-include [src]="GlobalRessourcesADAfterSpotlight"></iframe> -->
    <ins class="adsbygoogle" style="display:inline-block;width:300px;height:266px"
    data-ad-client="ca-pub-4564607713805390" data-ad-slot="7799528926"></ins>

</div>
<!-- <div class="text-center ADdiv displaynone" id="artspo" style="display:flex; justify-content:center; align-items:center">
    <iframe scrolling="none" frameborder="0" marginwidth="0" marginheight="0" style='min-height:266px; border: none;' ng-include [src]="GlobalArticlesADAfterSpotlight"></iframe>
</div>
<div class="text-center ADdiv displaynone" id="vidspo" style="display:flex; justify-content:center; align-items:center">
    <iframe scrolling="none" frameborder="0" marginwidth="0" marginheight="0" style='min-height:266px; border: none;' ng-include [src]="GlobalVideosADAfterSpotlight"></iframe>
</div>
<div class="text-center ADdiv displaynone" id="whispo" style="display:flex; justify-content:center; align-items:center">
    <iframe scrolling="none" frameborder="0" marginwidth="0" marginheight="0" style='min-height:266px; border: none;' ng-include [src]="GlobalWhitepapersADAfterSpotlight"></iframe>
</div>
<div class="text-center ADdiv displaynone" id="infospo" style="display:flex; justify-content:center; align-items:center">
    <iframe scrolling="none" frameborder="0" marginwidth="0" marginheight="0" style='min-height:266px; border: none;' ng-include [src]="GlobalInfographicsADAfterSpotlight"></iframe>
</div> -->