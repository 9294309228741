<div class="text-center add-center HideSliderMobile" id="reso">
    <!-- <iframe style='min-width: 743px; max-height:110px; border: none;' class="HideSliderMobile" ng-include [src]="GlobalResourcesTopAD"></iframe> -->
    <ins class="adsbygoogle" style="display:inline-block;width:728px;height:90px"
        data-ad-client="ca-pub-4564607713805390" data-ad-slot="7275649274"></ins>
</div>
<!-- <div class="text-center add-center displaynone" id="arti">
    <iframe style='min-width: 743px; max-height:110px; border: none;' class="HideSliderMobile" ng-include [src]="GlobalArticlesTopAD"></iframe>
</div>
<div class="text-center add-center displaynone" id="vide">
    <iframe style='min-width: 743px; max-height:110px; border: none;' class="HideSliderMobile" ng-include [src]="GlobalVideosTopAD"></iframe>
</div>
<div class="text-center add-center displaynone" id="whit">
    <iframe style='min-width: 743px; max-height:110px; border: none;' class="HideSliderMobile" ng-include [src]="GlobalWhitepapersTopAD"></iframe>
</div>
<div class="text-center add-center displaynone" id="info">
    <iframe style='min-width: 743px; max-height:110px; border: none;' class="HideSliderMobile" ng-include [src]="GlobalInfographicsTopAD"></iframe>
</div> -->

<router-outlet></router-outlet>
<!-- <app-articles-category-page></app-articles-category-page> -->

<!-- <div class="row">
    <div class="col-md-9">
        <router-outlet></router-outlet>
    </div>
    <div class="col-md-3">
         <app-square-ad-videos></app-square-ad-videos>
    </div>
</div> -->
