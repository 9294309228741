<p class="mb-3 mt-2 para-2-c-2" *ngIf="singleArticlesLoaded">
    <a routerLink="">Home</a> >
    <a routerLink="/resources">Resources</a> >
    <a routerLink="/resources/articles">Articles</a> >

    <span class="para-2-c-1" [innerHTML]=singleArticles.WhitePaperTitle></span>
</p>
<div class="row">
    <div class="col-md-9">
        <div *ngIf="singleArticlesLoaded" class="mb-5">
            <h1 [innerHTML]="singleArticles.WhitePaperTitle"></h1>
            <p *ngIf="isguestAuthor" class="para-4-bold">
                <a class="cursor-p para-2 para-2-c-2 text-uppercase" routerLink='/guest-contributors/{{authorName}}'> 
                    {{singleArticles.author | titlecase}} |
                </a> {{singleArticles.PublishingDate1}}
            </p>
            <p *ngIf="isguestAuthor==false" class="para-4-bold">
                {{singleArticles.author | titlecase}} | {{singleArticles.PublishingDate1}}
            </p>
            <img class="img-fluid pb-2" [src]="singleArticles.imageurl" alt="article image">
            <p class="pt-3 para-6 mb-0 text-justify" [innerHTML]="singleArticles.Description"></p>
            <a class="para-2 para-2-c-2 " target="_blank" *ngIf="readbutton" href="{{singleArticles.webpageurl}}">Read More</a>
        </div>
        <app-other-articles (refreshEmitter)="receiveRefresh($event)"></app-other-articles>
        <div class="text-center w-100">
            <a class="text-uppercase thm-btn mt-4 btn-theme-success w-50 pt-3 pb-3 white-color" href="resources/articles">
                More Articles
            </a>
        </div>

        <div class="share-div" (click)="shareClick()">
            <i class="fa fa-share-alt" aria-hidden="true"></i>
        </div>
        <div class="share-in" [@openClose]="isOpen ? 'open' : 'closed'">
            <a href="https://www.facebook.com/sharer.php?u={{fullUrl}}" target="_blank">
                <div class="share-inner f-icon">
                    <i class="fab fa-facebook-f"></i>
                </div>
            </a>
            <a href="https://www.linkedin.com/shareArticle?mini=true&url={{fullUrl}}" target="_blank">
                <div class="share-inner l-icon">
                    <i class="fab fa-linkedin-in"></i>
                </div>
            </a>
            <a href="https://twitter.com/share?url={{fullUrl}}" target="_blank">
                <div class="share-inner t-icon">
                    <i class="fab fa-twitter"></i>
                </div>
            </a>
        </div>

    </div>
    <div class="col-md-3">
        <app-single-infographics-spotlight></app-single-infographics-spotlight>

        <app-single-infographics-events></app-single-infographics-events>
    </div>
</div>