import { APP_ID, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { TransferState } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { HttpclientService } from 'src/app/modules/application/httpclient/httpclient.service';
import { environment } from '../../../../../../../src/environments/environment';

@Component({
  selector: 'app-sigle-guest-other-author',
  templateUrl: './sigle-guest-other-author.component.html',
  styleUrls: ['./sigle-guest-other-author.component.scss']
})
export class SigleGuestOtherAuthorComponent implements OnInit {

  href: string;
  lastString: string;
  otherAuthorsLoaded: boolean;
  otherAuthorsDatas: any;
  envSiteName:any;
  scrollToTop: number;
  pos: number;

  constructor(private state: TransferState,private httpclientService: HttpclientService,@Inject(PLATFORM_ID) private platformId: Object,
  @Inject(APP_ID) private appId: string,public router: Router) {
    this.envSiteName=environment.siteName;
   }

  ngOnInit(): void {
    this.otherAuthorsData();
  }
  otherAuthorsData(): void{
    
    this.href = this.router.url;
    this.lastString = this.href.substring(this.href.lastIndexOf('/') + 1);

    this.otherAuthorsLoaded = false;
    
    let str1: string =this.otherAuthorsDatas;

    this.httpclientService.getHttpclient('https://api.nathanark.com/api/GlobalGuestAuthors/GetGlobalGuestAuthors?siteName='+this.envSiteName+'&pageNumber=1')
    .subscribe(
      items => {
        
        
        this.otherAuthorsDatas = items;
        this.otherAuthorsLoaded = true;
        //console.log("other ---------------...////// AuthorsDatas", this.otherAuthorsDatas);
        
      }
    );

  }
  resetUsers(): void {
    this.otherAuthorsDatas = null;
    this.otherAuthorsLoaded = false;
    this.lastString = null;
    this.href = null;
  }

  handlePageChange(event): void {
    this.resetUsers();
    this.otherAuthorsData();
    this.onActivate();
  }
  onActivate() {
    this.scrollToTop = window.setInterval(() => {
        this.pos = window.pageYOffset;
        //console.log("pos value",this.pos)
        if (this.pos > 0) {
            window.scrollTo(0, this.pos - 40); // how far to scroll on each step
        } 
        else {
            window.clearInterval(this.scrollToTop);  
        }
    }, 2);
}
}
