<p class="mb-3 mt-2 para-2-c-2" *ngIf="singleVideosLoaded">
    <a routerLink="">Home</a> >
    <a routerLink="/resources">Resources</a> >
    <a routerLink="/resources/videos">Videos</a> >
    <span class="para-2-c-1" [innerHTML]=singleVideos.WhitePaperTitle></span>
</p>
<div class="row">
    <div class="col-md-9">
        <div *ngIf="singleVideosLoaded" class="mb-5">
            <p class="para-4-bold">{{singleVideos.Tag}}</p>
            <h1 [innerHTML]="singleVideos.WhitePaperTitle"></h1>


            <p class="para-4-bold">{{singleVideos.PublishingDate1}}</p>
            <div class="embed-responsive embed-responsive-16by9">
                <iframe class="embed-responsive-item" [src]="videoSrc" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
            </div>
            <!-- <iframe width="560" height="315" src="https://www.youtube.com/embed/{{singleVideos.VideoURL}}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->

            <p class="pt-3 para-6 mb-0 text-justify text-height-4" [innerHTML]="singleVideos.Description"></p>
            <!-- <a class="para-2 para-2-c-2" href="{{singleVideos.EmbededVideoURL}}" target="_blank">Watch Now</a> -->

        </div>

        <app-other-videos (refreshEmitter)="receiveRefresh($event)"></app-other-videos>

        <div class="text-center w-100">
            <a class="text-uppercase thm-btn mt-4 btn-theme-success w-50 pt-3 pb-3 white-color" target="_blank"  href="resources/videos">More
                videos</a>
        </div>

        <div class="share-div" (click)="shareClick()">
            <i class="fa fa-share-alt" aria-hidden="true"></i>
        </div>
        <div class="share-in" [@openClose]="isOpen ? 'open' : 'closed'">
            <a href="https://www.facebook.com/sharer.php?u={{fullUrl}}" target="_blank">
                <div class="share-inner f-icon">
                    <i class="fab fa-facebook-f"></i>
                </div>
            </a>
            <a href="https://www.linkedin.com/shareArticle?mini=true&url={{fullUrl}}" target="_blank">
                <div class="share-inner l-icon">
                    <i class="fab fa-linkedin-in"></i>
                </div>
            </a>
            <a href="https://twitter.com/share?url={{fullUrl}}" target="_blank">
                <div class="share-inner t-icon">
                    <i class="fab fa-twitter"></i>
                </div>
            </a>
        </div>
    </div>
    <div class="col-md-3">
        <app-single-infographics-spotlight></app-single-infographics-spotlight>

        <app-single-infographics-events></app-single-infographics-events>
    </div>
</div>