import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { SlickCarouselModule } from 'ngx-slick-carousel';

import { EventsComponent } from './events.component';
import { EventsRoutingModule } from './events-routing.module';
import { AllEventsComponent } from './all-events/all-events.component';
import { AllConferencesComponent } from './all-conferences/all-conferences.component';
import { AllWebinersComponent } from './all-webiners/all-webiners.component';
import { SpotlightEventsComponent } from './spotlight-events/spotlight-events.component';
import { ResourcesEventsComponent } from './resources-events/resources-events.component';
import { UpcomingConferencesComponent } from './all-conferences/upcoming-conferences/upcoming-conferences.component';
import { PastConferencesComponent } from './all-conferences/past-conferences/past-conferences.component';
import { LiveWebinersComponent } from './all-webiners/live-webiners/live-webiners.component';
import { OndemandWebinersComponent } from './all-webiners/ondemand-webiners/ondemand-webiners.component';
import { SquareAdEventsComponent } from './square-ad-events/square-ad-events.component';
import { FirstWordPipe } from './first-word.pipe';
import { NgxPaginationModule } from 'ngx-pagination';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { EventsSinglePageComponent } from './events-single-page/events-single-page.component';
import { OtherConferenceComponent } from './events-single-page/other-conference/other-conference.component';
import { WebinarSinglePageComponent } from './webinar-single-page/webinar-single-page.component';
import { OtherWebinarComponent } from './webinar-single-page/other-webinar/other-webinar.component';
import { OnDemandwebinarSinglePageComponent } from './on-demandwebinar-single-page/on-demandwebinar-single-page.component';
import { PastConferenceSinglePageComponent } from './past-conference-single-page/past-conference-single-page.component';
import { OtherPastConferenceComponent } from './past-conference-single-page/other-past-conference/other-past-conference.component';
import { OtherOnDemandComponent } from './on-demandwebinar-single-page/other-on-demand/other-on-demand.component';

@NgModule({
  declarations: [
    EventsComponent,
    AllEventsComponent,
    AllConferencesComponent,
    AllWebinersComponent,
    SpotlightEventsComponent,
    ResourcesEventsComponent,
    UpcomingConferencesComponent,
    PastConferencesComponent,
    LiveWebinersComponent,
    OndemandWebinersComponent,
    SquareAdEventsComponent,
    FirstWordPipe,
    EventsSinglePageComponent,
    OtherConferenceComponent,
    WebinarSinglePageComponent,
    OtherWebinarComponent,
    OnDemandwebinarSinglePageComponent,
    PastConferenceSinglePageComponent,
    OtherPastConferenceComponent,
    OtherOnDemandComponent,
    // SlickCarouselModule
  ],
  imports: [
    CommonModule,
    EventsRoutingModule,
    NgxPaginationModule,
    ContentLoaderModule
  ],
  exports: [
    EventsComponent
  ],
  providers: [
  ],
})
export class EventsModule { }
