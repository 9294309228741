<div class="row">
    <div class="text-center add-center HideSliderMobile" id="news">
        <!-- <iframe style='min-width: 743px; max-height:110px; border: none;' class="HideSliderMobile" ng-include [src]="GlobalNewsTopAD"></iframe> -->
        <ins class="adsbygoogle" style="display:inline-block;width:728px;height:90px"
            data-ad-client="ca-pub-4564607713805390" data-ad-slot="7275649274"></ins>
    </div>
    <!-- <div class="text-center add-center displaynone" id="fnews">
            <iframe style='min-width: 743px; max-height:110px; border: none;' class="HideSliderMobile" ng-include [src]="GlobalFeaturedNewsTopAD"></iframe>
        </div>
        <div class="text-center add-center displaynone" id="tnews">
            <iframe style='min-width: 743px; max-height:110px; border: none;' class="HideSliderMobile" ng-include [src]="GlobalTrendingNewsTopAD"></iframe>
        </div> -->
    <div class="col-md-9">
        <router-outlet>
        </router-outlet>
    </div>
    <div class="col-md-3">
        <app-spotlight-news></app-spotlight-news>
        <div class="text-center ADdiv " id="newsspo" style="display:flex; justify-content:center; align-items:center">
            <!-- <iframe scrolling="no" frameborder="0" marginwidth="0" marginheight="0"
                style='min-height:266px; border: none;' ng-include [src]="GlobalNewsADAfterSpotlight"></iframe> -->
            <ins class="adsbygoogle" style="display:inline-block;width:300px;height:266px"
                data-ad-client="ca-pub-4564607713805390" data-ad-slot="7799528926"></ins>
        </div>
        <!-- <div class="text-center ADdiv displaynone" id="fnewsspo" style="display:flex; justify-content:center; align-items:center">
            <iframe scrolling="no" frameborder="0" marginwidth="0" marginheight="0" style='min-height:266px; border: none;' ng-include [src]="GlobalFeaturedNewsADAfterSpotlight"></iframe>
        </div>
        <div class="text-center ADdiv displaynone " id="tnewsspo" style="display:flex; justify-content:center; align-items:center">
            <iframe scrolling="no" frameborder="0" marginwidth="0" marginheight="0" style='min-height:266px; border: none;' ng-include [src]="GlobalTrendingNewsADAfterSpotlight"></iframe>
        </div> -->
        <app-resources-news></app-resources-news>
        <app-events-news></app-events-news>
    </div>
</div>