import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FeaturedNewsComponent } from './featured-news/featured-news.component';
import { TrendingNewsComponent } from './trending-news/trending-news.component';
import { ArchiveNewsComponent } from './archive-news/archive-news.component';
import { GlobalNewsComponent } from './global-news/global-news.component';

import { NewsComponent } from './news.component';
import { NewsSinglePageComponent } from './news-single-page/news-single-page.component';

const routes: Routes = [
  {
    path: '', component: NewsComponent, children: [
      {
        path: 'news',
        loadChildren: () => import('./news.module')
        .then(mod => mod.NewsModule)
      },
      {path: '', component: GlobalNewsComponent},
      { path: 'featured-news',
       component: FeaturedNewsComponent
      },
      { path: 'trending-news', component: TrendingNewsComponent },
      // { path: 'archive-news', component: ArchiveNewsComponent },
      {
        path:':type/:title/:id', //:type is dynamic here
        component:NewsSinglePageComponent
      },
      {
        path: '',
        redirectTo: '/news',
        pathMatch: 'full'
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NewsRoutingModule { }
