
<h2 class="text-uppercase mb-0">Guest Authors</h2>
<div class="row d-md-flex mt-3" *ngIf="allglobalGuestAuthorsloaded">
    <div class="col-md-4 mb-5" *ngFor="let allglobalGuestAuthorsData of allglobalGuestAuthorsDatas | paginate : {
        itemsPerPage: pageSize,
        currentPage: page,
        totalItems: count 
      };
let i = index" [class.active]="i == currentIndex" (click)="setActiveTutorial(tutorial, i)">

        <div class="cursor-p" routerLink='/guest-contributors/{{allglobalGuestAuthorsData.RouteURL}}'>        
        <!-- <img  style="width: 625px;" [src]="https://img.youtube.com/vi/zQb-F1v4juo/maxresdefault.jpg" alt="Author"> -->
           <a target="_blank" routerLink='/guest-contributors/{{allglobalGuestAuthorsData.RouteURL}}'>
           <img class="img-fluid pb-2" [src]="allglobalGuestAuthorsData.ImageUrl" alt="Author" > 
           </a>
            <h4 class="text-height-2 text-uppercase" [innerHTML]="allglobalGuestAuthorsData.AuthorName"></h4>
            <p class="para-2 mb-1">{{allglobalGuestAuthorsData.JobTitle}}</p>
            <p class="para-1 text-justify mb-1 text-height-4" [innerHTML]="allglobalGuestAuthorsData.CompanyName"></p>
        
       </div>
   </div>
</div>
<div class="row">
    <div class="col-12 p-0 text-center" *ngIf="pagevalidation">
        <pagination-controls previousLabel="Prev" nextLabel="Next" responsive="true" (pageChange)="handlePageChange($event)"></pagination-controls>
    </div>
</div>