<p class="mb-3 mt-2 para-2-c-2" *ngIf="singleNewsLoaded">
    <a routerLink="/">Home</a> > <a routerLink="/news">News</a> >
    <a routerLink="/news/{{EventDifferentType1}}" class="text-capitalize"> {{singleNews.NewsTypeName}} </a>>
    <span class="para-2-c-1" [innerHTML]=singleNews.Title></span>
</p>
<div *ngIf="singleNewsLoaded" class="mb-5">
    <p class="para-2 mb-2">{{singleNews.Tag}}</p>
    <h1 [innerHTML]="singleNews.Title"></h1>
    <p class="para-4-bold">{{singleNews.Author}} | {{singleNews.Date}}</p>
    <img class="img-fluid" src="{{singleNews.ImageUrl}}" alt="{{singleNews.Title}}">
    <p class="pt-3 para-6 mb-0 text-justify" [innerHTML]="singleNews.Description"></p>
    <div *ngIf="isExternal" class="mb-5">
        <a class="para-2 para-2-c-2 para-4-bold cursor-p" *ngIf="ReadButton" href="{{singleNews.URL}}" target="_blank">Read More</a>
    </div>
</div>
<app-other-news (refreshEmitter)="receiveRefresh($event)" *ngIf="EventDifferentType1" [resourceTypeName]="EventDifferentType1"></app-other-news>
<div class="text-center w-100">
    <button class="text-uppercase thm-btn mt-4 btn-theme-success w-50 pt-3 pb-3" routerLink="/news/{{EventDifferentType1}}">More {{EventDifferentType1 | addDash}}</button>
</div>
<div class="share-div" (click)="shareClick()">
    <i class="fa fa-share-alt" aria-hidden="true"></i>
</div>
<div class="share-in" [@openClose]="isOpen ? 'open' : 'closed'">
    <a href="https://www.facebook.com/sharer.php?u={{fullUrl}}" target="_blank">
        <div class="share-inner f-icon">
            <i class="fab fa-facebook-f"></i>
        </div>
    </a>
    <a href="https://www.linkedin.com/shareArticle?mini=true&url={{fullUrl}}" target="_blank">
        <div class="share-inner l-icon">
            <i class="fab fa-linkedin-in"></i>
        </div>
    </a>
    <a href="https://twitter.com/share?url={{fullUrl}}" target="_blank">
        <div class="share-inner t-icon">
            <i class="fab fa-twitter"></i>
        </div>
    </a>
</div>