import { APP_ID, Component, EventEmitter, Inject, OnInit, Input, PLATFORM_ID } from '@angular/core';
import { TransferState ,makeStateKey} from '@angular/platform-browser';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { HttpclientService } from 'src/app/modules/application/httpclient/httpclient.service';
import { environment } from '../../../../../../../src/environments/environment';
const STATE_KEY_ITEMS = makeStateKey('items');

@Component({
  selector: 'app-single-related-news',
  templateUrl: './single-related-news.component.html',
  styleUrls: ['./single-related-news.component.scss']
})
export class SingleRelatedNewsComponent implements OnInit {

  @Input() CompanyNameNewsData:string;

  items: any = [];
  dumArray:any=[1,2,3,4,5,6,7,8,9];
  currentIndex = -1;
  title = '';
  page = 1;
  count = 0;
  pageSize = 4;
  href:string;
  lastString:string;
  singleRelatedNewsLoaded:  boolean;
  singleRelatedNews: any;
  firstString: string;
  envSiteName:any;
  scrollToTop: number;
  pos: number;

  constructor(private state: TransferState,private httpclientService: HttpclientService,@Inject(PLATFORM_ID) private platformId: Object,
  @Inject(APP_ID) private appId: string,public router:Router) {
    this.envSiteName=environment.siteName;
   }

  ngOnInit(): void {
    this.singleRelatedNewsData();
  }
  singleRelatedNewsData(): void{
    this.items = this.state.get(STATE_KEY_ITEMS, <any> []);
    this.href = this.router.url;
    this.lastString = this.href.substring(this.href.lastIndexOf('/') + 1);
    this.singleRelatedNewsLoaded = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/SingleCompanies/GetSingleCompanyRelatedNewsByCompany?siteName=' +this.envSiteName+'&companyName='+this.CompanyNameNewsData+'&pageNumber='+this.page)
      .subscribe(
        items => {
          const platform = isPlatformBrowser(this.platformId) ?
          'in the browser' : 'on the server';
          this.singleRelatedNews = items;
          this.count=this.singleRelatedNews[0].TotalDataCount;
          this.singleRelatedNewsLoaded = true;
          console.log("WFWKWFKWFKWwfwf value",this.singleRelatedNews)
          this.state.set(STATE_KEY_ITEMS, <any> items);
        });
  }

  redirect(getUrl:any,getId:any){
    this.firstString = getUrl.substring(0,5);
    if(this.firstString ==="https"){
      window.open(getUrl, "_blank");
    }else{
      this.router.navigate(['/news/featured-news/'+getUrl+"/"+getId]);
    }
  }

  resetUsers(): void {
    this.singleRelatedNews = null;
    this.singleRelatedNewsLoaded = false;
    this.lastString = null;
    this.href = null;
  }
  handlePageChange(event): void {
    this.resetUsers();
    this.page =Number(event);
    this.singleRelatedNewsData();
    this.onActivate();
  }
  onActivate() {
    this.scrollToTop = window.setInterval(() => {
        this.pos = window.pageYOffset;
        //console.log("pos value",this.pos)
        if (this.pos > 0) {
            window.scrollTo(0, this.pos - 40); // how far to scroll on each step
        } 
        else {
            window.clearInterval(this.scrollToTop);  
        }
    }, 2);
}
}
