import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CompaniesComponent } from './companies.component';
import { CompaniesRoutingModule } from './companies-routing.module';
import { MarketplaceComponent } from './marketplace/marketplace.component';
import { SpotlightCompanyComponent } from './spotlight-company/spotlight-company.component';
import { EventsCompanyComponent } from './events-company/events-company.component';
import { CSuiteondeckComponent } from './c-suiteondeck/c-suiteondeck.component';
import { AllCSuiteondeckComponent } from './all-c-suiteondeck/all-c-suiteondeck.component';
import { ThoughtLeadersComponent } from './thought-leaders/thought-leaders.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { SingleCompanyPageComponent } from './single-company-page/single-company-page.component';
import { SingleCSuiteondeckComponent } from './single-company-page/single-c-suiteondeck/single-c-suiteondeck.component';
import { SingleRelatedNewsComponent } from './single-company-page/single-related-news/single-related-news.component';
//import { SingleResourcesComponent } from './single-company-page/single-resources/single-resources.component';
import { SingleEventsComponent } from './single-company-page/single-events/single-events.component';
//import { SingleCSiuteInterviewComponent } from './single-c-siute-interview/single-c-siute-interview.component';
import { AllCompaniesComponent } from './all-companies/all-companies.component';
import { SingleCSuiteInterviewComponent } from './single-c-suite-interview/single-c-suite-interview.component';
import { SingleThoughtLeaderComponent } from './single-thought-leader/single-thought-leader.component';
import { SingleInterviewSpotlightComponent } from './single-interview-spotlight/single-interview-spotlight.component';
//import { SingleCSuiteInterviewComponent } from './all-s-suiteondeck/single-c-suite-interview/single-c-suite-interview.component';
import { SingleResourcesComponent } from './single-company-page/single-resources/single-resources.component';
import { SingleCompanyResourcesComponent } from './single-company-page/single-company-resources/single-company-resources.component';

@NgModule({
  declarations: [
    CompaniesComponent,
    MarketplaceComponent,
    SpotlightCompanyComponent,
    EventsCompanyComponent,
    CSuiteondeckComponent,
    AllCSuiteondeckComponent,
    ThoughtLeadersComponent,
    SingleCompanyPageComponent,
    SingleCSuiteondeckComponent,
    SingleRelatedNewsComponent,
    SingleResourcesComponent,
    SingleEventsComponent,
   // SingleCSiuteInterviewComponent,
   AllCompaniesComponent,
   SingleCSuiteInterviewComponent,
   SingleThoughtLeaderComponent,
   SingleInterviewSpotlightComponent,
   SingleCompanyResourcesComponent,
  ],
  imports: [
    CommonModule,
    CompaniesRoutingModule,
    NgxPaginationModule,
    ContentLoaderModule
  ],
  exports: [
    CompaniesComponent
  ],
  providers: [
  ],
})
export class CompaniesModule { }
