<ngx-spinner bdColor="rgb(255,0,0)" size="default" color="#fff" type="line-scale" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>
<!-- <div class="col-md-12">
        <app-horizontal-ad></app-horizontal-ad>
    </div> -->
<div class="text-center add-center HideSliderMobile">
    <!-- <iframe style='min-width: 743px; max-height:110px; border: none;' class="HideSliderMobile" ng-include [src]="HomeADDurl"></iframe> -->
    <!-- infotech_homead_top -->
        <ins class="adsbygoogle"
        style="display:inline-block;width:728px;height:90px"
        data-ad-client="ca-pub-4564607713805390"
        data-ad-slot="7275649274"></ins>
</div>
<div class="row">
    <div class="col-md-9 mt-3 mt-md-0 mb-0 mb-md-0">
        <app-banner></app-banner>
    </div>
    <div class="col-md-3">
        <app-spotlight></app-spotlight>
    </div>
</div>
<div class="row">
    <div class="col-md-9">
        <app-home-news></app-home-news>
        <app-home-events></app-home-events>
    </div>
    <div class="col-md-3">
        <app-home-resources></app-home-resources>
        <!-- <div class="text-center">
            <iframe style='min-width:350px; min-height:300px; border: none;' ng-include [src]="HomeADAfterWhitepaper"></iframe></div>
        <app-home-articles></app-home-articles> -->

    </div>
</div>