<h2 class="text-uppercase mb-0 mt-2">C-Suite On Deck</h2>


<div class="text-center" *ngIf="singleCSuiteLoaded">
    <div class="cursor-p" (click)="redirect(singleCSuite.RouteURL)">
        <a target="_blank" href='{{singleCSuite.RouteURL}}'>
            <img [src]="singleCSuite.InterviewImage" class="img-fluid" alt="Responsive image">
        </a>
    </div>
    <p class="text-justify para-1 spotlight-text-height" [innerHTML]=" singleCSuite.InterviewDetails"></p>
</div>
<div class="text-center pt-3" *ngIf="contactUs">

    <img [src]="imgDefault" class="img-fluid" alt="Responsive image">

    <p class="para-1 text-justify mb-1 text-height-4 pt-3">Feature market insights and perspectives from top C-Level executives, elite technology influencers and thought leaders from your company here. This signature initiative has garnered immense support from professionals who are always eager to consume
        thought leadership content.
    </p>
</div>
<div class="text-center" *ngIf="contactUs">
    <a class="para-2 para-2-c-2 float-left pt-2" href="https://media7.com/contact-us">Contact Us</a>
</div>