import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpclientService } from 'src/app/modules/application/httpclient/httpclient.service';
import { environment } from '../../../../../../src/environments/environment';

@Component({
  selector: 'app-spotlight-company',
  templateUrl: './spotlight-company.component.html',
  styleUrls: ['./spotlight-company.component.scss']
})
export class SpotlightCompanyComponent implements OnInit {
  spotLightDatas: any;
  loaded: boolean;
  firstString: string;
  envSiteName:any;

  constructor(private httpclientService: HttpclientService, public routeUrl: Router) {
    this.envSiteName=environment.siteName;
   }

  ngOnInit(): void {
    this.spotLightData();
  }
  redirect(getUrl:any){
    this.firstString = getUrl.substring(0,5);
    if(this.firstString ==="https"){
      window.open(getUrl, "_blank");
    }else{
      this.routeUrl.navigate(['/companies/all-companies/'+getUrl]);
    }
  }
  spotLightData(): void {
    this.loaded =false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/GlobalCompanies/GetGlobalCompanyTOP1SpotLight?siteName='+this.envSiteName)
    .subscribe(
      items => {
        this.spotLightDatas =items[0];
        this.loaded =true;
        //console.log("spotLight MSMDMSDMD Datas", this.spotLightDatas);
      }
    );
  }
}
