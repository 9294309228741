import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-cookie-popup',
  templateUrl: './cookie-popup.component.html',
  styleUrls: ['./cookie-popup.component.scss']
})
export class CookiePopupComponent implements OnInit {


  private isConsented: boolean=false;
  isShowDivIf:boolean=false;
  name:string="cookieconsent_status";

  constructor(private cookieService : CookieService) { }

  ngOnInit(): void {

    let acceptcheck = this.cookieService.check('cookieconsent_status');
    //let declinecheck = this.cookieService.check('decline');
   
    if(this.cookieService.check('cookieconsent_status')){
      this.isShowDivIf = !this.isShowDivIf;
    }
    else
    {
      this.isShowDivIf = this.isShowDivIf;
    }
  }

  // @HostListener('window:load', ['$event'])
  // UpdateClassesInChatBotAndScrollTop(event: Event) {
  //   let intervalFn =
  //     setInterval(() => {
  //       if ((<any>window).Intercom.booted) {
  //         let chatBot = document.getElementsByClassName('intercom-launcher')[0];
  //         let scrollToTop = document.getElementsByClassName('scroll-to-top')[0];
  //         if (chatBot)
  //         {
  //           chatBot.classList.remove('cookie-popup-active');
  //         }
  //         if (scrollToTop)
  //           scrollToTop.classList.remove('cookie-popup-active');
  //         if (!this.isShowDivIf) {
  //           if (chatBot) {
  //             clearInterval(intervalFn);
  //             chatBot.classList.add('cookie-popup-active');
  //           }
  //           else {
  //             console.log('chatbot not loaded');
  //           }
  //           if (scrollToTop)
  //             scrollToTop.classList.add('cookie-popup-active');
  //         }
  //         else
  //         {
  //           clearInterval(intervalFn);
  //         }
  //       }
  //     }, 500);
  // }

  deleteCookies(){
    this.cookieService.set('cookieconsent_status', 'decline');
    this.cookieService.deleteAll();
   this.isShowDivIf = !this.isShowDivIf;
   //this.cookieValue = this.cookieService.get('cookieconsent_status');

   const dateNow = new Date().getMonth()+1;

   this.declineCookie(this.name, "decline", 1);
    // setTimeout(() => {
    //   this.isShowDivIf = !this.isShowDivIf;
    //   alert(dateNow);
    // }, 5000);
   
  }
  storeCookies(){
   //let cookies = document.getElementById('cookiesMain');
   this.isShowDivIf = !this.isShowDivIf;
  //  this.cookieService.set('cookieconsent_status', 'accept');
   //this.cookieValue = this.cookieService.get('cookieconsent_status');

   const dateNow = new Date().getFullYear()+2;
  //  const dateNow1 = new Date();
  //  dateNow1.setMinutes(dateNow1.getMinutes() + 5);
  //  this.cookieService.set('isLoggedIn', 'true', dateNow1);
   // if(dateNow<dateNow1){
   //   this.isShowDivIf = !this.isShowDivIf;
   //   this.cookieService.set('cookieconsent_status', 'decline');
   // }
   // else{
   //   this.cookieService.set('cookieconsent_status', 'accept');
   // }
  //  this.name="cookieconsent_status";
   this.setCookie(this.name, "accept", 1);
    // setTimeout(() => {
    //   this.isShowDivIf = !this.isShowDivIf;
    //   window.location.reload();
    // }, 5000);
  }

  setCookie(cName, cValue, expDays) {
    let date = new Date();
   //date.setTime(date.getTime() + (expDays * 60 * 60 * 60 * 1000));
   date.setFullYear(date.getFullYear() + 2);
   const expires = "expires=" + date.toUTCString();
   document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";

   if(new Date()>=date){
    this.isShowDivIf = this.isShowDivIf;
   }
  }

  
  declineCookie(cName, cValue, expDays) {
    let date = new Date();
   date.setMonth(date.getMonth() +1);
   const expires = "expires=" + date.toUTCString();
   document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";

   if(new Date()>=date){
    this.isShowDivIf = this.isShowDivIf;
   }
  }



































  // deleteCookies(){
  //   this.cookieService.deleteAll();
    
  //   //this.cookieValue = this.cookieService.get('cookieconsent_status');
    
  //   const dateNow = new Date();
  //   const dateNow1 = new Date();
  //   dateNow1.setMinutes(dateNow1.getMinutes() + 5);
  //   this.cookieService.set('isLoggedIn', 'true', dateNow1);
  //   // if(dateNow<dateNow1){
  //   //   this.isShowDivIf = !this.isShowDivIf;
  //   //   this.cookieService.set('cookieconsent_status', 'decline');
  //   // }
  //   // else{
  //   //   this.cookieService.set('cookieconsent_status', 'accept');
  //   // }

  // }
  // storeCookies(){
  //   let cookies = document.getElementById('cookiesMain');
  //   this.cookieService.set('cookieconsent_status', 'accept');
  //   //this.cookieValue = this.cookieService.get('cookieconsent_status');
  //   // const dateNow = new Date();
  //   // dateNow.setMinutes(dateNow.getMinutes() + 1);
  //   // this.cookieService.set('isLoggedIn', 'true', dateNow);
  
  //   this.isShowDivIf = !this.isShowDivIf;
  //   // cookies.set('SESSION_TOKEN', sessionToken, 6(hours));
  //   // setTimeout(() => {
  //   //   this.isShowDivIf = !this.isShowDivIf;
  //   // }, 5000);
  // }

  // setCookie(userObject) {
  //   // let expire = new Date();
  //   // var time = Date.now() + ((3600 * 1000) * 6);
  //   // expire.setTime(time);
  //   // console.log("expire "+expire);

  //   const dateNow = new Date();
  //   dateNow.setHours(dateNow.getMinutes() + 1);
  //   this.cookieService.set('isLoggedIn', 'true', dateNow);    

  //   // Cookie.set('USER_NAME',Base64.encode(userObject.user.email),expire);
  //   // Cookie.set('SESSION_TOKEN',userObject.sessionToken,expire);
  // }

  // declineCookies(){

  // }

  // private consent(isConsent: boolean, e: any) {
  //   if (!isConsent) {
  //       return this.isConsented;
  //   } else if (isConsent) {
  //       //this.setCookie(COOKIE_CONSENT, '1', COOKIE_CONSENT_EXPIRE_DAYS);
  //       this.isConsented = true;
  //       e.preventDefault();
  //   }
  // }
  // private setCookie(name: string, value: string, expireDays: number, path: string = '') {
  //   let d:Date = new Date();
  //   d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
  //   let expires:string = `expires=${d.toUTCString()}`;
  //   let cpath:string = path ? `; path=${path}` : '';
  //   document.cookie = `${name}=${value}; ${expires}${cpath}`;
  // }
}
