<div class="row" *ngIf='allNewsGuestAuthorsLoaded==false'>
    <div class="col-md-6 pb-5" *ngFor="let d of dumArray">
        <list-content-loader></list-content-loader>
    </div>
</div>
<div class="row d-md-flex" *ngIf="allNewsGuestAuthorsLoaded">
    <div class="col-md-6 pb-5" *ngFor="
    let allNewsGuestAuthorsData of allNewsGuestAuthorsDatas | paginate : {
            itemsPerPage: pageSize,
            currentPage: page,
            totalItems: count
          };
    let i = index
  " [class.active]="i == currentIndex">
        <div class="cursor-p">
            <img class="img-fluid pb-2 image-hight-news" [src]="allNewsGuestAuthorsData.ImageUrl" alt="news image">
            <p class="para-2 mb-0">CORE re</p>
            <h4 class="text-height-4 card-header-height-1 text-uppercase" [innerHTML]="allNewsGuestAuthorsData.Title">
            </h4>
            <p class="para-2 mb-1">By {{allNewsGuestAuthorsData.Author}} | {{allNewsGuestAuthorsData.PublishingDate}} </p>
            <p class="para-1 text-justify mb-1 text-height-4" [innerHTML]="allNewsGuestAuthorsData.Description"></p>
            <a class="para-2 para-2-c-2 " href="">Read More</a>
        </div>
    </div>
</div>