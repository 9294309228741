import { Component, OnInit } from '@angular/core';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { HttpclientService } from 'src/app/modules/application/httpclient/httpclient.service';
import { environment } from '../../../../../../../src/environments/environment';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { CanonicalService } from 'src/app/canonical.service';

const STATE_KEY_ITEMS = makeStateKey('items');

@Component({
  selector: 'app-webiner',
  templateUrl: './webiner.component.html',
  styleUrls: ['./webiner.component.scss']
})
export class WebinerComponent implements OnInit {
  
  items: any = [];
  dumArray:any=[1,2,3,4,5,6,7,8,9];

  tutorials: any;
  currentTutorial = null;
  currentIndex = -1;
  title = '';
  firstString: string;
  page = 1;
  count = 0;
  pageSize = 9;
  resourcesWebinarDatas: any;
  resourcesWebinarLoaded: boolean;
  envSiteName:any;
  scrollToTop: number;
  pos: number;
  MetaTagLoadedWebinar: boolean;
  MetaTagDataWebinar: any;

  constructor(private state: TransferState,private titleService: Title,private metaTagService: Meta,private httpclientService: HttpclientService,private canonical:CanonicalService,@Inject(PLATFORM_ID) private platformId: Object,
  @Inject(APP_ID) private appId: string,private routeUrl: Router) {
    this.envSiteName=environment.siteName;
   }

  ngOnInit(): void {
    this.resourcesWebinarData();
    //this.AllMetaTagWebinar();
  }
  resourcesWebinarData(): void
  {
    this.items = this.state.get(STATE_KEY_ITEMS, <any> []);
    this.resourcesWebinarLoaded = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/GlobalEvents/GetGlobalEventAllOnDemandWebinars?siteName='+this.envSiteName+'&pageNumber='+this.page)
    .subscribe(
      items => {
        const platform = isPlatformBrowser(this.platformId) ?
            'in the browser' : 'on the server';
        this.resourcesWebinarDatas = items;
        this.count=this.resourcesWebinarDatas[0].TotalDataCount;
        this.resourcesWebinarLoaded = true;
        this.state.set(STATE_KEY_ITEMS, <any> items);
        //console.log("resources Webinar Datas", this.resourcesWebinarDatas);
        window.scrollTo(0,0);
      });
  }

  resetUsers(): void {
    this.resourcesWebinarDatas = null;
    this.resourcesWebinarLoaded = true;
  }
  // redirect(getType:any,getUrl:any,getId:any)
  // {
  //   this.firstString = getUrl.substring(0,5);
  //   if(this.firstString ==="https")
  //   {
  //     window.open(getUrl, "_blank");
  //   }else
  //   {
  //     this.routeUrl.navigate(['/events/'+getType+"/"+getUrl+"/"+getId]);
  //   }
  // }
  handlePageChange(event): void {
    this.resetUsers();
    this.page = event;
    this.resourcesWebinarData();
    this.onActivate();
  }
  
  onActivate() {
    this.scrollToTop = window.setInterval(() => {
        this.pos = window.pageYOffset;
        //console.log("pos value",this.pos)
        if (this.pos > 0) {
            window.scrollTo(0, this.pos - 40); // how far to scroll on each step
        } 
        else {
            window.clearInterval(this.scrollToTop);  
        }
    }, 2);
}
AllMetaTagWebinar(): void {
  if(this.MetaTagLoadedWebinar!=true){
  this.MetaTagLoadedWebinar = false;
  this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/Home/GetMetaCode?PubsiteName='+this.envSiteName+'&PageName='+'Webinars')
  .subscribe(
    items => {
      this.MetaTagDataWebinar = items[0];
      this.MetaTagLoadedWebinar=true;
      //console.log('this.MetaTagDataVideo',this.MetaTagDataWebinar);

      this.canonical.CreateCanonicalLink();


        //seo code for tags
        this.titleService.setTitle(this.MetaTagDataWebinar.PageTitle);

        this.metaTagService.updateTag(
        { name: 'og:description', content: this.MetaTagDataWebinar.OgDescription}
        );

        this.metaTagService.updateTag(
          { name: 'og:title', content: this.MetaTagDataWebinar.PageTitle}
        );

        this.metaTagService.updateTag(
          { name: 'og:image', content: this.MetaTagDataWebinar.OgImageURL}
        );

        this.metaTagService.updateTag(
          { name: 'og:image:alt', content: this.MetaTagDataWebinar.PageTitle}
        );

        this.metaTagService.updateTag(
          { name: 'og:keywords', content: this.MetaTagDataWebinar.OgKeyWords}
        );
        
        this.metaTagService.updateTag(
          { name: 'description', content: this.MetaTagDataWebinar.OgDescription}
          );
  
          this.metaTagService.updateTag(
            { name: 'title', content: this.MetaTagDataWebinar.PageTitle}
          );
  
          this.metaTagService.updateTag(
            { name: 'image', content: this.MetaTagDataWebinar.OgImageURL}
          );
  
          this.metaTagService.updateTag(
            { name: 'image:alt', content: this.MetaTagDataWebinar.PageTitle}
          );

          this.metaTagService.updateTag(
            { name: 'keywords', content: this.MetaTagDataWebinar.OgKeyWords}
          );

        this.metaTagService.updateTag(
          { name: 'twitter:description', content: this.MetaTagDataWebinar.OgDescription}
        );
        this.metaTagService.updateTag(
          { name: 'twitter:title', content: this.MetaTagDataWebinar.PageTitle}
        );
        this.metaTagService.updateTag(
          { name: 'twitter:image', content: this.MetaTagDataWebinar.OgImageURL}
        );
        this.metaTagService.updateTag(
          { name: 'twitter:keywords', content: this.MetaTagDataWebinar.OgKeyWords}
        );
        //seo code for tags end
      });
    }
}

}
