import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { HttpclientService } from 'src/app/modules/application/httpclient/httpclient.service';
import { environment } from '../../../../../../../src/environments/environment';

@Component({
  selector: 'app-single-infographics-others',
  templateUrl: './single-infographics-others.component.html',
  styleUrls: ['./single-infographics-others.component.scss']
})
export class SingleInfographicsOthersComponent implements OnInit {
  
  refreshEmit:any;
  @Output() refreshEmitter= new EventEmitter<string>();

  href:string;
  lastString:string;
  singleInfographicsOthersDatas: any;
  singleInfographicsOthersLoaded: boolean;
  envSiteName:any;
  scrollToTop: number;
  pos: number;

  constructor(private httpclientService: HttpclientService,public router:Router) { 
    this.envSiteName=environment.siteName;
  }


  ngOnInit(): void {
    this.singleInfographicsOthersData();
  }

  refreshReq(resourcesid:any){
    
    this.resetUsers();
    
    this.refreshEmit= resourcesid;
    this.refreshEmitter.emit(this.refreshEmit);
    this.lastString= resourcesid;
    this.singleInfographicsOthersData();
    
  }

  singleInfographicsOthersData(): void
  {
    this.href = this.router.url;
    this.lastString = this.href.substring(this.href.lastIndexOf('/') + 1);
    this.singleInfographicsOthersLoaded=false;
    
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/SingleInfographics/GetTop6SingleInfographics?siteName='+this.envSiteName+'&ID='+this.lastString)
      .subscribe(
        items => {
          this.singleInfographicsOthersDatas = items;
          this.singleInfographicsOthersLoaded=true;
          //console.log("Single Infographics OthersLoaded ",this.singleInfographicsOthersDatas);
        });
  }

  resetUsers(): void {
    this.singleInfographicsOthersDatas = null;
    this.singleInfographicsOthersLoaded = false;
    this.lastString=null;
    this.href=null;
  }

  onActivate() {
    this.scrollToTop = window.setInterval(() => {
        this.pos = window.pageYOffset;
        //console.log("pos value",this.pos)
        if (this.pos > 0) {
            window.scrollTo(0, this.pos - 40); // how far to scroll on each step
        } 
        else {
            window.clearInterval(this.scrollToTop);  
        }
    }, 2);
}
}
