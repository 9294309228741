<h5 class="text-uppercase">Other Articles</h5>
<div class="row d-none d-md-flex" *ngIf="moreArticlesLoaded">
    <div class="col-md-6 mb-6 mt-4" *ngFor="let moreArticlesData of moreArticlesDatas">
        <!-- <div class="cursor-p" (click)="refreshReq(moreArticlesData.ID)" routerLink='/resources/articles/{{moreArticlesData.RouteURL}}/{{moreArticlesData.ID}}'> -->
        <a target="_blank" routerLink='/articles/{{moreArticlesData.RouteURL}}'>
            <img class="img-fluid pb-8" [src]="moreArticlesData.ImageUrl" alt="Article image">

            <h4 class="text-height-4 card-header-height-1 text-uppercase mt-3" [innerHTML]="moreArticlesData.WhitePaperTitle">

            </h4>
        </a>

        <p class="para-2 mb-2">{{moreArticlesData.ResourceType}} | {{moreArticlesData.PublishingDate1}}</p>
        <p class="para-1 text-justify mb-1 text-height-4 text-justify" [innerHTML]="moreArticlesData.Description"></p>
        <a class="para-2 para-2-c-2" target="_blank" href='/articles/{{moreArticlesData.RouteURL}}'>Read More</a>



        <!-- </div> -->
    </div>
</div>