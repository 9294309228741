<div class="h-25"></div>
<nav id="navbar" class="navbar navbar-expand-lg navbar-light bg-white p-0">
    <div class="container-fluid shadow-md-none">
        <div class="col-xs-6 col-sm-9 col-lg-2 col-6 col-md-2 p-0 logo-img">
            <a class="navbar-brand mr-3" routerLink="/">
                <img [src]="logo" class="h-logo-height" alt="The {{preSite}}"></a>
        </div>
        <div class="col-md-7 col-lg-5 p-0 d-none d-md-none d-lg-block pl55" *ngIf="loaded">
            <p class="header-title para-4 mb-2 pb-1 mt-3 pt-3 pt-1 text-uppercase">{{this.iData.Headertext}}</p>
            <p class="mb-0 text-uppercase text-center text-sm-left">
                <span class="a-tag-header">
                    <a href="" id="opennewsletterPopupButton" class="text-dark txtHover"  data-toggle="modal" data-target="#exampleModalCenter">Newsletter</a>
                </span> |
                <span class="a-tag-header">
                    <a href="" class="text-dark txtHover" data-toggle="modal" data-target="#memberLoginModalCenter">Member Login</a>
                </span> | <span class="a-tag-header">
                     <a href="" class="text-dark txtHover" data-toggle="modal" data-target="#memberSignupModalCenter">Signup</a>
                </span>
            </p>
        </div>
        <div class="col-xs-2 col-sm-1 col-2 px-0 search-mob-view order-md-12">
            <div class="search float-right t-18">
                <div class="search-circle float-right" (click)=" isOpen ? searchKey(searchData.value) : open();searchData.value = '';searchData.focus()">

                    <!-- <i class="fa fa-search text-white"></i> -->
                    <i [className]="isOpen ? 'fa fa-check text-white submit-text' : 'fa fa-search text-white'"></i>
                </div>
                <div class="search-box">
                    <input [@openClose]="isOpen ? 'open' : 'closed'" (keyup.enter)="isOpen ? searchKey(searchData.value) : open();searchData.value = '';searchData.focus()" #searchData class="openClose search-panel" type="text">
                </div>

            </div>
        </div>
        <div class="col-xs-2 col-sm-1 col-2 px-0 search-mob-view d-lg-none">
            <div class="search float-right">
                <a data-toggle="modal" data-target="#memberLoginModalCenter">
                    <div class="search-circle float-right mt-0">
                        <i class="far fa-user text-white"></i>
                    </div>
                </a>

            </div>
        </div>
        <div class="col-xs-2 col-sm-1 col-2 px-0 d-lg-none border-0 float-right pr-0 tab-bar-order">
            <button class="ng-mobilepanel_toggle navbar-toggler collapsed pr-0" (click)="overlay()" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
                <span class="fas fa-bars"></span>
            </button>
        </div>
        <div class="col-md-4 col-lg-4 order-md-1 collapse navbar-collapse" id="navbarTogglerDemo03">

            <ul class="navbar-nav mr-auto mt-0 mt-md-2 pt-1 pt-md-0 mt-lg-0 zi-2">
                <li class="nav-item ml-md-2 ml-0 dropdown dmenu">
                    <a class="nav-link a-tag-header text-uppercase dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span routerLink="/news" (click)="overlay()" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03">News</span>
                        <span id="navbarDropdown" class="mob-menu-arrow"><i class="fas fa-angle-down"></i></span>
                    </a>

                    <div class="dropdown-menu m-0 p-0 sm-menu" aria-labelledby="navbarDropdown">
                        <a class="dropdown-item" routerLink="news/featured-news" (click)="overlay()" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03">Top Stories</a>
                        <a class="dropdown-item" routerLink="news/trending-news" (click)="overlay()" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03">Latest</a>
                        <!-- <a class="dropdown-item" routerLink="/news/archive-news">Archive News</a> -->
                    </div>
                </li>
                <li class="nav-item ml-md-2 ml-0 dropdown dmenu">
                    <a class="nav-link a-tag-header text-uppercase dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" *ngIf="sharedDataService.upComingConferencesFound || sharedDataService.pastConferencesFound || sharedDataService.liveWebinarFound || sharedDataService.onDemandWebinarFound">
                        <span routerLink="/events" (click)="overlay()" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03">Events</span>
                        <span id="navbarDropdown" class="mob-menu-arrow"><i class="fas fa-angle-down"></i></span>
                    </a>
                    <div class="dropdown-menu m-0 p-0 sm-menu" aria-labelledby="navbarDropdown">
                        <a class="dropdown-item" (click)="overlay()" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" routerLink="/events/upcoming-conferences" *ngIf="sharedDataService.upComingConferencesFound">Upcoming Conferences</a>
                        <a class="dropdown-item" (click)="overlay()" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" routerLink="/events/past-conferences" *ngIf="sharedDataService.pastConferencesFound">Past Conferences</a>
                        <a class="dropdown-item" (click)="overlay()" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" routerLink="/events/live-webinars" *ngIf="sharedDataService.liveWebinarFound">Live Webinars</a>
                        <a class="dropdown-item" (click)="overlay()" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" routerLink="/events/on-demand-webinars" *ngIf="sharedDataService.onDemandWebinarFound">On-Demand Webinars</a>
                    </div>
                </li>
                <li class="nav-item ml-md-2 ml-0 dropdown dmenu">
                    <a class="nav-link a-tag-header text-uppercase dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span routerLink="/companies" (click)="overlay()" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03">Companies</span>
                        <span id="navbarDropdown" class="mob-menu-arrow"><i class="fas fa-angle-down"></i></span>
                    </a>
                    <div class="dropdown-menu m-0 p-0 sm-menu" aria-labelledby="navbarDropdown">
                        <!-- <a class="dropdown-item" (click)="overlay()" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" routerLink="/companies/marketplace">Marketplace</a> -->
                        <a class="dropdown-item"   aria-controls="navbarTogglerDemo03" target="_blank" href="/c-suite-on-deck">C-Suite On Deck</a>
                        <a class="dropdown-item"   aria-controls="navbarTogglerDemo03" target="_blank" href="/thought-leaders">Thought Leaders</a>
                        <a class="dropdown-item"   aria-controls="navbarTogglerDemo03" target="_blank" href="/guest-contributors" *ngIf="isShowGuestContributors">Guest Contributors</a>
                    </div>
                </li>
                <li class="nav-item ml-md-2 ml-0 dropdown dmenu">
                    <a class="nav-link a-tag-header text-uppercase dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span routerLink="/resources" (click)="overlay()" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03">Resources</span>
                        <span id="navbarDropdown" class="mob-menu-arrow"><i class="fas fa-angle-down"></i></span>
                    </a>
                    <div class="dropdown-menu m-0 p-0 sm-menu" aria-labelledby="navbarDropdown">
                        <a class="dropdown-item" (click)="overlay()" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" routerLink="/resources/articles">Articles</a>
                        <a class="dropdown-item" (click)="overlay()" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" routerLink="/resources/videos">Videos</a>
                        <a class="dropdown-item" (click)="overlay()" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" routerLink="/resources/whitepapers">Whitepapers</a>
                        <a class="dropdown-item" (click)="overlay()" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" routerLink="/resources/infographics">Infographics</a>
                        <a class="dropdown-item" (click)="overlay()" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" routerLink="/resources/webinars">Webinars</a>

                        <!-- <a class="dropdown-item" href="#" routerLink="/resources/singleGuestAuthorsProfile">Single GuestAuthors Profile</a> -->
                    </div>
                </li>
            </ul>
        </div>
    </div>
</nav>

<div class="hidden-div">
    <h2>{{title}}</h2>
</div>
<div id="overlay"></div>
<app-newsletter-pop></app-newsletter-pop>
<app-member-login-pop></app-member-login-pop>
<app-member-signup-pop></app-member-signup-pop>