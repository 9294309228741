import { APP_ID, Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { TransferState } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { HttpclientService } from 'src/app/modules/application/httpclient/httpclient.service';
import { environment } from '../../../../../../../src/environments/environment';

@Component({
  selector: 'app-single-resources',
  templateUrl: './single-resources.component.html',
  styleUrls: ['./single-resources.component.scss']
})
export class SingleResourcesComponent implements OnInit {
  directRoute : string;
  @Input() companyNameResourceData: any;
  href:string;
  lastString:string;
  ResourcesLoaded:  boolean;
  ResourcesDatas: any;
  firstString: string;
  envSiteName:any;

  constructor(private httpclientService: HttpclientService,public router:Router) {
    this.envSiteName=environment.siteName;
   }

  ngOnInit(): void {
    this.resourcesData();
  }

  redirect(ResourceType:any,getUrl:any,getId:any){
    this.firstString = getUrl.substring(0,4);
    //console.log("single resources companies",this.firstString)
    if(this.firstString ==="http"){
      window.open(getUrl, "_blank");
    }else{
      this.router.navigate(['/resources/'+ResourceType+"/"+getUrl+"/"+getId]);
    }
  }
  resourcesData(): void{
    this.href = this.router.url;
    this.lastString = this.href.substring(this.href.lastIndexOf('/') + 1);
    this.ResourcesLoaded = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/SingleCompanies/GetTop4SingleCompaniesResources?siteName=' +this.envSiteName+'&CompanyName='+this.companyNameResourceData)
      .subscribe(
        items => {
          this.ResourcesDatas = items;
          //console.log(this.ResourcesDatas);
          this.ResourcesLoaded = true;
          //console.log("single resourceedvdvdvdvdvds companies",this.ResourcesDatas)
        });
  }
}
