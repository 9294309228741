<p class="mb-3 mt-2 para-2-c-2" *ngIf="singleWebinarLoaded">

    <a routerLink="/">Home</a> >
    <a routerLink="/events">Event</a> >
    <a routerLink="/events/{{singleWebinar.EventDifferentType}}" class="text-capitalize">live webinars</a> >


    <span class="para-2-c-1" [innerHTML]=singleWebinar.Name></span>
</p>
<div *ngIf="singleWebinarLoaded" class="mb-5">
    <h1 [innerHTML]="singleWebinar.Name"></h1>
    <p class="para-4-bold">{{singleWebinar.DateWithStartTime}} | EST </p>
    <img class="img-fluid" src="{{singleWebinar.ImageUrl}}" alt="{{singleWebinar.Name}}">
    <p class="pt-3 para-6 mb-0 text-justify" [innerHTML]="singleWebinar.Details"></p>
    <a class="para-2 para-2-c-2 " href="{{singleWebinar.URL}}" target="_blank">Register Now</a>

</div>
<app-other-webinar (refreshEmitter)="receiveRefresh($event)"></app-other-webinar>
<div class="text-center w-100">
    <button class="text-uppercase thm-btn mt-4 btn-theme-success w-50 pt-3 pb-3" routerLink="/events/live-webinars">MORE LIVE WEBINARS</button>
</div>
<div class="share-div" (click)="shareClick()">
    <i class="fa fa-share-alt" aria-hidden="true"></i>
</div>
<div class="share-in" [@openClose]="isOpen ? 'open' : 'closed'">
    <a href="https://www.facebook.com/sharer.php?u={{fullUrl}}" target="_blank">
        <div class="share-inner f-icon">
            <i class="fab fa-facebook-f"></i>
        </div>
    </a>
    <a href="https://www.linkedin.com/shareArticle?mini=true&url={{fullUrl}}" target="_blank">
        <div class="share-inner l-icon">
            <i class="fab fa-linkedin-in"></i>
        </div>
    </a>
    <a href="https://twitter.com/share?url={{fullUrl}}" target="_blank">
        <div class="share-inner t-icon">
            <i class="fab fa-twitter"></i>
        </div>
    </a>
</div>