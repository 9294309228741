import { Component, OnInit } from '@angular/core';
import { HttpclientService } from 'src/app/modules/application/httpclient/httpclient.service';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { state, trigger, transition, style, animate } from '@angular/animations';
import { environment } from '../../../../../../src/environments/environment';



const STATE_KEY_ITEMS = makeStateKey('items');

@Component({
  selector: 'app-single-guest-authors-profile',
  templateUrl: './single-guest-authors-profile.component.html',
  styleUrls: ['./single-guest-authors-profile.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        width: '200px',
        opacity: 1,
        display:'block',
      })),
      state('closed', style({
        width: '0px',
        opacity: 0,
        display:'none',
      })),
      transition('open => closed', [
        animate('0.5s')
      ]),
      transition('closed => open', [
        animate('0.5s')
      ]),
    ]),
  ],
})
export class SingleGuestAuthorsProfileComponent implements OnInit {

href: string;
lastString: string;
allsingleGuestAuthorsProfileDatas: any;
allsingleGuestAuthorsProfileloaded: boolean;
isOpen = false;
isOverlay = false;
fullUrl:any;
envSiteName:any;
  scrollToTop: number;
  pos: number;
  authorName : string;
  authorId: any;
  AuthorEmail: any;
constructor(private state: TransferState,private httpclientService: HttpclientService,@Inject(PLATFORM_ID) private platformId: Object,
@Inject(APP_ID) private appId: string,public router: Router) {
  this.envSiteName=environment.siteName;
 }

  ngOnInit(): void {
    this.allsingleGuestAuthorsProfileData();
    this.fullUrl=window.location.href;
  }


  allsingleGuestAuthorsProfileData(): void {
    
    this.href = this.router.url;
    this.lastString = this.href.substring(this.href.lastIndexOf('/') + 1);

    this.allsingleGuestAuthorsProfileloaded = false;
    this.httpclientService.getHttpclient('https://api.nathanark.com/api/SingleGuestAuthorProfile/GetSingleGuestAuthorProfileByID?ID='+ this.lastString)
    .subscribe(
      items => {
        this.allsingleGuestAuthorsProfileDatas = items[0];
        
        this.authorId = items[0].UserDetailsId;
        this.AuthorEmail = items[0].UserEmail;
       
        this.allsingleGuestAuthorsProfileloaded = true;
      }
    );
  }
 
  resetUsers(): void {
    this.allsingleGuestAuthorsProfileDatas = null;
    this.allsingleGuestAuthorsProfileloaded = true;
    this.onActivate();
  }

  handlePageChange(event): void {
    this.resetUsers();
    this.allsingleGuestAuthorsProfileData();
    this.onActivate();
  }

  onActivate() {
    this.scrollToTop = window.setInterval(() => {
        this.pos = window.pageYOffset;
        //console.log("pos value",this.pos)
        if (this.pos > 0) {
            window.scrollTo(0, this.pos - 40); // how far to scroll on each step
        } 
        else {
            window.clearInterval(this.scrollToTop);  
        }
    }, 2);
}

  shareClick(){
    this.isOpen = !this.isOpen;
  }
 
}