<h5 class="text-uppercase">OTHER VIDEOS</h5>
<div class="row d-none d-md-flex" *ngIf="moreVideosLoaded">
    <div class="col-md-6 mb-6" *ngFor="let moreVideosData of moreVideosDatas">
         <!-- <div class="cursor-p" (click)="refreshReq(moreVideosData.ID)" routerLink='/resources/videos/{{moreVideosData.RouteURL}}/{{moreVideosData.ID}}'> -->
            <a target="_blank" routerLink='/videos/{{moreVideosData.RouteURL}}' >
            <img class="img-fluid mb-2 image-hight-video-single" src="{{moreVideosData.ImageUrl}}" alt="videos image">
            
            <h4 class="text-height-4 card-header-height-1 text-uppercase" [innerHTML]="moreVideosData.WhitePaperTitle"></h4>
        </a>
            <p class="para-2 mb-2">{{moreVideosData.ResourceType |titlecase}} | {{moreVideosData.PublishingDate1}}</p>
            <p class="para-1 text-justify mb-1 text-height-4 text-justify" [innerHTML]="moreVideosData.Description"></p>
            <!-- <a href="{{moreVideosData.EmbededVideoURL}}" target="_blank" class="para-2 para-2-c-2 " routerLink='{{moreVideosData.EmbededVideoURL}}'>Watch Now</a> -->
            
            <a target="_blank" routerLink='/videos/{{moreVideosData.RouteURL}}' class="para-2 para-2-c-2"  target="_blank">Watch Now</a>
        <!-- </div> -->
   
    </div>
</div>