import { Component, OnInit } from '@angular/core';
import { HttpclientService } from 'src/app/modules/application/httpclient/httpclient.service';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { environment } from '../../../../../../src/environments/environment';

const STATE_KEY_ITEMS = makeStateKey('items');

@Component({
  selector: 'app-archive-news',
  templateUrl: './archive-news.component.html',
  styleUrls: ['./archive-news.component.scss']
})
export class ArchiveNewsComponent implements OnInit {

  items: any = [];
  dumArray:any=[1,2,3,4,5,6,7,8,9];

  currentIndex = -1;
  title = '';

  page = 1;
  count = 0;
  pageSize = 9;

  archivedNewsDatas: any;
  archivedloaded: boolean;
  envSiteName:any;
  scrollToTop: number;
  pos: number;

  constructor(private state: TransferState,private httpclientService: HttpclientService,@Inject(PLATFORM_ID) private platformId: Object,
  @Inject(APP_ID) private appId: string) { 
    this.envSiteName=environment.siteName;
  }

  ngOnInit(): void {
    this.archivedNews();
  }
  archivedNews(): void{
    this.items = this.state.get(STATE_KEY_ITEMS, <any> []);
    this.archivedloaded= false;
    this.httpclientService.getHttpclient('https://api.nathanark.com/api/News/GetAllArchivedNewsDetails?siteName='+this.envSiteName+'&pageNumber='+this.page)
    .subscribe(
      items => {
        const platform = isPlatformBrowser(this.platformId) ?
            'in the browser' : 'on the server';
        this.archivedNewsDatas = items;
        this.count=this.archivedNewsDatas[0].TotalDataCount;
        this.archivedloaded =true;
        this.state.set(STATE_KEY_ITEMS, <any> items);
        //console.log("ArchivedNews Data:", this.archivedNewsDatas);
      }
    );
  }
  resetUsers(): void {
    this.archivedNewsDatas = null;
    this.archivedloaded = true;
  }

  // on click of pagination tabs
  handlePageChange(event): void {
    this.resetUsers();
    this.page = event;
    this.archivedNews();
    this.onActivate();
  }

  // for scroll of to top on page change
  onActivate() {
    this.scrollToTop = window.setInterval(() => {
        this.pos = window.pageYOffset;
        //console.log("pos value",this.pos)
        if (this.pos > 0) {
            window.scrollTo(0, this.pos - 40); // how far to scroll on each step
        } 
        else {
            window.clearInterval(this.scrollToTop);  
        }
    }, 2);
}
}
