<h2 class="text-uppercase mt-md-3 mb-0 GNewsResoEve">
    Events</h2>
<div class="row EveGDiv" *ngIf="loaded">
    <div class="col-md-12 GNewsReEDiv" *ngFor="let eventData of eventDatas">
        
        <!-- <div class="cursor-p" routerLink='/events/upcoming-conferences/{{eventData.RouteURL}}/{{eventData.EventID}}'> -->
        <a target="_blank" href='{{eventData.RouteURL}}' > 
            <img class="resources-img-h pb-2 pr-5" [src]='eventData.ImageUrl' alt="event image">
            <h4 class="text-uppercase EventTitle">{{eventData.Name}}</h4>
        </a>
            <p class="para-3 text-capitalize">{{eventData.EventType}}</p>
            
        <!-- </div> -->
   </div>
</div>
