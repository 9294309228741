import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpclientService } from 'src/app/modules/application/httpclient/httpclient.service';
import { environment } from '../../../../../../../src/environments/environment';

@Component({
  selector: 'app-single-events',
  templateUrl: './single-events.component.html',
  styleUrls: ['./single-events.component.scss']
})
export class SingleEventsComponent implements OnInit {
  href:string;
  lastString:string;
  singleEventLoaded:  boolean;
  eventDatas: any;
  firstString: string;
  envSiteName:any;

  constructor(private httpclientService: HttpclientService,public router:Router) {
    this.envSiteName=environment.siteName;
   }

  ngOnInit(): void {
    this.singleCSuiteData();
  }
  redirect(getUrl:any,getId:any){
    this.firstString = getUrl.substring(0,5);
    if(this.firstString ==="https"){
      window.open(getUrl, "_blank");
    }else{
      this.router.navigate(['/events/upcoming-conferences/'+getUrl+"/"+getId]);
    }
  }
  singleCSuiteData(): void{
    this.href = this.router.url;
    this.lastString = this.href.substring(this.href.lastIndexOf('/') + 1);
    this.singleEventLoaded = false;
    this.httpclientService.getHttpclient('https://api.nathanark.com/api/SingleCompanies/GetTop6SingleCompaniesEvents?siteName=' +this.envSiteName)
      .subscribe(
        items => {
          this.eventDatas = items;
          this.singleEventLoaded = true;
          //console.log("single Event", this.eventDatas);
        });
  }
 
}
