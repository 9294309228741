
<h2 class="text-uppercase mt-3 mb-0 pt-5 flot-left">
    Events</h2>
<div class="row" *ngIf="singleEventLoaded">
    <div class="col-md-12 pt-3" *ngFor="let singleEvent of eventDatas">
        <!-- <div class="cursor-p" (click)="redirect(singleEvent.RouteURL,singleEvent.EventId)"> -->
            <a target="_blank"  routerLink='{{singleEvent.RouteURL}}'>
            <img class="resources-img-h pb-2 pr-5" [src]='singleEvent.ImageUrl' alt="news image">
            <h4 class="text-uppercase">{{singleEvent.Name}}

            </h4>
            </a>
        <!-- </div> -->
            <p class="para-3 text-capitalize">Conference</p>
</div>
</div>

