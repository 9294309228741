<div class="privacy-tags">
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 AllFont">
            <h1>PRIVACY POLICY</h1>
            <h4>1.   Introduction</h4>
            <p>{{preSite}} is committed to your data protection and data privacy. We recommend you read this Privacy Policy to better understand what information we collect, how we use it, and who we may share it with. Additionally, this Privacy Policy will provide you with details on how you can control the use and disclosure of your information.</p>
            <p>{{preSite}} provides this Privacy Policy to help Users make an informed decision as to whether to use or continue using the Website. If you have questions or concerns, contact {{preSite}} Support at <a style="color:#891a2b" href="mailto:privacy@{{envSiteName | lowercase}}">privacy@{{envSiteName | lowercase}}</a>. By reference, this Privacy Policy is incorporated into and is subject to any and all agreements entered into between Users and {{preSite}}.</p>
            <h4>2.   What does this privacy policy cover?</h4>
            <p>This Privacy Policy covers information collected by {{preSite}} through its websites and details the Personal Data that we process and collect from website visitors, users completing a registration form to become a {{preSite}} member, Service Providers and third parties who perform functions for us.</p>
            <h4>3.   How we collect the information</h4>
            <p>We collect or receive information in several different ways. In many cases you choose what information to provide.</p>
            <h5>3.1   Directly from You:</h5>                    
            <p>We collect a variety of information from you when you provide it to us directly or when we request it from you. Some examples of these instances are as follows:</p>
            <h6>3.1.1   Personal Data that you Provide to Us </h6>
            <p>We receive, store, and collect any Personal Data you provide to us directly through one of our websites or give us in any other way, including business cards, emails, and telephone communications. For example, when you fill out and submit a registration form to become {{preSite}} member, download a specific piece of content, request a walkthrough or demonstration of one of our products or services.</p>

            <h6>3.1.2   Information About Others Who May be Interested in Our Content or Websites.</h6>
            <p>Our websites may include an “email-a-friend” or “share this” feature, which allows someone to email or send content to a third party. If you utilize the feature, we will only use the email address of the intended recipient for purposes of emailing the respective content to that person. You should never share another person’s Personal Information with others without first receiving their permission.</p>
            <h6>3.1.3   Member Generated Content.</h6>
            <p>Some websites feature member generated content in the form of a member review section. In order to interact in these forums, an individual must first complete the applicable registration form. The information provided by the individual may be displayed in the individual’s public profile in our forums and can be viewed by all visitors to the website. To ensure individuals’ privacy, we do not display any the email addresses of individuals who have commented in such forums or who have contributed such content.</p>
            <h5>3.2   Third Party Service Providers and Partners.</h5>
            <p>We engage third party service providers and partners to provide us with information, and we also use publicly available sources, to supplement our member account information to help improve personalization of our Premium Services. For example:</p>
            <h6>3.2.1   Supplementary and Third Party Information</h6>
            <p>We may collect, obtain, or supplement your Personal Data from third parties who license it to us or otherwise provide it to us. This may include information provided through telephone, mailings, or events, from customers, vendors, suppliers, third parties, or commercially available sources (e.g., data brokers, data aggregators, public databases, etc.), and from third party partners or affiliates running co-branded campaigns or membership initiatives.</p>
            <h6>3.2.2	Public and Social Network Sources</h6>
            <p>We may collect or obtain your Personal Data from publicly accessible sources like corporate websites, business listings, or social networking platforms. You can opt-out of the third parties’ (such as a social media network or advertiser platform) collection and use of information for ad targeting by updating your account ad settings with the third party or by following the instructions regarding Tracking Technologies described in the paragraph below.</p>
            <h5>3.3	Cookies and Other Tracking Tools.</h5>
            <p>We use Cookies, Clear GIFs, Web Beacons, Tracking Technologies or other similar solutions (“Tracking Technologies”) to collect or receive certain information when you visit or use {{preSite}} website or read an email, newsletter, or otherwise interact with content from us.</p>
            <h6>3.3.1	Cookies</h6>
            <p>Cookies are small data files which are placed on your computer or mobile device when you visit a website or use an online service. For example, cookies enable us to identify and authenticate you and remember your preferences so we can enhance your experience on our websites. They also help us serve you ads relevant to your professional interests and to provide aggregated auditing, research, and reporting for advertisers, understand and improve our service, and know when content has been shown to you.</p>
            <h6>3.3.2	Clear GIFs: </h6>
            <p>{{preSite}} may choose to utilize "clear GIFs" to track the online usage patterns of our Users in a non-personally identifiable manner. Additionally, {{preSite}} may also use clear GIFs in HTML-based emails dispatched to Users so as to track which emails are being opened by the recipients. This act helps {{preSite}} to determine active and inactive email addresses.</p>
            <h6>3.3.3	Log File Data</h6>
            <p>When Users log in to their {{envSiteName}} account, our servers will automatically record and archive certain information that web-browsers send whenever visiting a website. These server logs may include information vital to validating the User's authorization to access the account. Information, such as a Web request, Internet Protocol (the "IP") address, browser type, browser language, referring pages, exit pages and visited URLs, platform type, click counts, pages viewed and in what order, time spent, the date and time of the request, and other important data is necessary to validate and authorize a User's entry and activity on the Website.</p>
            <p>{{preSite}} may collect information from other sources to help us correct or supplement our records, improve the quality or personalization of our service to you, and prevent or detect fraud. We work closely with third parties (for example, business partners, service providers, sub-contractors, advertising networks, analytics providers, search information providers, fraud protection services) and may receive information about you from them.</p>
            <p>Further, and in order to provide the services and improve {{preSite}}, we may engage the services of third-party vendors. In the process of supplying services to {{preSite}}, these third-party vendors may need to collect Personal Information about you.</p>
            <h4>4.	Why we collect Personal Data?</h4>
            <p>We collect Personal Data about you to provide you with the best and most useful content and services and to offer you opportunities to obtain other products and services from us and our Partners. By providing us with your Personal Data, we can cater our content and services to your interests and ensure that you receive relevant offers.</p>
            <p>Additionally, the collection and use of your Personal Data helps us tailor and improve our services and communicate with you effectively as we know many of our website visitors and members make use of a range of media channels. Finally, collecting and sharing your Personal Data with our Partners makes it possible to operate our business and provide you with free content, products, or services relevant to your professional interests.</p>
            <h4>5.	How we use Personal Data?</h4>
            <p>We use, aggregate, and retain your Personal Data as needed to provide you with access to content, products, and services catered to your professional interests and for other legitimate business reasons. Some information is required in order to use our websites and for us to provide the Premium Services. At a high level, we use the Personal Data that has been provided by you directly or indirectly:</p>
            <ul>
                <li class="terms">To provide you with our Premium Services including, publications, and information that you request, access, or which may be of interest to you (e.g., sending you whitepapers and newsletters regarding information you are researching);</li>
                <li class="terms">To create your member profile and purchase-intent history;</li>
                <li class="terms">To send you satisfaction questionnaires, surveys, and marketing information;</li>
                <li class="terms">To deliver or display content, advertisements, and other materials that match your professional interests and responsibilities for the purposes of online behavioral advertising (e.g., displaying advertisements that relate to your business or research);</li>
                <li class="terms">To tailor the newsletters delivered to you based on your activity, interests, and responsibilities;</li>
                <li class="terms">For {{preSite}} and its Partners to contact you for direct marketing purposes regarding information about, and invitations for, products, services, and events relevant to your professional interests and responsibilities;</li>
                <li class="terms">To communicate with you, advertise to you or invite you to join us on social media platforms on which you participate;</li>
                <li class="terms">To offer you opportunities to connect directly with your peers and with providers of technologies, goods, and services that may be of interest to you;</li>
                <li class="terms">To operate our websites and improve our products and services;</li>
                <li class="terms">To understand trends, usage statistics, and purchase-intent data;</li>
                <li class="terms">To respond to your product or service inquiries or support requests and comments;</li>
                <li class="terms">To test and evaluate potential new products and features;</li>
                <li class="terms">To diagnose and resolve problems, analyze trends, and monitor usage and resource needs; and</li>
                <li class="terms">For any other purpose that we tell you about when you give us your Personal Data.</li>
            </ul>
            <h4>6.	How we share your information</h4>
            <p>{{preSite}} shares information with service providers, affiliates, Partners, and other third parties where it is necessary to meet the requirements of our Terms of Use, to provide the Premium Services, or for any other purposes described in this Privacy Policy.</p>
            <h5>6.1	Partners:</h5>
            <p>{{preSite}} provides Partners with access to business contact lists and reports that may include your Personal Data and information about your areas of interest derived from your activity on our websites and interaction with our content or Partner content that has been published on our websites. Partners use this information to improve their sales and marketing outreach to their target audience and to optimize their sales and marketing strategies. They may also use this information to contact you about products or services that are relevant to your professional interests.</p>
            <h5>6.2	Vendors and Service Providers</h5>
            <p>{{preSite}} may provide your Personal Data to certain vendors and service providers who are working for or with {{preSite}} as described in this Privacy Policy and who require access to such information to facilitate the provision of our services, such as the provision of data storage or processing activities, member acquisition or lead generation services, or corporate auditing services.</p>
            <h5>6.3	Vital Interests</h5>
            <p>We may disclose the information that we collect where we believe that it is necessary in order to protect the vital interests of any person, or to exercise, establish, or defend our legal rights.</p>
            <h5>6.4	Legal Compliance and Public Safety</h5>
            <p>We may share your Personal Data when required by law, subpoena, or other legal process or if we have a good faith belief that disclosure is reasonably necessary to :</p>
            <ul>
                <li>a)	investigate, prevent, or take action regarding suspected or actual illegal activities or to assist government enforcement agencies;</li>
                <li>b)	enforce our agreements,</li>
                <li>c)	investigate and defend ourselves against any third-party claims or allegations,</li>
                <li>d)	protect the security or integrity of our services</li>
                <li>e)	exercise or protect the rights and safety of our members, personnel, or others</li>
            </ul>
            <h4>7.	Data Retention</h4>
            <p>We retain your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements. We will retain member’s Personal Data for so long as a member’s account remains in existence or as needed to provide our Premium Services to our member, to comply with our legal obligations, to resolve disputes, and to enforce our agreements. We may retain Personal Data after a member has cancelled their membership if retention is reasonably necessary to comply with our legal obligations, meet regulatory requirements, resolve disputes, prevent fraud and abuse, or enforce this Privacy Policy and our Terms of Use. We may also retain Personal Data, for a limited period, if requested by law enforcement.</p>
            <h4>8.	How Do We Protect Your Information?</h4>
            <p>We take the security of the data that it collects very seriously. {{preSite}} has implemented a myriad of technical, organizational and administrative measures which are generally accepted by the industry to protect the Personal Data in its possession. These measures include, but are not limited to, the implementation of current security technologies and processes to protect your information from loss, misuse and unauthorized access, disclosure, alteration or destruction. Our security systems include authenticated access to internal databases, regular audits of processes and procedures, scheduled reviews of overall web security, and the use of restrictions of access to Personal Data in our possession. Additionally, we use encryption (HTTPS/TLS) to protect data transmitted to and from our websites.</p>
            <h4>9.	Your EU and UK GDPR Privacy Rights and Controlling Your Personal Data.</h4>
            <p>If you wish to access, rectify, erase, restrict, transfer, or object to the use of your information, or deactivate your membership, please access our  request form. If you have any other questions in connection with this Privacy Policy or the use of your personal information, please send us an email at privacy@dataanalytics.report</p>
            <p>Every email sent by or on the behalf of {{preSite}} includes information as to how you can easily unsubscribe from future communications.</p>
            <p>Subject to applicable law, you have the following rights in relation to your Personal Data:</p>
            <h5>9.1	Right of access:</h5>
            <p>If you ask us, we will confirm whether we are processing your Personal Data and, if so, provide you with a copy of that Personal Data (along with certain other details). If you require additional copies, we may need to charge a reasonable fee</p>
            <h5>9.2	Right to rectification:</h5>
            <p>If your Personal Data is inaccurate or incomplete, you are entitled to have it rectified or completed. If we have shared your Personal Data with others, we will tell them about the rectification where possible. If you ask us, where possible and lawful to do so, we will also tell you with whom we shared your Personal Data so that you can contact them directly.</p>
            <h5>9.3	Right to erasure:</h5>
            <p>You may ask us to delete or remove your Personal Data and we will do so in some circumstances, such as where we no longer need it (we may not delete your data when other interests outweigh your right to deletion.</p>
            <h5>9.4	Right to restrict processing:</h5>
            <p>You may ask us to restrict or ‘block’ the processing of your Personal Data in certain circumstances, such as where you contest the accuracy of that Personal Data or object to us processing it. We will tell you before we lift any restriction on processing.</p>
            <h5>9.5	Right to data portability:</h5>
            <p>You have the right to obtain your Personal Data from us that you consented to give us or that is necessary to perform a contract with you. We will give you your Personal Data in a structured, commonly used and machine-readable format. You may reuse it elsewhere.</p>
            <h5>9.6	Right to object:</h5>
            <p>You may ask us at any time to stop processing your Personal Data, and we will do so if we are processing your Personal Data for direct marketing and otherwise.</p>
            <h5>9.7	Right to withdraw consent:</h5>
            <p>If we rely on your consent to process your Personal Data, you have the right to withdraw that consent at any time. Your withdrawal of consent will not affect apply to data that was processed prior to our receipt of your withdrawal of consent.</p>
            <h5>9.8	Right to lodge a complaint with the data protection authority:</h5>
            <p>If you have a concern about our privacy practices, including the way we have handled your Personal Data, you can report it to the data protection authority that is authorized to hear those concerns.</p>
            <p>{{preSite}} complies with the EU-US Privacy Shield Framework and the Swiss-US Privacy Shield Framework (together, the “Privacy Shield.”) as set forth by the U.S. Department of Commerce regarding the collection, use, and retention of Personal Data transferred from the European Union, the United Kingdom, and Switzerland to the United States in reliance on Privacy Shield. {{preSite}} has certified to the Department of Commerce that it adheres to the Privacy Shield Principles.  If there is any conflict between the terms in this privacy policy and the Privacy Shield Principles, the Privacy Shield Principles shall govern.  To learn more about the Privacy Shield program, and to view our certification, please visit <a href="https://www.privacyshield.gov/welcome" target="_blank">https://www.privacyshield.gov/welcome</a></p>
            <h4>10.	Children’s Privacy</h4>
            <p>Our business is not directed at children, and we do not solicit or knowingly collect any personal information from children under the age of 16 without verifiable parental consent. If you are not 18 or older, you are not authorized to use our websites or services. Parents should be aware that there are parental control tools available online that you can use to prevent children from submitting information online without parental permission or from accessing material that is harmful to minors. If you learn that a child under 13 has provided us with Personal Data without consent, please contact us via the information provided below.</p>
            <h4>11.	Your California Privacy Rights</h4>
            <p>If you are a resident of California, the CCPA offers you important rights you can exercise.  In summary, those include the rights to:  </p>
            <ul>
                <li class="terms">California residents may request certain information regarding {{preSite}} disclosure of personal information to third parties for their direct marketing purposes.  To make such a request, please write to <a style="color:#891a2b" href="mailto:privacy@{{envSiteName | lowercase}}">privacy@{{envSiteName | lowercase}}</a>. Please include your name, mailing address, and email address if you want to receive a response by email.  Otherwise, we will respond by postal mail within the time required by applicable law.  </li>
                <li class="terms">California residents may request that {{preSite}} not sell their personal information by clicking the <a href="https://media7.com/do-not-sell-my-information">“Do Not Sell My Information”</a> link at the bottom of the {{preSite}} home page.  </li>
                <li class="terms">If you are a California resident below the age of 18 and are a registered user of any of {{preSite}} Services, then you may request that we remove any content you created and publicly posted on our website ("User Content").  To request the removal of your User Content, please email <a style="color:#891a2b" href="mailto:privacy@{{envSiteName | lowercase}}">privacy@{{envSiteName | lowercase}}</a>, with a detailed description of the specific User Content.  {{preSite}} reserves the right to request that you provide information that will enable us to confirm that the User Content you want to be removed was created and posted by you. </li>
                <li class="terms">{{preSite}} will make a good faith effort to delete or remove your User Content from public view as soon as reasonably practicable. Please note, however, that your request to delete your User Content does not ensure complete or comprehensive removal of your User Content.  Your User Content may remain on backup media, cached or otherwise retained by {{preSite}} for administrative or legal purposes, or your User Content may remain publicly available if you or someone else has forwarded or re-posted your User Content on another website or service before its deletion.  {{preSite}} may also be required by law to not remove (or allow removal) of your User Content.</li>
            </ul>
            <h4>12.	Changes to this Privacy Policy</h4>
            <p>{{preSite}} may modify or amend this Privacy Policy from time to time. If we change our Privacy Policy, we will post those changes in addition to updating the “Last Updated” date at the top of this policy. We encourage you to read this Privacy Policy regularly. Where applicable, your continued use of our Premium Services, our websites, or accessing content posted on our websites shall constitute your acceptance of the modified or amended Privacy Policy.</p>
            <h4>13.	Contact Us</h4>
            <p>For more information, or if you have questions or concerns regarding {{preSite}}'s Privacy Policy, you may email us at <a style="color:#891a2b" href="mailto:privacy@{{envSiteName | lowercase}}">privacy@{{envSiteName | lowercase}}</a>,  or you can send correspondence to either of the following addresses:</p>
            <p>
                3rd Floor, Delta-1, Giga Space,<br>
                Viman Nagar, Pune - 411014<br>
                India<br>
            </p>
        </div>
    </div>
</div>