<!-- <div class="container"> -->
    <div class="community">
        <h4>The Community: At A Glance</h4>
       <div class="row">
          <div class="col-md-6  contentWidth bannermobile">
            <img id="btmImage1" src="https://media7.io/Advertise/Pubsite/BottomImages/re.report1.jpg" style="border-width:0px;">
            <div class="fleft">
                <h5 class="contentWidth"> News</h5>
                <p><label><span id="lblFeatured">{{datacount.FeaturedNewsCount}}</span></label> featured</p>
                <p><label><span id="lblTrending">{{datacount.TrendingNewsCount}}</span></label> trending</p>
            </div>
          </div>
          <div class="col-md-6 contentWidth bannermobile">
            <img id="btmImage2" src="https://media7.io/Advertise/Pubsite/BottomImages/re.report2.jpg" style="border-width:0px;">
            <div class="fleft contentWidth">
                <h5>Companies</h5>
                <p><label><span id="lblcompanies">{{datacount.CompanyCount}}</span></label> companies</p>
                <p><label><span id="lblPeople">{{datacount.CompanyCount}}</span></label> people</p>
                <p><label><span id="lblcompanies">{{interview_datacount.CSuiteOnDeck}}</span></label> c-suite on deck</p>
                <p><label><span id="lblPeople">{{interview_datacount.ThoughtLeaders}}</span></label> thought leaders</p>
            </div>
          </div>
          <div class="col-md-2 col-sm-2 col-xs-2 dispne"></div>
        </div>
        <div> </div>
        <div class="row">
            <div class="col-md-6 contentWidth bannermobile">
                <img id="btmImage3" class="brandscontent" src="https://media7.io/Advertise/Pubsite/BottomImages/re.report3.jpg" style="border-width:0px;">
                <div class="fleft  contentWidth">
                    <h5>Events</h5>        
                    <p><label><span id="lblliveweb">{{datacount.UpcommingLivewebinarCount}}</span></label> live webinars</p>
                    <p><label><span id="lblconferences">{{datacount.UpcommingConferencesCount}}</span></label> upcomming conferences</p>
                    <p><label><span id="lblconferences">{{datacount.PastConferencesCount}}</span></label> past conferences</p>
                </div>
            </div>
            <div class="col-md-6 contentWidth bannermobile">
                <img id="btmImage4" class="brandscontent" src="https://media7.io/Advertise/Pubsite/BottomImages/re.report4.jpg" style="border-width:0px;">
                <div class="fleft  contentWidth">
                    <h5>Resources</h5>
                    <p><label><span id="lblwhitepapers">{{datacount.WhitepaperCount}}</span></label> whitepapers</p>
                    <p><label><span id="lblondemand">{{datacount.OndemandwebinarCount}}</span></label> on-demand webinars</p>
                    <p><label><span id="lblvideos">{{datacount.VideoCount}}</span></label> videos</p>
                    <p><label><span id="lblinfographics">{{datacount.InfographicsCount}}</span></label> infographics</p>
                    <p><label><span id="lblblog">{{datacount.ArticleCount}}</span></label> blog articles</p>
                </div>
            </div>
            <div class="col-md-2 col-sm-2 col-xs-2 dispne"></div>
        </div>
    </div>
<!-- </div> -->
