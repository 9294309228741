import { Component, OnInit } from '@angular/core';
import { HttpclientService } from '../../application/httpclient/httpclient.service';
import { Title, Meta } from '@angular/platform-browser';
import { environment } from '../../../../../src/environments/environment';
import { CanonicalService } from 'src/app/canonical.service';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { ActivationEnd } from '@angular/router';
import { filter } from "rxjs/operators";

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit {
  ResourceMetaTagLoaded: boolean;
  ResourceMetaTagData: any;
  envSiteName: any;
  constructor(private router: Router, private httpclientService: HttpclientService, private titleService: Title, private metaTagService: Meta, private canonical: CanonicalService) {
    this.envSiteName = environment.siteName;
    this.router.events.pipe(
      filter(event => event instanceof ActivationEnd))
      .subscribe(e => {
        this.MetaData((e as ActivationEnd).snapshot);
      });
  }

  ngOnInit(): void {
    //this.ResourceAllMetaData();
  }

  MetaData(snapshot: ActivatedRouteSnapshot): void {
    let parent: ActivatedRouteSnapshot = snapshot.parent as ActivatedRouteSnapshot;
    //let path: string = 'all-resources';
    let path: string = 'Resources';
    let componentName : string;
    if (typeof(parent.component)==='function')
    {
      componentName = parent.component.name;
    }
    if(componentName != 'ResourcesComponent') 
      return;

    if (snapshot.routeConfig.path) {
      path = snapshot.routeConfig.path;
    }

    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/Home/GetMetaCode?PubsiteName=' + this.envSiteName + '&PageName=' + path)
      .subscribe(
        items => {
          this.ResourceMetaTagData = items[0];
          this.ResourceMetaTagLoaded = true;
          //console.log('this.ResourceMetaTagData',this.ResourceMetaTagData);


          //seo code for tags
          this.canonical.CreateCanonicalLink();

          this.titleService.setTitle(this.ResourceMetaTagData.PageTitle);

          this.metaTagService.updateTag(
            { name: 'og:description', content: this.ResourceMetaTagData.OgDescription }
          );

          this.metaTagService.updateTag(
            { name: 'og:title', content: this.ResourceMetaTagData.PageTitle }
          );

          this.metaTagService.updateTag(
            { name: 'og:image', content: this.ResourceMetaTagData.OgImageURL }
          );

          this.metaTagService.updateTag(
            { name: 'og:image:alt', content: this.ResourceMetaTagData.PageTitle }
          );

          this.metaTagService.updateTag(
            { name: 'description', content: this.ResourceMetaTagData.OgDescription }
          );

          this.metaTagService.updateTag(
            { name: 'title', content: this.ResourceMetaTagData.PageTitle }
          );

          this.metaTagService.updateTag(
            { name: 'keywords', content: this.ResourceMetaTagData.OgKeyWords }
          );

          this.metaTagService.updateTag(
            { name: 'image', content: this.ResourceMetaTagData.OgImageURL }
          );

          this.metaTagService.updateTag(
            { name: 'image:alt', content: this.ResourceMetaTagData.PageTitle }
          );

          this.metaTagService.updateTag(
            { name: 'twitter:description', content: this.ResourceMetaTagData.OgDescription }
          );
          this.metaTagService.updateTag(
            { name: 'twitter:title', content: this.ResourceMetaTagData.PageTitle }
          );
          this.metaTagService.updateTag(
            { name: 'twitter:image', content: this.ResourceMetaTagData.OgImageURL }
          );
          //seo code for tags end
        });
  }


  ResourceAllMetaData(): void {
    if (this.ResourceMetaTagLoaded != true) {
      this.ResourceMetaTagLoaded = false;
      this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/Home/GetMetaCode?PubsiteName=' + this.envSiteName + '&PageName=' + 'all-resources')
        .subscribe(
          items => {
            this.ResourceMetaTagData = items[0];
            this.ResourceMetaTagLoaded = true;
            //console.log('this.ResourceMetaTagData',this.ResourceMetaTagData);


            //seo code for tags
            this.canonical.CreateCanonicalLink();

            this.titleService.setTitle(this.ResourceMetaTagData.PageTitle);

            this.metaTagService.updateTag(
              { name: 'og:description', content: this.ResourceMetaTagData.OgDescription }
            );

            this.metaTagService.updateTag(
              { name: 'og:title', content: this.ResourceMetaTagData.PageTitle }
            );

            this.metaTagService.updateTag(
              { name: 'og:image', content: this.ResourceMetaTagData.OgImageURL }
            );

            this.metaTagService.updateTag(
              { name: 'og:image:alt', content: this.ResourceMetaTagData.PageTitle }
            );

            this.metaTagService.updateTag(
              { name: 'description', content: this.ResourceMetaTagData.OgDescription }
            );

            this.metaTagService.updateTag(
              { name: 'title', content: this.ResourceMetaTagData.PageTitle }
            );

            this.metaTagService.updateTag(
              { name: 'keywords', content: this.ResourceMetaTagData.OgKeyWords }
            );

            this.metaTagService.updateTag(
              { name: 'image', content: this.ResourceMetaTagData.OgImageURL }
            );

            this.metaTagService.updateTag(
              { name: 'image:alt', content: this.ResourceMetaTagData.PageTitle }
            );

            this.metaTagService.updateTag(
              { name: 'twitter:description', content: this.ResourceMetaTagData.OgDescription }
            );
            this.metaTagService.updateTag(
              { name: 'twitter:title', content: this.ResourceMetaTagData.PageTitle }
            );
            this.metaTagService.updateTag(
              { name: 'twitter:image', content: this.ResourceMetaTagData.OgImageURL }
            );
            //seo code for tags end
          });
    }
  }


}
