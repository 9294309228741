import { Component, OnInit } from '@angular/core';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { HttpclientService } from 'src/app/modules/application/httpclient/httpclient.service';
import { environment } from '../../../../../environments/environment';
import { Title, Meta } from '@angular/platform-browser';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CanonicalService } from 'src/app/canonical.service';
const STATE_KEY_ITEMS = makeStateKey('items');

@Component({
  selector: 'advertise-brand',
  templateUrl: './advertise-brand.component.html',
  styleUrls: ['./advertise-brand.component.scss']
})
export class AdvertiseBrandComponent implements OnInit {

  items: any = [];
  industry_outreach: any;
  href:string;
  lastString:string;
  firstString: string;
  envSiteName:any;
  siteName:any;
  domain:any;
  domain1:any;
  scrollToTop: number;
  pos: number;
  
  constructor(private state: TransferState,private titleService: Title,private metaTagService: Meta,private httpclientService: HttpclientService,private canonical:CanonicalService,@Inject(PLATFORM_ID) private platformId: Object,private sanitizer:DomSanitizer,
  @Inject(APP_ID) private appId: string,public router:Router) { 
    this.envSiteName=environment.siteName;
  }

  ngOnInit(): void {
   
    this.siteName=this.envSiteName.replace("."," ");
    this.domain=this.envSiteName.replace(".report","").replace(".network","");
    
    if(this.envSiteName=="Virtualization.network"){
      this.domain1="Network";
    }
    else{
      this.domain1="Report";
    }
    
    switch(this.envSiteName)
    {
        case 'abm.report':
          this.industry_outreach=" 37.8M";
          break;
        case 'advertising.report':
          this.industry_outreach=" 7.3M";
          break;
        case 'aviation.report':
          this.industry_outreach=" 44.6M";
          break;
        case 'biotechnology.report':
          this.industry_outreach=" 16.1M";
          break;
        case 'biotech.report':
            this.industry_outreach=" 16.1M";
            break;
        case 'capital.report':
          this.industry_outreach=" 37.8M";
          break;
        case 'channel.report':
          this.industry_outreach=" 7.3M";
          break;
        case 'chemical.report':
          this.industry_outreach=" 16.1M";
          break;
        case 'cloud.report':
          this.industry_outreach=" 44.6M";
          break;
        case 'dataanalytics.report':
          this.industry_outreach=" 44.6M";
          break;
        case 'education.report':
          this.industry_outreach=" 16.9M";
          break;
        case 'engineering.report':
          this.industry_outreach=" 26.4M";
          break;
        case 'entertainment.report':
          this.industry_outreach=" 17.7M";
          break;
        case 'government.report':
          this.industry_outreach=" 6.9M";
          break;
        case 'greenenergy.report':
          this.industry_outreach=" 3.5M";
          break;
        case 'healthcare.report':
          this.industry_outreach="20.9M";
          break;
        case 'humanresources.report':
          this.industry_outreach=" 1.1M ";
          break;
        case 'informationsecurity.report':
          this.industry_outreach=" 44.6M";
          break;
        case 'infotech.report':
          this.industry_outreach=" 44.6M";
          break;
        case 'itinfrastructure.report':
          this.industry_outreach=" 44.6M";
          break;
        case 'manufacturing.report':
          this.industry_outreach=" 26.4M";
          break;
        case 'networking.report':
          this.industry_outreach="  44.6M";
          break;
        case 'nonprofit.report':
          this.industry_outreach=" 4.9M";
          break;
        case 'policy.report':
          this.industry_outreach=" 2.4M";
          break;
        case 'pos.report':
          this.industry_outreach=" 15.9M";
          break;
        case 'pharmaceutical.report':
          this.industry_outreach="20.9M";
          break;
        case 're.report':
          this.industry_outreach=" 13.6M";
          break;
        case 'smallbusiness.report':
          this.industry_outreach=" 18.9M";
          break;
        case 'theinternetofthings.report':
          this.industry_outreach=" 44.6M";
          break;
        case 'travel.report':
          this.industry_outreach=" 17.7M";
          break;
        case 'virtualization.report':
          this.industry_outreach=" 44.6M";
          break;
        case 'wheels.report':
          this.industry_outreach=" 15.2M";
          break;

    }

  }


}
