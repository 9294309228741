import { Component, OnInit } from '@angular/core';
import { HttpclientService } from 'src/app/modules/application/httpclient/httpclient.service';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { environment } from '../../../../../../src/environments/environment';

const STATE_KEY_ITEMS = makeStateKey('items');


@Component({
  selector: 'app-global-guest-authors',
  templateUrl: './global-guest-authors.component.html',
  styleUrls: ['./global-guest-authors.component.scss']
})
export class GlobalGuestAuthorsComponent implements OnInit {

  items: any = [];
  dumArray:any=[1,2,3,4,5,6,7,8,9];
  currentIndex = -1;
  title = '';
  page = 1;
  count = 0;
  pageSize = 9;

allglobalGuestAuthorsDatas: any;
allglobalGuestAuthorsloaded: boolean;
envSiteName:any;
  scrollToTop: number;
  pos: number;
  pagevalidation:boolean;

constructor(private state: TransferState,private httpclientService: HttpclientService,@Inject(PLATFORM_ID) private platformId: Object,
@Inject(APP_ID) private appId: string) {
  this.envSiteName=environment.siteName;
 }

  ngOnInit(): void {
    this.allglobalGuestAuthorsData();
  }


  allglobalGuestAuthorsData(): void {
    
    this.items = this.state.get(STATE_KEY_ITEMS, <any> []);

    this.allglobalGuestAuthorsloaded = false;

    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/GlobalGuestAuthors/GetGlobalGuestAuthors?siteName='+this.envSiteName+'&pageNumber='+this.page)
    .subscribe(
      items => {
        
        const platform = isPlatformBrowser(this.platformId) ?
        'in the browser' : 'on the server';
        this.allglobalGuestAuthorsDatas = items;
        this.count = this.allglobalGuestAuthorsDatas[0].TotalDataCount;
      
        this.allglobalGuestAuthorsloaded = true;
        this.state.set(STATE_KEY_ITEMS, <any> items);
        //console.log("allglobalGuestAuthorsDatas", this.allglobalGuestAuthorsDatas);
        if(this.count>9)
        {

          this.pagevalidation=false;
        }
       
      }
    );
  }
  resetUsers(): void {
    this.allglobalGuestAuthorsDatas = null;
    this.allglobalGuestAuthorsloaded = true;
  }
  handlePageChange(event): void {
    this.resetUsers();
    this.page = event;
    this.allglobalGuestAuthorsData();
    this.onActivate();
  }
  onActivate() {
    this.scrollToTop = window.setInterval(() => {
        this.pos = window.pageYOffset;
        //console.log("pos value",this.pos)
        if (this.pos > 0) {
            window.scrollTo(0, this.pos - 40); // how far to scroll on each step
        } 
        else {
            window.clearInterval(this.scrollToTop);  
        }
    }, 2);
}
}
