<h2 class="text-uppercase mb-3 mt-2">Related News</h2>

<div class="row" *ngIf='singleRelatedNewsLoaded==false'>
    <div class="col-md-12 pb-5" *ngFor="let d of dumArray">
        <list-content-loader></list-content-loader>
    </div>
</div>
<div class="row d-md-flex" *ngIf="singleRelatedNewsLoaded">
    <div class="col-md-12 pb-5" *ngFor="
    let singleRelatedNew of singleRelatedNews | paginate : {
            itemsPerPage: pageSize,
            currentPage: page,
            totalItems: count
          };
    let i = index
  " [class.active]="i == currentIndex" (click)="setActiveTutorial(tutorial, i)">
        
        <div class="cursor-p" (click)="redirect(singleRelatedNew.RouteURL)">
           
            <p class="para-2 mb-0">{{singleRelatedNew.Tag}}</p>
            <a target="_blank" href="{{singleRelatedNew.RouteURL}}">
            <h4 class="text-height-4 card-header-height-1 text-uppercase" [innerHTML]="singleRelatedNew.Title">
            </h4>
            </a>
            <p class="para-2 mb-1">{{singleRelatedNew.Source}} | {{singleRelatedNew.Date}} </p>
            <a target="_blank" href="{{singleRelatedNew.RouteURL}}">
            <img class="img-fluid pb-2" [src]="singleRelatedNew.ImageUrl" alt="news image">
            </a>
            <p class="para-1 text-justify mb-1 text-height-4" [innerHTML]="singleRelatedNew.Description"></p>
            <!-- <a target="_blank" routerLink="/news/{{singleRelatedNew.EventDifferentType}}/{{singleRelatedNew.RouteURL}}/{{singleRelatedNew.ID}}"> -->
            <a class="para-2 para-2-c-2" target="_blank" href="{{singleRelatedNew.RouteURL}}">Read More</a>
        <!-- </a> -->
        </div>
    </div>
</div>

<div class="row">
    <div class="col-12 p-0 text-center" *ngIf="count>4">
        <pagination-controls previousLabel="Prev" nextLabel="Next" responsive="true" (pageChange)="handlePageChange($event)"></pagination-controls>
    </div>
</div>