import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './modules/general/home/home.component';
import { NotFoundComponent } from './modules/general/not-found/not-found.component';
import { TermsPageComponent } from './modules/application/terms-page/terms-page.component';
import { PrivacyPageComponent } from './modules/application/privacy-page/privacy-page.component';
import { CookiesComponent } from './modules/application/cookies/cookies.component';
import { GlobalSearchComponent } from './modules/application/header/global-search/global-search.component';
import { ThankYouPageComponent } from './modules/application/thank-you-page/thank-you-page.component';
import { CategoryTag } from './modules/application/category-tag/category-tag.component';
import { AdvertiseComponent } from './modules/application/Advertise/Advertise/Advertise.component';
import {AdvertiseThankYouPageComponent} from './modules/application/Advertise/advertise-thank-you-page/advertise-thank-you-page.component';
const routes: Routes = [
  {
    path: '', component: HomeComponent
   },
  {
  path: 'news',
    loadChildren: () => import('./modules/general/news/news.module')
    .then(mod => mod.NewsModule)
},
  {
  path: 'events',
    loadChildren: () => import('./modules/general/events/events.module')
    .then(mod => mod.EventsModule)
 },
  {
  path: 'resources',
    loadChildren: () => import('./modules/general/resources/resources.module')
    .then(mod => mod.ResourcesModule)
  },
  {
  path: 'companies',
    loadChildren: () => import('./modules/general/companies/companies.module')
    .then(mod => mod.CompaniesModule)
  },
   {
  path: 'httpclient',
    loadChildren: () => import('./modules/application/httpclient/httpclient.module')
    .then(mod => mod.HttpclientModule)
  },
  { path: 'terms-and-service', component:TermsPageComponent },
  { path: 'privacy-policy', component:PrivacyPageComponent },
  { path: 'cookies', component:CookiesComponent },
  { path: 'global-search', component:GlobalSearchComponent },
  { path: 'thank-you', component:ThankYouPageComponent },
  { path: 'tag/:tagname', component: CategoryTag },
  { path: 'advertise', component: AdvertiseComponent },
  { path: 'advertise/thankyou', component:AdvertiseThankYouPageComponent},
  { path: '**', component: NotFoundComponent },


  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
