import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpclientService } from '../../../../../src/app/modules/application/httpclient/httpclient.service';
import { Title, Meta } from '@angular/platform-browser';
import { CanonicalService } from 'src/app/canonical.service';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss']
})
export class CookiesComponent implements OnInit {
  scrollToTop: number;
  pos: number;
  envSiteName:any;
  preSite: any;
  Site_Domain:any;
  siteName:any;
  iData:any;
  loaded:boolean;


  constructor(private httpclientService: HttpclientService,private metaTagService: Meta,private canonical:CanonicalService,private titleService: Title) {
    this.envSiteName=environment.siteName;
   }

  ngOnInit(): void {
    this.onActivate();
    this.LogoData();
    this.metaTagService.updateTag(
      {name:'robots',content:'index,follow'}
    );
  }
  LogoData(): void {
    this.loaded = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/Home/GetMasterMetaCode?PubsiteName='+this.envSiteName)
      .subscribe(
          items  => {
          this.iData = items[0];
          this.preSite = this.iData.PreSite;
          this.Site_Domain=this.iData.Site_Domain
          this.loaded = true;
          this.siteName=this.iData.siteName;

          //console.log("meta title",this.envSiteName);
          this.titleService.setTitle('Website cookies of Use | '+ this.Site_Domain);
      
          this.canonical.CreateCanonicalLink();

          this.metaTagService.updateTag(
            {name: 'title',content:'Website cookies of Use | '+ this.Site_Domain}
          );
      
          this.metaTagService.updateTag(
            {name:'description',content:'This page describes the cookies policy.'}
          );
      
          this.metaTagService.updateTag(
            {name:'keywords',content:'cookies'}
          );

        });
  }
  onActivate() {
    this.scrollToTop = window.setInterval(() => {
        this.pos = window.pageYOffset;
        if (this.pos > 0) {
            window.scrollTo(0, this.pos - 40); // how far to scroll on each step
        } 
        else {
            window.clearInterval(this.scrollToTop);  
        }
    }, 2);
}

}
