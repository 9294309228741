import { Component, OnInit } from '@angular/core';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { HttpclientService } from 'src/app/modules/application/httpclient/httpclient.service';
import { environment } from '../../../../../environments/environment';
import { Title, Meta } from '@angular/platform-browser';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CanonicalService } from 'src/app/canonical.service';
const STATE_KEY_ITEMS = makeStateKey('items');

@Component({
  selector: 'advertise-community',
  templateUrl: './advertise-community.component.html',
  styleUrls: ['./advertise-community.component.scss']
})
export class AdvertiseCommunityComponent implements OnInit {

  items: any = [];
  datacount:any=[];
  dataloaded :boolean;

  interview_items: any = [];
  interview_datacount:any=[];
  interview_dataloaded :boolean;

  href:string;
  envSiteName:any;
  scrollToTop: number;
  pos: number;

  constructor(private state: TransferState,private titleService: Title,private metaTagService: Meta,private httpclientService: HttpclientService,private canonical:CanonicalService,@Inject(PLATFORM_ID) private platformId: Object,private sanitizer:DomSanitizer,
  @Inject(APP_ID) private appId: string,public router:Router) { 
    this.envSiteName=environment.siteName;
  }

  ngOnInit(): void {
     this.DataCount();
     this.InterviewDataCount();
  }

   DataCount(): void{
    this.items=this.state.get(STATE_KEY_ITEMS,<any>[]);
    this.dataloaded=false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/DataCount/GetPubSiteDataCount?siteName='+this.envSiteName)
   
    .subscribe(
      items =>{
        const platform=isPlatformBrowser(this.platformId)?'in the browser':'on the server';
        this.datacount=items[0];
        this.dataloaded=true;
        this.state.set(STATE_KEY_ITEMS, <any> items);
        window.scrollTo(0,0);
      }
    )
  }

  InterviewDataCount(): void{
    this.interview_items=this.state.get(STATE_KEY_ITEMS,<any>[]);
    this.interview_dataloaded=false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/DataCount/GetPubsiteInterviewDataCount?siteName='+this.envSiteName)
   
    .subscribe(
      interview_items =>{
        const platform=isPlatformBrowser(this.platformId)?'in the browser':'on the server';
        this.interview_datacount=interview_items[0];
        this.interview_dataloaded=true;
        this.state.set(STATE_KEY_ITEMS, <any> interview_items);
        window.scrollTo(0,0);
      }
    )
  }


}
