import { Component, OnInit } from '@angular/core';
import { HttpclientService } from '../../application/httpclient/httpclient.service';
import { NavigationEnd, Router } from '@angular/router'; //import router
import { environment } from '../../../../../src/environments/environment';


@Component({
  selector: 'app-horizontal-ad',
  templateUrl: './horizontal-ad.component.html',
  styleUrls: ['./horizontal-ad.component.scss']
})
export class HorizontalAdComponent implements OnInit {

  adDatas: any;
  adLoaded: boolean;
  masterDatas: any;
  masterLoaded: boolean;
  envSiteName:any;

  constructor( private httpclientService: HttpclientService,public router:Router   ) { 
    this.envSiteName=environment.siteName;
  }

  ngOnInit(): void {
    this.getAd();
    this.masterData();
  }
  
  getAd(): void {
    this.adLoaded = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/Home/GetAddCode?siteName='+this.envSiteName+'&position='+'Home_Top')
      .subscribe(
        items => {
          this.adDatas = items;
          this.adLoaded = true;
        });
  }
  masterData(): void {
    this.masterLoaded = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/Home/GetMasterMetaCode?PubsiteName='+this.envSiteName)
      .subscribe(
        items => {
          this.masterDatas = items;
          this.masterLoaded = true;
        });
  }

}
