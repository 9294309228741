<h2 class="text-uppercase mb-0 mt-2">
    Resources</h2>
   
<div class="tab-pane fade show active pt-2" id="resource-tab1" role="tabpanel" aria-labelledby="home-tab">
    <div class="row d-none d-md-flex" *ngIf="ResourcesLoaded">
        <div class="col-md-12 pb-5" *ngFor="let resourceData of ResourcesDatas">
            <!-- <div class="cursor-p" (click)="redirect(resourceData.ResourceType,resourceData.RouteURL,resourceData.ID)"> -->
               <a target="_blank" routerLink='/{{resourceData.RouteURL}}'>
                <img class="resources-img-h pb-2 pr-5" [src]="resourceData.ImageUrl" alt="news image">

                <p class="para-2 mb-0 text-uppercase">{{resourceData.Tag}}</p>
                <h4 class="text-uppercase">{{resourceData.WhitePaperTitle}}</h4>
</a>

            <!-- </div> -->
            <p class="para-3 text-capitalize">{{resourceData.ResourceType}}</p>
        </div>
    </div>
</div>