<h2 class="text-uppercase mb-0">Other Authors</h2>

<div class="row d-md-flex mt-3" *ngIf="otherAuthorsLoaded" >
    
    <div class="col-md-4 mb-5" *ngFor="let otherAuthorsData of otherAuthorsDatas | paginate : {
        itemsPerPage: pageSize,
        currentPage: page,
        totalItems: count 
      };
let i = index" [class.active]="i == currentIndex">
<!-- <div class="cursor-p" routerLink='{{otherAuthorsData.RouteURL}}/{{otherAuthorsData.UserDetailsId}}'>         -->
<div class="row">
            <!-- <img class="img-fluid pb-2" [src]="otherAuthorsData.ImageUrl" alt="Author" > -->
            <h4 class="text-height-2 card-header-height-2 text-uppercase" [innerHTML]="otherAuthorsData.AuthorName"></h4>
            <!-- <p class="para-2 mb-1">{{otherAuthorsData.JobTitle}}</p> -->
            <p class="para-1 text-justify mb-1 text-height-4" [innerHTML]="otherAuthorsData.CompanyName"></p>
        <!-- </div> -->
    </div>
    </div>
</div>



