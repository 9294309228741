import { Component, OnInit } from '@angular/core';
import { HttpclientService } from '../../application/httpclient/httpclient.service';
import { Title, Meta } from '@angular/platform-browser';
import { environment } from '../../../../../src/environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CanonicalService } from 'src/app/canonical.service';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { ActivationEnd } from '@angular/router';
import { filter } from "rxjs/operators";

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {
  EventNewsDatas: any;
  EventLoaded: boolean;
  pastEventsDatas: any;
  pastEventsLoaded: boolean;
  EventMetaTagLoaded: boolean;
  EventMetaTagData: any;
  envSiteName:any;

  GlobalEventTopAD:SafeResourceUrl;
  GlobalUpcomingConferencesTopAD:SafeResourceUrl;
  GlobalPastConferencesTopAD:SafeResourceUrl;
  GlobalLiveWebinarTopAD:SafeResourceUrl;
  GlobalOndemandWebinarTopAD:SafeResourceUrl;

  GlobalEventADAfterSpotlight:any;
  GlobalUpcomingConferencesADAfterSpotlight:SafeResourceUrl;
  GlobalPastConferencesADAfterSpotlight:SafeResourceUrl;
  GlobalLiveWebinarADAfterSpotlight:SafeResourceUrl;
  GlobalOndemandWebinarADAfterSpotlight:SafeResourceUrl;

  event:string='https://'+environment.siteName+'/ADcodeFile/GlobalEventTopAD.html';
  upconferenceTop:string='https://'+environment.siteName+'/ADcodeFile/GlobalUpcomingConferencesTopAD.html';
  psconferenceTop:string='https://'+environment.siteName+'/ADcodeFile/GlobalPastConferencesTopAD.html';
  liwebinarTop:string='https://'+environment.siteName+'/ADcodeFile/GlobalLiveWebinarTopAD.html';
  ondewebinarTop:string='https://'+environment.siteName+'/ADcodeFile/GlobalOndemandWebinarTopAD.html';

  eventSpo:string='https://'+environment.siteName+'/ADcodeFile/GlobalEventADAfterSpotlight.html'
  upconferenceSpo:string='https://'+environment.siteName+'/ADcodeFile/GlobalUpcomingConferencesADAfterSpotlight.html'
  psconferenceSpo:string='https://'+environment.siteName+'/ADcodeFile/GlobalPastConferencesADAfterSpotlight.html'
  liwebinarSpo:string='https://'+environment.siteName+'/ADcodeFile/GlobalLiveWebinarADAfterSpotlight.html'
  ondewebinarSpo:string='https://'+environment.siteName+'/ADcodeFile/GlobalOndemandWebinarADAfterSpotlight.html'
  
  events:String= "https://"+window.location.hostname+"/events";
  upcnfrnsurl:String= "https://"+window.location.hostname+"/events/upcoming-conferences";
  pascnfrnsurl:string = "https://"+window.location.hostname+"/events/past-conferences";
  livwebinarurl:String= "https://"+window.location.hostname+"/events/live-webinars";
  ondewebinarurl:string = "https://"+window.location.hostname+"/events/on-demand-webinars";
  currentpageeurl:string=window.location.href;

  constructor(private router: Router,private httpclientService: HttpclientService,private titleService: Title,private metaTagService: Meta, private sanitizer:DomSanitizer,private canonical:CanonicalService) 
  {
    this.router.events.pipe(
      filter(event => event instanceof ActivationEnd))
      .subscribe(e => {
        this.MetaData((e as ActivationEnd).snapshot);
      });
    this.envSiteName=environment.siteName;
  }

  MetaData(snapshot: ActivatedRouteSnapshot): void {
    let parent: ActivatedRouteSnapshot = snapshot.parent as ActivatedRouteSnapshot;
    let path: string = 'Events';
    let componentName : string;
    if (typeof(parent.component)==='function')
    {
      componentName = parent.component.name;
    }
    if(componentName != 'EventsComponent') 
      return;

    if (snapshot.routeConfig.path) {
      path = snapshot.routeConfig.path;
    }

    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/Home/GetMetaCode?PubsiteName='+this.envSiteName+'&PageName='+path)
    .subscribe(
      items => {
        this.EventMetaTagData = items[0];
        this.EventMetaTagLoaded=true;
        //console.log('this.EventMetaTagData',this.EventMetaTagData);


          //seo code for tags
          this.canonical.CreateCanonicalLink();

          this.titleService.setTitle(this.EventMetaTagData.PageTitle);
          this.metaTagService.updateTag(
            { name: 'description', content: this.EventMetaTagData.OgDescription}
          );
          this.metaTagService.updateTag(
              { name: 'keywords', content: this.EventMetaTagData.OgKeyWords}
          );
  
          // this.metaTagService.updateTag(
          //   {name:'robots',content:'index,follow'}
          // );

          this.metaTagService.updateTag(
          { name: 'og:description', content: this.EventMetaTagData.OgDescription}
          );

          this.metaTagService.updateTag(
            { name: 'og:title', content: this.EventMetaTagData.PageTitle}
          );

          this.metaTagService.updateTag(
            { name: 'og:image', content: this.EventMetaTagData.OgImageURL}
          );

          this.metaTagService.updateTag(
            { name: 'og:image:alt', content: this.EventMetaTagData.PageTitle}
          );
          this.metaTagService.updateTag(
            { name: 'og:keywords', content: this.EventMetaTagData.OgKeyWords}
          );

          this.metaTagService.updateTag(
            { name: 'description', content: this.EventMetaTagData.OgDescription}
            );
  
            this.metaTagService.updateTag(
              { name: 'title', content: this.EventMetaTagData.PageTitle}
            );
            this.metaTagService.updateTag(
            { name: 'keywords', content: this.EventMetaTagData.OgKeyWords}
          );
            this.metaTagService.updateTag(
              { name: 'image', content: this.EventMetaTagData.OgImageURL}
            );
  
            this.metaTagService.updateTag(
              { name: 'image:alt', content: this.EventMetaTagData.PageTitle}
            );

          this.metaTagService.updateTag(
            { name: 'twitter:description', content: this.EventMetaTagData.OgDescription}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:keywords', content: this.EventMetaTagData.OgKeyWords}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:title', content: this.EventMetaTagData.PageTitle}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:image', content: this.EventMetaTagData.OgImageURL}
          );
          //seo code for tags end
        });


  }

  ngOnInit(): void {
    // this.AllMetaTagEvent();
    this.GlobalEventTopAD=this.sanitizer.bypassSecurityTrustResourceUrl(this.event);
    this.GlobalUpcomingConferencesTopAD=this.sanitizer.bypassSecurityTrustResourceUrl(this.upconferenceTop);
    this.GlobalPastConferencesTopAD=this.sanitizer.bypassSecurityTrustResourceUrl(this.psconferenceTop);
    this.GlobalLiveWebinarTopAD=this.sanitizer.bypassSecurityTrustResourceUrl(this.liwebinarTop);
    this.GlobalOndemandWebinarTopAD=this.sanitizer.bypassSecurityTrustResourceUrl(this.ondewebinarTop);

    this.GlobalEventADAfterSpotlight=this.sanitizer.bypassSecurityTrustResourceUrl(this.eventSpo);
    this.GlobalUpcomingConferencesADAfterSpotlight=this.sanitizer.bypassSecurityTrustResourceUrl(this.upconferenceSpo);
    this.GlobalPastConferencesADAfterSpotlight=this.sanitizer.bypassSecurityTrustResourceUrl(this.psconferenceSpo);
    this.GlobalLiveWebinarADAfterSpotlight=this.sanitizer.bypassSecurityTrustResourceUrl(this.liwebinarSpo);
    this.GlobalOndemandWebinarADAfterSpotlight=this.sanitizer.bypassSecurityTrustResourceUrl(this.ondewebinarSpo);

    //this.EventsAD();
    // this.pastEvents();
  }

  ngAfterViewInit() {
    try {
      (window['adsbygoogle'] = window['adsbygoogle'] || []).push({});
      (window['adsbygoogle'] = window['adsbygoogle'] || []).push({});
    } catch (e) { }
  }
  
  EventsAD(): void{

    // alert(this.fnurl);
    if(this.upcnfrnsurl == window.location.href){
       document.getElementById('upconferenceTop').classList.add('displayblock');
       document.getElementById('upconferenceSpo').classList.add('displayblock');
    }else if(this.pascnfrnsurl == window.location.href){
       document.getElementById('psconferenceTop').classList.add('displayblock'); 
       document.getElementById('psconferenceSpo').classList.add('displayblock'); 
    }else if(this.livwebinarurl == window.location.href){
       document.getElementById('liwebinarTop').classList.add('displayblock'); 
       document.getElementById('liwebinarSpo').classList.add('displayblock'); 
    }else if(this.ondewebinarurl == window.location.href){
      document.getElementById('ondewebinarTop').classList.add('displayblock'); 
      document.getElementById('ondewebinarSpo').classList.add('displayblock'); 
    }else{
       document.getElementById('event').classList.add('displayblock'); 
       document.getElementById('eventSpo').classList.add('displayblock'); 
     }
  }

  AllMetaTagEvent(): void {
    if(this.EventMetaTagLoaded!=true){
    this.EventMetaTagLoaded = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/Home/GetMetaCode?PubsiteName='+this.envSiteName+'&PageName='+'Events')
    .subscribe(
      items => {
        this.EventMetaTagData = items[0];
        this.EventMetaTagLoaded=true;
        //console.log('this.EventMetaTagData',this.EventMetaTagData);


          //seo code for tags
          this.canonical.CreateCanonicalLink();

          this.titleService.setTitle(this.EventMetaTagData.PageTitle);

          // this.metaTagService.updateTag(
          //   {name:'robots',content:'index,follow'}
          // );

          this.metaTagService.updateTag(
          { name: 'og:description', content: this.EventMetaTagData.OgDescription}
          );

          this.metaTagService.updateTag(
            { name: 'og:title', content: this.EventMetaTagData.PageTitle}
          );

          this.metaTagService.updateTag(
            { name: 'og:image', content: this.EventMetaTagData.OgImageURL}
          );

          this.metaTagService.updateTag(
            { name: 'og:image:alt', content: this.EventMetaTagData.PageTitle}
          );
          this.metaTagService.updateTag(
            { name: 'og:keywords', content: this.EventMetaTagData.OgKeyWords}
          );

          this.metaTagService.updateTag(
            { name: 'description', content: this.EventMetaTagData.OgDescription}
            );
  
            this.metaTagService.updateTag(
              { name: 'title', content: this.EventMetaTagData.PageTitle}
            );
            this.metaTagService.updateTag(
            { name: 'keywords', content: this.EventMetaTagData.OgKeyWords}
          );
            this.metaTagService.updateTag(
              { name: 'image', content: this.EventMetaTagData.OgImageURL}
            );
  
            this.metaTagService.updateTag(
              { name: 'image:alt', content: this.EventMetaTagData.PageTitle}
            );

          this.metaTagService.updateTag(
            { name: 'twitter:description', content: this.EventMetaTagData.OgDescription}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:keywords', content: this.EventMetaTagData.OgKeyWords}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:title', content: this.EventMetaTagData.PageTitle}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:image', content: this.EventMetaTagData.OgImageURL}
          );
          //seo code for tags end
        });
      }
  }

}
