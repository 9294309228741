<div class="row">
    <div class="text-center add-center HideSliderMobile" >
        <!-- <iframe style='min-width: 743px; max-height:110px; border: none;' class="HideSliderMobile" ng-include [src]="GlobalCompanyTopAD"></iframe></div> -->
        <ins class="adsbygoogle" style="display:inline-block;width:728px;height:90px"
        data-ad-client="ca-pub-4564607713805390" data-ad-slot="7275649274"></ins>
    <div class="col-md-12">
        <router-outlet>
        </router-outlet>
        
    </div>
   
</div>
