import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router";
import { filter } from "rxjs/operators";
import {  HostListener}  from "@angular/core";
import { CookieService } from 'ngx-cookie-service';
import { Guid } from 'guid-typescript';
import { HttpclientService } from './../../application/httpclient/httpclient.service';
import {environment} from './../../../../environments/environment'
@Component({
  selector: 'app-route-tracker',
  templateUrl: './route-tracker.component.html',
  styleUrls: ['./route-tracker.component.scss']
})
export class RouteTrackerComponent implements OnInit {
  private previousUrl: string = '';
  private currentUrl: string = '';
  private envSiteName : string;
  public id:Guid;
  public apiUrl:string = 'https://newapi.nathanark.com/api';
  constructor(router: Router,private cookieService : CookieService,private httpclientService: HttpclientService) {
    this.envSiteName=environment.siteName;
    this.getUserTrackingCookie();
    router.events
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.previousUrl = this.currentUrl;
          this.currentUrl = event.url;
          this.navigation();
        }
      });
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event) {
    let rawData = {
      "userID" : this.getUserTrackingCookie(),
      "siteName" : this.envSiteName,
      "url" : window.location.protocol + '//' + this.envSiteName + this.currentUrl,
    }
    this.sendRequest('/session/NavigationEnd',rawData);

    //event.returnValue = false;
    //event.preventDefault();
    //event.returnValue = confirm(`You have unsaved changes, leave anyway?`);
    //event.returnValue = false;
    // Your logic on beforeunload
  }



  ngOnInit(): void {
  }

  navigation() : void {
    let rawData = {
      "userID" : this.getUserTrackingCookie(),
      "siteName" : this.envSiteName,
      "url" : window.location.protocol + '//' + this.envSiteName + this.currentUrl,
      "previousUrl" : window.location.protocol + '//' + this.envSiteName + this.previousUrl
    }
    this.sendRequest('/session/Navigation',rawData);
  }

  sendRequest(url:string,rawData:any) {
    url = this.apiUrl + url;
    this.httpclientService.postHttpclient(url,window.JSON.stringify(rawData)).subscribe(e=>{
    })
  }

  getUserTrackingCookie() : string {
    const userTrackingCookieName : string ="usertracking_userid";
    let cookieValue =  this.cookieService.get(userTrackingCookieName);
    if(!cookieValue)
    {
        cookieValue  = Guid.create().toString();
        this.cookieService.set(userTrackingCookieName,cookieValue,365)
    }

    return cookieValue
  }

}
