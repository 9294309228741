<p class="mb-3 mt-2 para-2-c-2">
    <a href="/">Home</a> > <a href="/news">News</a> > <a href="/news/archive-news" class="para-2-c-1">Archive News</a>
</p>
<h2 class="text-uppercase mb-3">Archive News</h2>
<div class="row" *ngIf='archivedloaded==false'>
    <div class="col-md-4 pb-5" *ngFor="let d of dumArray">
        <list-content-loader></list-content-loader>
    </div>
</div>
<div class="row d-md-flex" *ngIf="archivedloaded">
    <div class="col-md-4 pb-5" *ngFor="
    let archivedNewsData of archivedNewsDatas | paginate : {
            itemsPerPage: pageSize,
            currentPage: page,
            totalItems: count
          };
    let i = index
  " [class.active]="i == currentIndex" (click)="setActiveTutorial(tutorial, i)">
        <div class="cursor-p" [routerLink]='archivedNewsData.ID'>
            <img class="img-fluid pb-2 image-hight-news" [src]="archivedNewsData.ImageUrl" alt="news image">
            <p class="para-2 mb-0">{{archivedNewsData.Tag}}</p>
            <h4 class="text-height-4 card-header-height-1" [innerHTML]="archivedNewsData.Title">
            </h4>
            <p class="para-2 mb-1">{{archivedNewsData.CompanyName}} | {{archivedNewsData.Date}} </p>
            <p class="para-1 text-justify mb-1 text-height-4" [innerHTML]="archivedNewsData.Description"></p>
            <a class="para-2 para-2-c-2 " href="">Read More</a>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12 p-0 text-center">
        <pagination-controls previousLabel="Prev" nextLabel="Next" responsive="true" (pageChange)="handlePageChange($event)"></pagination-controls>
    </div>
</div>