import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'; //import router
import { HttpclientService } from '../../../../application/httpclient/httpclient.service';
import { state, trigger, transition, style, animate } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../../../../src/environments/environment';
import { Title, Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-article-single-page',
  templateUrl: './article-single-page.component.html',
  styleUrls: ['./article-single-page.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        width: '200px',
        opacity: 1,
        display:'block',
      })),
      state('closed', style({
        width: '0px',
        opacity: 0,
        display:'none',
      })),
      transition('open => closed', [
        animate('0.5s')
      ]),
      transition('closed => open', [
        animate('0.5s')
      ]),
    ]),
  ],
})
export class ArticleSinglePageComponent implements OnInit {

  isguestAuthor:boolean;
  authorsType:any;
  authorsName:string;
  singleArticlesLoaded:boolean;
  singleArticles:any;
  newArticlesId:any;
  isOpen = false;
  isOverlay = false;
  fullUrl:any;
  tag: string;
  articlesid:any;
  envSiteName:any;
  authorsId: any;
  routeURl: any;
  UserName: string;
  singleGuestAuthors: any;
  readbutton:boolean;
  SourcceType:string;
  titlePrefix:string;
  loaded:boolean;
  iData:any;
  
  constructor(private httpclientService: HttpclientService,private titleService: Title,private metaTagService: Meta,public router:Router, private activatedRoute: ActivatedRoute) { 
    this.envSiteName=environment.siteName;
  }
  

  href:string;
  lastString:string;
  receiveRefData:any;

  ngOnInit(): void {
    this.singleArticlesData();
    this.singleGuestAuthor();
    this.LogoData();
    this.fullUrl=window.location.href;
    this.articlesid = this.activatedRoute.snapshot.params.id;
  }

  LogoData(): void {
    this.loaded = false;
    this.httpclientService.getHttpclient('https://api.nathanark.com/api/Home/GetMasterMetaCode?PubsiteName='+window.location.hostname)
      .subscribe(
        (  items: any) => {
          this.iData = items;
          this.titlePrefix = this.iData.PreSite;
          this.loaded = true;
        });
  }

  receiveRefresh($event){
    this.resetUsers();
    this.newArticlesId = $event;
    //console.log("receiveRefData",this.lastString);
    this.singleArticlesData();
    //console.log("receiveRefData",this.lastString);
  }

  singleArticlesData(): void {
    if(this.newArticlesId != null){
      this.articlesid=this.newArticlesId;
      //console.log("lastString newArticlesId",this.lastString);
  }else{
    this.articlesid =  this.activatedRoute.snapshot.params.id;
  }
    this.isguestAuthor= false;
    this.singleArticlesLoaded=false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/SingleResources/GetSingleResources?siteName='+this.envSiteName+'&id='+this.articlesid)
      .subscribe(
        items => {
          this.singleArticles = items[0];
          //this.authorsID = items[0].AuthorID;
          this.authorsType = items[0].SourcceType;
          this.UserName =  items[0].UserName;
          this.routeURl = items[0].authorName;
          this.tag = items[0].tag;
          this.authorsId = items[0].UserId;

          //SEO code
          this.titleService.setTitle(this.singleArticles.WhitePaperTitle+' | '+this.titlePrefix);
          this.metaTagService.updateTag(
            { name: 'og:description', content: this.singleArticles.Description}
          );
          this.metaTagService.updateTag(
            { name: 'og:title', content: this.singleArticles.WhitePaperTitle+' | '+this.titlePrefix}
          );
          this.metaTagService.updateTag(
            { name: 'og:image', content: this.singleArticles.ImageUrl}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:description', content: this.singleArticles.Description}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:title', content: this.singleArticles.WhitePaperTitle+' | '+this.titlePrefix}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:image', content: this.singleArticles.ImageUrl}
          );
          //seo code for tags end

          if(this.authorsType == 0){
            this.isguestAuthor = true;
          }
          else
          {
            this.isguestAuthor = false;
            
          }
            this.singleArticlesLoaded=true;
        });
  }

  singleGuestAuthor():void{
   /// this.isguestAuthor= false;
    this.httpclientService.getHttpclient('https://api.nathanark.com/api/GlobalGuestAuthors/GetGlobalGuestAuthors?siteName='+this.envSiteName+'&pageNumber='+1)
      .subscribe(
        items => {
          this.singleGuestAuthors = items[0];
          this.SourcceType=items[0];
          if(this.SourcceType='1')
          {
            this.readbutton=false;
          }

         
         
          this.authorsType = items[0].Role;

          // if(this.authorsType == 'GuestAuthor'){
          //   this.isguestAuthor = true;
          // }
          // else
          // {
          //   this.isguestAuthor = false;
          // }
          //console.log("Author Type:",this.authorsType);
          //console.log("single Authors:",this.singleGuestAuthors);
        });
  }
  
  resetUsers(): void {
    this.singleArticles = null;
    this.singleGuestAuthors= null;
    this.isguestAuthor = false;
    this.singleArticlesLoaded = false;
    this.lastString=null;
    this.href=null;
  }

  shareClick(){
    this.isOpen = !this.isOpen;
  }



}