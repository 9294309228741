<div class="col-md-12">
    <h5>OTHER ON-DEMAND WEBINAR</h5>
    <div class="row d-none d-md-flex" *ngIf="moreOnDemandwebinarLoaded">
        <div class="col-md-6 mb-5" *ngFor="let moreOnDemandWebinartData of moreDemandEventDatas">
            <!-- <div class="cursor-p" (click)="refreshReq(moreOnDemandWebinartData.EventID)" routerLink='/events/on-demand-webinars/{{moreOnDemandWebinartData.RouteURL}}/{{moreOnDemandWebinartData.EventID}}'> -->
                <a href='{{moreOnDemandWebinartData.RouteURL}}' target="_blank">
                <img class="img-fluid pb-2 image-hight-webinar-single-other" [src]="moreOnDemandWebinartData.ImageUrl">
                <h4 class="text-height-4 card-header-height-1 text-uppercase" [innerHTML]="moreOnDemandWebinartData.Name">
                </h4>
        </a>
                <p class="para-2 mb-2">{{moreOnDemandWebinartData.Keywords}} | Sponsored
                    <!-- <span *ngIf="moreOnDemandWebinartData.IsSponsored"><a> | </a> <font class="para-2-c-2">Sponsored</font></span> -->
                </p>
                <p class="para-2 mb-2"> {{moreOnDemandWebinartData.Company}}

                    <!-- <span  *ngIf="trendingNewsData.IsSponsored"><a>| </a> <font class="para-2-c-2">Sponsored</font></span>     -->
                </p>
                <p class="para-1 text-justify mb-1 text-height-4 text-justify" [innerHTML]="moreOnDemandWebinartData.Details"></p>
                <a [href]="moreOnDemandWebinartData.RouteURL" target="_blank">
                <a class="para-2 para-2-c-2 "href='{{moreOnDemandWebinartData.RouteURL}}' target="_blank">Watch Now</a> 
            </a>
                <!-- <a class="para-2 para-2-c-2 " href="{{moreOnDemandWebinartData.URL}}" target="_blank">Watch Now</a> -->
            <!-- </div> -->
        </div>
    </div>
</div>
<!-- <a class="para-2 para-2-c-2 " href="{{singleEvents.URL }}" target="_blank">Register Now</a> -->