import { Component, OnInit, Output, EventEmitter  } from '@angular/core';
import { Router } from '@angular/router'; //import router
import { HttpclientService } from '../../../../application/httpclient/httpclient.service';
import { environment } from '../../../../../../../src/environments/environment';

@Component({
  selector: 'app-other-conference',
  templateUrl: './other-conference.component.html',
  styleUrls: ['./other-conference.component.scss']
})
export class OtherConferenceComponent implements OnInit {

  refreshEmit:any;
  @Output() refreshEmitter= new EventEmitter<string>();

  moreEventLoaded:boolean;
  moreEventDatas:any;
  href:any;
  lastString:any;
  envSiteName:any;
  shortSiteName:string;

  constructor(private httpclientService: HttpclientService,public router:Router) {
    this.envSiteName=environment.siteName;
   }

  ngOnInit(): void {
    this.moreEventData();
  }

  refreshReq(EventID:any){
    
    this.resetUsers();
    
    this.refreshEmit=EventID;
    this.refreshEmitter.emit(this.refreshEmit);
    this.lastString=EventID;
    this.moreEventData();


}
moreEventData(){
  this.shortSiteName=this.envSiteName.substr(0, this.envSiteName.indexOf('.')); 
  if(this.lastString==null){
  this.href = this.router.url;
  this.lastString = this.href.substring(this.href.lastIndexOf('/') + 1);
}
  //console.log("other Event",this.lastString)
  this.moreEventLoaded=false;
  this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/SingleConference/GetSingle4UpCommingConference?SiteName='+this.shortSiteName+'&EventID='+this.lastString)
    .subscribe(
      items => {
        this.moreEventDatas = items;
        this.moreEventLoaded=true;
        //console.log("events moreEventDatas",this.moreEventDatas)
      });
}
resetUsers(): void {
  this.moreEventDatas = null;
  this.moreEventLoaded = false;
  this.lastString=null;
  this.href=null;
}
}
