import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addDash'
})
export class AddDashPipe implements PipeTransform {

  transform(value: string): string {
    return value && value.replace('-',' ');
  }

}
