
<div class="row main">
    <div class="banner mainmb">
        <div class="col-md-12">
            <div class="container-fluid">
            <div class="row v-center "> 
                <div class="col-md-7 text-center banner">
                    <iframe class="Iframewidth" src="https://www.youtube.com/embed/Zqp8A6o_gNA" frameborder="0" allowfullscreen=""></iframe>
                </div>
                <div class="col-md-5 banner paddingMobile">
                    <h1>{{industry_outreach}}</h1>
                    <p>Why target audiences using keywords when you can promote directly to decision makers with intent? Why use only banners and text ads when you can engage your audience using multiple digital tools?</p>
                    <p style="font-weight: bold;">Reach the right audience at the right time with the right message.</p>
                    <p>Preview MEDIA 7's AI-powered media planner and digital tools to reach thousands of potential customers from a highly specialized audience, and engage with buyers in more meaningful ways to nurture them from awareness through conversion.</p>
                </div> 
            </div> 
          </div>
        </div>  
    </div>
    <br/>
    <div class="container-fluid">
       <div class="row">
        <div class="col-md-12">
            <div class="row">
            <div class="col-md-8">
                <advertise-brand></advertise-brand>
                <advertise-community></advertise-community>
            </div>
            <div class="col-md-4"><advertise-signup></advertise-signup></div>
            </div>
          </div>
       </div>
    </div>
</div>