import { Component, OnInit, ɵConsole } from '@angular/core';
import { HttpclientService } from '../../../application/httpclient/httpclient.service';
import { environment } from '../../../../../../src/environments/environment';

@Component({
  selector: 'app-events-news',
  templateUrl: './events-news.component.html',
  styleUrls: ['./events-news.component.scss']
})
export class EventsNewsComponent implements OnInit {

  eventDatas: any;
  loaded: boolean;
  envSiteName:any;

  constructor(private httpclientService: HttpclientService) {
    this.envSiteName=environment.siteName;
   }

  ngOnInit(): void {
    this.newsEventData();
  }
  newsEventData(): void {
    this.loaded = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/News/GetNewsEvent?siteName='+this.envSiteName)
      .subscribe(
        items => {
          this.eventDatas = items[0];
          this.loaded = true;
          //console.log("Event Data:", this.eventDatas);
        });
  }

}
