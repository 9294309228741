<h2 class="text-uppercase text-center mb-0 mt-2">
    Spotlight</h2>
<div class="text-center" *ngIf="loaded">
    
    <div class="cursor-p" (click)="redirect(spotLightDatas.RouteURL,spotLightDatas.id)">
        <a [href]="spotLightDatas.RouteURL" target="_blank">
            
    <!-- <div class="cursor-p" routerLink='{{spotLightDatas.RouteURL}}'> -->
    <img [src]="spotLightDatas.logo" class="img-fluid" alt="Responsive image">
    <h4 class="text-uppercase mt-2 text-center">{{spotLightDatas.company_name}}</h4>
    </a>
   
    <p class="text-justify para-1 spotlight-text-height" [innerHTML]=" spotLightDatas.description"></p>
</div>
</div>