import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NewsComponent } from './news.component';
import { NewsRoutingModule } from './news-routing.module';
import { GlobalNewsComponent } from './global-news/global-news.component';
import { FeaturedNewsComponent } from './featured-news/featured-news.component';
import { TrendingNewsComponent } from './trending-news/trending-news.component';
import { ArchiveNewsComponent } from './archive-news/archive-news.component';
import { ResourcesNewsComponent } from './resources-news/resources-news.component';
import { EventsNewsComponent } from './events-news/events-news.component';
import { SpotlightNewsComponent } from './spotlight-news/spotlight-news.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { NewsSinglePageComponent } from './news-single-page/news-single-page.component';
import { OtherNewsComponent } from './news-single-page/other-news/other-news.component';
import { AddDashPipe } from './news-single-page/add-dash.pipe';

@NgModule({
  declarations: [
    NewsComponent,
    GlobalNewsComponent,
    FeaturedNewsComponent,
    TrendingNewsComponent,
    ArchiveNewsComponent,
    ResourcesNewsComponent,
    EventsNewsComponent,
    SpotlightNewsComponent,
    NewsSinglePageComponent,
    OtherNewsComponent,
    AddDashPipe
  ],
  imports: [
    CommonModule,
    NewsRoutingModule,
    NgxPaginationModule,
    ContentLoaderModule

  ],
  exports: [
    NewsComponent
  ],
  providers: [
  ],
})
export class NewsModule { }
