import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpclientService } from 'src/app/modules/application/httpclient/httpclient.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../../../src/environments/environment';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-single-company-page',
  templateUrl: './single-company-page.component.html',
  styleUrls: ['./single-company-page.component.scss']
})
export class SingleCompanyPageComponent implements OnInit {
  DomainName: string;
  companyNameNews: any;
  href: string;
  companyId: any;
  lastString: string;
  receiveRefData: any;
  singleCompanyLoaded: boolean;
  singleCompany: any;
  singlecompaniesid:any;
  companyNameResource: string;
  envSiteName:any;
  titlePrefix:string;
  loaded:boolean;
  iData:any;

  constructor(private httpclientService: HttpclientService,private titleService: Title,private metaTagService: Meta, public router: Router, private activatedRoute: ActivatedRoute) { 
    this.envSiteName=environment.siteName;
  }
  ngOnInit(): void {
    
    this.LogoData();
   
    this.singlecompaniesid = this.activatedRoute.snapshot.params.id;
  }
  LogoData(): void {
    this.loaded = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/Home/GetMasterMetaCode?PubsiteName='+this.envSiteName)
      .subscribe(
        (  items: any) => {
          this.iData = items;
          this.titlePrefix = this.iData[0].PreSite;
          //console.log("this.titlePrefix",this.titlePrefix)
          this.loaded = true;
          this.singleCompanyData();
        });
  }
  singleCompanyData(): void { 
    if(this.companyId != null){
      this.singlecompaniesid=this.companyId;
  }else{
    this.singlecompaniesid =  this.activatedRoute.snapshot.params.id;
  }
    this.singleCompanyLoaded = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/SingleCompanies/GetTop1SingleCompanyById?siteName='+this.envSiteName+'&companyId=' + this.singlecompaniesid)
      .subscribe(
        items => {
          this.singleCompany = items[0];
          this.companyNameNews = items[0].CompanyName; 
          this.DomainName = items[0].DomainName;
          this.companyNameResource =  items[0].CompanyName;
          //console.log("this.singleCompany",this.singleCompany);

          //SEO code
          if(this.titlePrefix){
          this.titleService.setTitle(this.singleCompany.CompanyName+' | '+this.titlePrefix);
          this.metaTagService.updateTag(
            { name: 'og:description', content: this.singleCompany.Description}
          );
          this.metaTagService.updateTag(
            { name: 'og:title', content: this.singleCompany.CompanyName+' | '+this.titlePrefix}
          );
          this.metaTagService.updateTag(
            { name: 'og:image', content: this.singleCompany.Logo}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:description', content: this.singleCompany.Description}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:title', content: this.singleCompany.CompanyName+' | '+this.titlePrefix}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:image', content: this.singleCompany.Logo}
          );
        }
          //seo code for tags end

          this.singleCompanyLoaded = true;
        });
  }
  resetUsers(): void {
    this.singleCompany = null;
    this.singleCompanyLoaded = false;
    this.singlecompaniesid = null;
    this.href = null;
  }
}
