<h5>OTHER UPCOMING CONFERENCES</h5>
<div class="row d-none d-md-flex" *ngIf="moreEventLoaded">
    <div class="col-md-6 mb-5" *ngFor="let moreEventData of moreEventDatas">
        <!-- <div class="cursor-p" (click)="refreshReq(moreEventData.EventID)" routerLink='/events/upcoming-conferences/{{moreEventData.RouteURL}}/{{moreEventData.EventID}}'> -->
         <a target="_blank" routerLink='upcoming-conferences/{{moreEventData.RouteURL}}' >
            <img class="img-fluid pb-2" [src]="moreEventData.ImageUrl">
            <h4 class="text-height-4 card-header-height-1 text-uppercase" [innerHTML]="moreEventData.Name">
            </h4>
            </a>
            <p class="para-2 mb-2"> {{moreEventData.DateWithStartTime}} | {{moreEventData.Country}}

                <!-- <span  *ngIf="trendingNewsData.IsSponsored"><a>| </a> <font class="para-2-c-2">Sponsored</font></span>     -->
            </p>
            <p class="para-1 text-justify mb-1 text-height-4 text-justify" [innerHTML]="moreEventData.Details"></p>
            <!-- <a class="para-2 para-2-c-2 " href="{{moreEventData.URL }}" target="_blank">Register Now</a> -->
            <a class="para-2 para-2-c-2 " href="upcoming-conferences/{{moreEventData.RouteURL}}">Register Now</a> 

        <!-- </div> -->
    </div>
</div>
<!-- <a class="para-2 para-2-c-2 " href="{{singleEvents.URL }}" target="_blank">Register Now</a> -->