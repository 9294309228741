import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpclientService } from 'src/app/modules/application/httpclient/httpclient.service';

@Component({
  selector: 'app-single-guest-author-spotlight',
  templateUrl: './single-guest-author-spotlight.component.html',
  styleUrls: ['./single-guest-author-spotlight.component.scss']
})
export class SingleGuestAuthorSpotlightComponent implements OnInit {
@Input() AuthorEmailID: any;
href: string;
lastString: string;
CompanyDetailsByAuthorsSpotLightDatas: any;
Loaded: boolean;
CompanyWebsite : string;
  constructor(public router: Router,private httpclientService: HttpclientService) { }

  ngOnInit(): void {
    this.CompanyDetailsByAuthorsSpotLight();
  }
  CompanyDetailsByAuthorsSpotLight(): void {
    
    this.href = this.router.url;
    this.lastString = this.href.substring(this.href.lastIndexOf('/') + 1);

    this.Loaded = false;
    //console.log("SpotLight for Author:", this.AuthorEmailID);
    this.httpclientService.getHttpclient('https://api.nathanark.com/api/SingleGuestAuthorProfile/GetSingleGuestAuthorSpotlightByEmail?email='+ this.AuthorEmailID)
    .subscribe(
      items => {
        this.CompanyDetailsByAuthorsSpotLightDatas = items[0];
        this.CompanyWebsite =this.CompanyDetailsByAuthorsSpotLightDatas.CompanyWebsite;
        //console.log("SpotLight for Author:", this.CompanyDetailsByAuthorsSpotLightDatas);
        this.Loaded = true;
      }
    );
  }
}
