<p class="mb-3 mt-2 para-2-c-2" *ngIf="singleInterviewLoaded">
    <a routerLink="">Home</a> >
    <a routerLink="/companies">Companies</a> >
    <a routerLink="/c-suite-on-deck">C-Suite On Deck</a> >

    <span class="para-2-c-1" [innerHTML]=singleInterviewDatas.InterviewTitle></span>
</p>
<div class="row">

    <div class="col-md-9">
        <div *ngIf="singleInterviewLoaded" class="mb-5">
            <h1 [innerHTML]="singleInterviewDatas.InterviewTitle"></h1>
            <p class="para-4-bold">{{singleInterviewDatas.InterviewTakenBy}} | {{singleInterviewDatas.InterviewDate}}</p>
            <img class="img-fluid" src="{{singleInterviewDatas.InterviewImage}}" alt="{{singleInterviewDatas.RouteURL}}">
            <p class="pt-3 para-6 mb-0 text-justify" [innerHTML]="singleInterviewDatas.InterviewDetails"></p>

            <div class="row">
                <div class="col-md-12">
                    <h6 class="w-30 float-left p-4" *ngIf="singleInterviewDatas.Quote1">
                        <i class="fas fa-quote-left quote-font-size"></i><br>
                        <span class="text-quote" [innerHTML]="singleInterviewDatas.Quote1">
                    </span>
                    </h6>
                    <p class="para-6 para-2-c-1" [innerHTML]=singleInterviewDatas.Desc2></p>
                    <h6 class="w-30 float-left p-4" *ngIf="singleInterviewDatas.Quote2">
                        <i class="fas fa-quote-left quote-font-size"></i><br>
                        <span class="text-quote" [innerHTML]="singleInterviewDatas.Quote2">
                    </span>
                    </h6>
                    <p class="para-6 para-2-c-1" [innerHTML]=singleInterviewDatas.Desc3></p>
                    <h6 class="w-30 float-left p-4" *ngIf="singleInterviewDatas.Quote3">
                        <i class="fas fa-quote-left quote-font-size"></i><br>
                        <span class="text-quote" [innerHTML]="singleInterviewDatas.Quote3">
                    </span>
                    </h6>
                    <p class="para-6 para-2-c-1" [innerHTML]=singleInterviewDatas.Desc4></p>
                </div>
            </div>
            <!-- <span class="para-6 para-2-c-1" [innerHTML]=singleInterviewDatas.Quote3></span> -->


            <div class="pt-4">
                <p class="para-2-c-2 text-uppercase"><b>ABOUT {{singleInterviewDatas.CompanyName}}</b></p>
                <span class="para-6 para-2-c-1" [innerHTML]=singleInterviewDatas.AboutCompany></span>
            </div>
        </div>
        <div class="share-div" (click)="shareClick()">
            <i class="fa fa-share-alt" aria-hidden="true"></i>
        </div>
        <div class="share-in" [@openClose]="isOpen ? 'open' : 'closed'">
            <a href="https://www.facebook.com/sharer.php?u={{fullUrl}}" target="_blank">
                <div class="share-inner f-icon">
                    <i class="fab fa-facebook-f"></i>
                </div>
            </a>
            <a href="https://www.linkedin.com/shareArticle?mini=true&url={{fullUrl}}" target="_blank">
                <div class="share-inner l-icon">
                    <i class="fab fa-linkedin-in"></i>
                </div>
            </a>
            <a href="https://twitter.com/share?url={{fullUrl}}" target="_blank">
                <div class="share-inner t-icon">
                    <i class="fab fa-twitter"></i>
                </div>
            </a>
        </div>

    </div>
    <div class="col-md-3" *ngIf="singleInterviewLoaded">

        <app-single-interview-spotlight [domainData]="domainData"></app-single-interview-spotlight>
        <app-events-company></app-events-company>
        <app-single-resources [company_Name]="companyName"></app-single-resources>
    </div>
</div>