<!-- <div class="row" *ngIf='LiveWebinarLoaded==false && count!=0 else pageloading'>
    <div>
        <p>no live webinars</p>  
    </div>
</div> -->

<div class="row" *ngIf='LiveWebinarLoaded==false'>
    <div class="col-md-4 pb-5" *ngFor="let d of dumArray">
        <list-content-loader></list-content-loader>
    </div>
</div>
<div class="row d-md-flex WrapArt" *ngIf="LiveWebinarLoaded">
<div class="col-md-4 pb-5" *ngFor="let livewebinarNewsData of LiveWebinarDatas | paginate : {
    id:'LiveWebinarPaginatorID',
    itemsPerPage: pageSize,
    currentPage: page,
    totalItems: count
    };
    let i = index
    " [class.active]="i == currentIndex">
        <!-- <div class="cursor-p"> -->
        <!-- <div class="cursor-p" (click)="redirect(livewebinarNewsData.RouteURL,livewebinarNewsData.EventID)"> -->
        <a target="_blank" href="{{livewebinarNewsData.RouteURL}}">
            <img class="img-fluid pb-2 image-hight-news" [src]="livewebinarNewsData.ImageUrl" alt="webinar image">
            <h4 class="text-height-3 card-header-height-3 text-uppercase MainTitle" [innerHTML]="livewebinarNewsData.Name">
            </h4>
        </a>
        <p class="para-2 mb-1 sponDate"> {{livewebinarNewsData.DateWithStartTime}}
            <span *ngIf='livewebinarNewsData.IsSponcered==true'>| <font>Sponsored</font></span>
        </p>
        <p class="para-1 text-justify text-height-4 DescPara" [innerHTML]="livewebinarNewsData.Details"></p>
        <a target="_blank" class="Read_More" href="{{livewebinarNewsData.RouteURL}}">
           Register Now
            </a>
        <!-- <a class="para-2 para-2-c-2 " href="events/live-webinars/{{livewebinarNewsData.RouteURL}}/{{livewebinarNewsData.EventID}} ">Register Now</a> -->
        <!-- </div> -->
    </div>
</div>

<!-- <div class="row">
    <div class="col-12 p-0 text-center mt-md-0" *ngIf="count>9">
        <pagination-controls previousLabel="Prev" nextLabel="Next" responsive="true" (pageChange)="handlePageChange($event)"></pagination-controls>
    </div>
</div> -->

<div class="row">
    <div class="col-12 p-0 text-center mt-md-0" *ngIf="count>9">
        <pagination-controls id='LiveWebinarPaginatorID' previousLabel="Prev" nextLabel="Next" responsive="true" (pageChange)="handlePageChange($event)"></pagination-controls>
    </div>
</div>