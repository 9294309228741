<h5 class="text-uppercase" >Other Infographics</h5>
<div class="row d-none d-md-flex" *ngIf="singleInfographicsOthersLoaded">
    <div class="col-md-4 mb-5" *ngFor="let singleInfographicsOthersData of singleInfographicsOthersDatas">
        <div class="cursor-p" (click)="refreshReq(singleInfographicsOthersData.ID)" routerLink='/infographics/{{singleInfographicsOthersData.RouteURL}}'> 
        <a target="_blank" routerLink='/infographics/{{singleInfographicsOthersData.RouteURL}}' >
            <img class="img-fluid pb-2" [src]="singleInfographicsOthersData.ImageUrl" alt="news image">
            <h4 class="text-height-4 card-header-height-1 text-uppercase" [innerHTML]="singleInfographicsOthersData.WhitePaperTitle"></h4>
        </a>

            <p class="para-2 mb-2">{{singleInfographicsOthersData.ResourceType}} | {{singleInfographicsOthersData.PublishingDate1}}</p>
            <p class="para-1 text-justify mb-1 text-height-4 text-justify" [innerHTML]="singleInfographicsOthersData.Description"></p>
            <a target="_blank" routerLink='/infographics/{{singleInfographicsOthersData.RouteURL}}' class="para-2 para-2-c-2 ">Read More</a>
        </div> 
    </div>
</div>
