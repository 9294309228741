<h5 class="text-uppercase">Other News</h5>
<div class="row d-none d-md-flex" *ngIf="moreNewsLoaded">
    <div class="col-md-6 mb-5" *ngFor="let moreNewsData of moreNewsDatas">
        <!-- <div class="cursor-p" (click)="refreshReq(moreNewsData.ID)" routerLink='/news/featured-news/{{moreNewsData.RouteURL}}/{{moreNewsData.ID}}'> -->
            <a target="_blank" routerLink='/{{moreNewsData.EventDifferentType}}/{{moreNewsData.RouteURL}}'>
            <img class="img-fluid pb-2" [src]="moreNewsData.ImageUrl" alt="news image">
        
            <p class="para-2 mb-0">{{moreNewsData.Tag}}</p>

            <h4 class="text-height-4 card-header-height-1 text-uppercase" [innerHTML]="moreNewsData.Title">
            </h4>
        </a>
            <p class="para-2 mb-2">{{moreNewsData.CompanyName}} | {{moreNewsData.Date}}

                <!-- <span  *ngIf="trendingNewsData.IsSponsored"><a>| </a> <font class="para-2-c-2">Sponsored</font></span>     -->
            </p>
            <p class="para-1 text-justify mb-1 text-height-4 text-justify" [innerHTML]="moreNewsData.Description"></p>
            <a class="para-2 para-2-c-2 " target="_blank"  routerLink='/{{moreNewsData.EventDifferentType}}/{{moreNewsData.RouteURL}}'>Read More</a>
        <!-- </div> -->
    </div>
</div>