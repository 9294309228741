<p class="mb-3 mt-2 para-2-c-2" *ngIf="singleCompanyLoaded">
    <a routerLink="/">Home</a> > <a routerLink="/companies">Companies</a> >
    <span class="para-2-c-1" [innerHTML]=singleCompany.CompanyName></span>
</p>

<div class="row">
    <div class="col-md-9">
        <div *ngIf="singleCompanyLoaded" class="mb-5">
            <h1 [innerHTML]="singleCompany.CompanyName"></h1>
            <div class="row">
                <div class="col-md-4">

                    <img class="img-fluid" src="{{singleCompany.Logo}}" alt="{{singleCompany.CompanyName}}">
                </div>
                <div class="col-md-8">

                    <p class="pt-3 para-6 mb-0 text-justify" [innerHTML]="singleCompany.Description"></p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-3" *ngIf="singleCompanyLoaded">
        <app-single-c-suiteondeck *ngIf="DomainName" [DomainName]="DomainName"></app-single-c-suiteondeck>
        <app-single-events></app-single-events>
    </div>
    <div class="col-md-6">
        <app-single-related-news *ngIf="companyNameNews" [CompanyNameNewsData]="companyNameNews"></app-single-related-news>
    </div>
    <div class="col-md-3">
        <app-single-company-resources *ngIf="companyNameResource" [companyNameResourceData]="companyNameResource"></app-single-company-resources>
    </div>
</div>