import { Component, OnInit,Output,EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpclientService } from '../../../application/httpclient/httpclient.service';
import { environment } from '../../../../../../src/environments/environment';
import { CookieService } from 'ngx-cookie-service';

export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
          // return if another validator has already found an error on the matchingControl
          return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
          matchingControl.setErrors({ mustMatch: true });
      } else {
          matchingControl.setErrors(null);
      }
  }
}

@Component({
  selector: 'advertise-signup',
  templateUrl: './advertise-signup.component.html',
  styleUrls: ['./advertise-signup.component.scss']
})
export class AdvertiseSignupComponent implements OnInit {

  createandSaveUserData:any;
  createUserSignup:any;
  registerForm: FormGroup;
  submitted = false;
  Message : string;
  envSiteName:any;
  private isConsented: boolean=false;
  isShowDivIf:boolean=false;
  name:string="cookieconsent_status";
  
  @Output() cookies:EventEmitter<boolean>=new EventEmitter();
  constructor(private formBuilder: FormBuilder, private httpclientService: HttpclientService,private cookieService : CookieService) {
    this.createregisterForm();
    this.envSiteName=environment.siteName;
   }

  ngOnInit() {
    let acceptcheck = this.cookieService.check('cookieconsent_status');
    //let declinecheck = this.cookieService.check('decline');
   
    if(this.cookieService.check('cookieconsent_status')){
      this.isShowDivIf = !this.isShowDivIf;
      this.cookies.emit(this.isShowDivIf);
    }
    else
    {
      this.isShowDivIf = this.isShowDivIf;
      this.cookies.emit(this.isShowDivIf);
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  //url1:string='https://'+environment.siteName+'/User-Email-Verifier.aspx'
  onSubmit() {
    this.submitted = true;
    //console.log('Your form data registerForm : ', this.registerForm.value );
    // stop here if form is invalid

    if (this.registerForm.invalid) {
        return;
    }     
    if (this.registerForm.valid) {
      let cookieHide = document.getElementById('cookieId');
      if(this.cookieService.check('cookieconsent_status')){
         
          //cookieHide.style.display = "none";
      }
      else{
        cookieHide.style.display = "none";
      }
     }
    this.registerFormDataFunction();
    //window.location.href=this.url1;

         ////////////////////cookies///////////////
         this.isShowDivIf = !this.isShowDivIf;
         const dateNow = new Date().getFullYear()+2;
         this.setCookie(this.name, "accept", 1);

        //  setTimeout(() => {
        //    this.isShowDivIf = !this.isShowDivIf;
        //    window.location.reload();
        //   }, 5000);
  }
 
  setCookie(cName, cValue, expDays) {
    let date = new Date();
   //date.setTime(date.getTime() + (expDays * 60 * 60 * 60 * 1000));
   date.setFullYear(date.getFullYear() + 2);
   const expires = "expires=" + date.toUTCString();
   document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";

   if(new Date()>=date){
    this.isShowDivIf = this.isShowDivIf;
   }
  }

  registerFormDataFunction(): void {

    //this.createUserSignup =  JSON.stringify(this.registerForm.value, null, 4);
 
    if((this.registerForm.value).role=='Advertise')
    {
      window.open('https://media7.com/contact-us','_Self');
      return;
    }
  
    var formData: any = new FormData();

    formData.firstName = this.registerForm.get('firstName').value;     
    formData.lastName=this.registerForm.get('lastName').value;
    formData.email=this.registerForm.get('email').value;
    formData.password=this.registerForm.get('password').value;
    formData.acceptTerms=this.registerForm.get('acceptTerms').value;
    formData.role=this.registerForm.get('role').value;
    formData.siteName=this.envSiteName;
    
    this.createandSaveUserData = false;    
    this.httpclientService.postFormData('https://newapi.nathanark.com/api/AllForms/AdvertiseMainSignUp',  this.getFormUrlEncodedData(formData))
      .subscribe(
        items => {
          this.createandSaveUserData = items;
          //this.createandSaveUserData = true;
          //console.log("Sign Up",this.createandSaveUserData)

          switch(items)
          {
              case 'New User':

              
              break;

              case 'Pubsite User':

              break;

              default :              
              this.Message = items;
              break;
          };
         
        });

  }
 
  createregisterForm(){
    this.registerForm = this.formBuilder.group({        
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(4)]],
      confirmPassword: ['', Validators.required],
      acceptTerms: [false, Validators.requiredTrue],
       role: ['', Validators.required],
      }, {
          validator: MustMatch('password', 'confirmPassword')
    });
  }
  
  getFormUrlEncodedData(toConvert) {
		const formBody = [];
		for (const property in toConvert) {
			const encodedKey = encodeURIComponent(property);
			const encodedValue = encodeURIComponent(toConvert[property]);
			formBody.push(encodedKey + '=' + encodedValue);
		}
		return formBody.join('&');
  }
 
  onReset() {
    this.submitted = false;
     this.registerForm.reset();

  }
 
  

}

