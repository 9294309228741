import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpclientService } from 'src/app/modules/application/httpclient/httpclient.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-guest-other-authers',
  templateUrl: './guest-other-authers.component.html',
  styleUrls: ['./guest-other-authers.component.scss']
})
export class GuestOtherAuthersComponent implements OnInit {
  guestAuthorsDatas: any;
  guestAuthorsloaded: boolean;
  envSiteName:any;
  Flag: boolean;
  @Input() AuthorID: any;
  constructor(private httpclientService: HttpclientService,private route:Router) { 
    this.envSiteName=environment.siteName;
  }

  ngOnInit(): void {
    this.GuestAuthorsData();
  }
  GuestAuthorsData(): void {
    
     //this.href = this.router.url;
     //this.lastString = this.href.substring(this.href.lastIndexOf('/') + 1);
//console.log("dcdcdcdcdcdcdc::::"+ this.AuthorID);
    this.guestAuthorsloaded = false;
    this.Flag = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/SingleGuestAuthorProfile/GetTop3SingleGuestAuthorProfileOtherAuthorsByExcludeId?siteName='+this.envSiteName+'&Id='+ this.AuthorID)
    .subscribe(
      items => {
       
          this.guestAuthorsDatas = items;
          this.guestAuthorsloaded = true;
          //console.log("dcdcdcdcdcdcdcew gwettt3t3t ::::");
          //console.log(this.guestAuthorsDatas[0]);
       
       
      }
    );
  }
  redirect(domain: any,url:any,author_Name: any,id:any){
    this.guestAuthorsDatas =null;
    this.route.navigate(["/"]).then(result=>{window.location.href = 'https://'+domain+url+author_Name+'/'+id});
  }

}
