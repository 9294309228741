import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ResourcesComponent } from './resources.component';
import { ArticlesComponent } from './globel-resources/articles/articles.component';
import { VideoComponent } from './globel-resources/video/video.component';
import { WhitepaperComponent } from './globel-resources/whitepaper/whitepaper.component';
import { InfographicComponent } from './globel-resources/infographic/infographic.component';
import { WebinerComponent } from './globel-resources/webiner/webiner.component';
import { VideoSinglePageComponent } from './globel-resources/video-single-page/video-single-page.component';
import { ArticleSinglePageComponent } from './globel-resources/article-single-page/article-single-page.component';
import { SingleWhitepaperPageComponent } from './single-whitepaper-page/single-whitepaper-page.component';
import { SingleInfographicsPageComponent } from './single-infographics-page/single-infographics-page.component';
import { SingleGuestAuthorsProfileComponent } from './single-guest-authors-profile/single-guest-authors-profile.component';
import { SigleGuestOtherAuthorComponent } from './single-guest-authors-profile/sigle-guest-other-author/sigle-guest-other-author.component';



const routes: Routes = [
  {
    path: '', component: ResourcesComponent, children: [
      {
        path: 'resources',
        loadChildren: () => import('./resources.module')
        .then(mod => mod.ResourcesModule)
      },
      { path: '', component: ArticlesComponent},
      { path: 'articles', component: ArticlesComponent },
      { path: 'videos', component: VideoComponent },
      { path: 'whitepapers', component: WhitepaperComponent },
      { path: 'infographics', component: InfographicComponent },
      { path: 'webinars', component: WebinerComponent },
      // { path: 'globalGuestAuthors', component: GlobalGuestAuthorsComponent},
       { path: 'single-guest-authors-profile/:title/:id', component: SingleGuestAuthorsProfileComponent},
       //{ path: 'sigle-guest-other-author/:title/:id', component: SigleGuestOtherAuthorComponent},
       
      { path:'videos/:title/:id', //videos is dynamic here
        component:VideoSinglePageComponent
      },

      { path:':title/:id', //articles is dynamic here
        component:ArticleSinglePageComponent
      },
      { path:'articles/:title/:id', //articles is dynamic here
        component:ArticleSinglePageComponent
      },
      { path:'whitepapers/:title/:id', component: SingleWhitepaperPageComponent },
      { path:'infographics/:title/:id', component: SingleInfographicsPageComponent },
      { path:'webiner/:title/:id', component: SingleInfographicsPageComponent },

      {
        path: '',
        redirectTo: '/resources',
        pathMatch: 'full'
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ResourcesRoutingModule { }
