<h5>OTHER LIVE WEBINAR</h5>
<div class="row d-none d-md-flex" *ngIf="morewebinarLoaded">
    <div class="col-md-6 mb-5" *ngFor="let moreWebinartData of moreLiveEventDatas">
        <!-- <div class="cursor-p" (click)="refreshReq(moreWebinartData.EventID)" routerLink='/events/live-webinars/{{moreWebinartData.RouteURL}}/{{moreWebinartData.EventID}}'> -->

           
        <a target="_blank" href='https://{{envSiteName}}/{{moreWebinartData.EventDifferentType  | lowercase }}/{{moreWebinartData.RouteURL}}' >
            <img class="img-fluid pb-2" [src]="moreWebinartData.ImageUrl">
            <h4 class="text-height-4 card-header-height-1 text-uppercase" [innerHTML]="moreWebinartData.Name">
            </h4>
        </a>
           <p class="para-2 mb-2">  {{moreWebinartData.DateWithStartTime}} | EST

                <!-- <span  *ngIf="trendingNewsData.IsSponsored"><a>| </a> <font class="para-2-c-2">Sponsored</font></span>     -->
            </p>
            <p class="para-1 text-justify mb-1 text-height-4 text-justify" [innerHTML]="moreWebinartData.Details"></p>

            <a class="para-2 para-2-c-2 " href='https://{{envSiteName}}/{{moreWebinartData.EventDifferentType  | lowercase }}/{{moreWebinartData.RouteURL}}'>Register Now</a> 

            <!-- <a class="para-2 para-2-c-2 " href="{{moreWebinartData.URL }}" target="_blank">Register Now</a> -->
        <!-- </div> -->
    </div>
</div>
<!-- <a class="para-2 para-2-c-2 " href="{{singleEvents.URL }}" target="_blank">Register Now</a> -->