import { Component, OnInit } from '@angular/core';
import { HttpclientService } from 'src/app/modules/application/httpclient/httpclient.service';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { environment } from '../../../../../../src/environments/environment';

const STATE_KEY_ITEMS = makeStateKey('items');

@Component({
  selector: 'app-all-c-suiteondeck',
  templateUrl: './all-c-suiteondeck.component.html',
  styleUrls: ['./all-c-suiteondeck.component.scss']
})
export class AllCSuiteondeckComponent implements OnInit {
  items: any = [];
  dumArray:any=[1,2,3,4,5,6,7,8,9];
  currentIndex = -1;
  title = '';
  page = 1;
  count = 0;
  pageSize = 9;
  firstString: string;
allcSuiteOnDeckDatas: any;
loaded: boolean;
envSiteName:any;
scrollToTop:any;
pos:any;
CSuitMetaTagLoaded: boolean;
CSuitMetaTagData: any;


constructor(private state: TransferState,private titleService: Title,private metaTagService: Meta,private httpclientService: HttpclientService,@Inject(PLATFORM_ID) private platformId: Object,
@Inject(APP_ID) private appId: string,private routeUrl: Router) {
  this.envSiteName=environment.siteName;
 }

  ngOnInit(): void {
    this.allCSuiteOnDeckData();
    this.AllMetaTagCsuit();
  }
  allCSuiteOnDeckData(): void {
    
    this.items = this.state.get(STATE_KEY_ITEMS, <any> []);
    this.loaded = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/GlobalCompanies/GetAllGlobalCompanyCSuiteOnDeck?siteName='+this.envSiteName+'&pageNumber='+this.page)
    .subscribe(
      items => {
        
        const platform = isPlatformBrowser(this.platformId) ?
        'in the browser' : 'on the server';
        this.allcSuiteOnDeckDatas = items;
        this.count=this.allcSuiteOnDeckDatas[0].TotalDataCount;
        this.loaded = true;
        this.state.set(STATE_KEY_ITEMS, <any> items);
        //console.log("all CSuiteOnDeck Datas", this.allcSuiteOnDeckDatas);
        
      }
    );
  }
  resetUsers(): void {
    this.allcSuiteOnDeckDatas = null;
    this.loaded = true;
  }
  // redirect(getUrl:any,getId:any){
  //   this.firstString = getUrl.substring(0,5);
  //   if(this.firstString ==="https"){
  //     window.open(getUrl, "_blank");
  //   }else{
  //     this.routeUrl.navigate(['/companies/all-c-suiteondeck/'+getUrl+"/"+getId]);
  //   }
  // }
  handlePageChange(event): void {
    this.resetUsers();
    this.page = event;
    this.allCSuiteOnDeckData();
    this.onActivate();
  }
  onActivate() {
    this.scrollToTop = window.setInterval(() => {
        this.pos = window.pageYOffset;
        //console.log("pos value",this.pos)
        if (this.pos > 0) {
            window.scrollTo(0, this.pos - 40); // how far to scroll on each step
        } 
        else {
            window.clearInterval(this.scrollToTop);  
        }
    }, 2);
}

AllMetaTagCsuit(): void {
  if(this.CSuitMetaTagLoaded!=true){
  this.CSuitMetaTagLoaded = false;
  this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/Home/GetMetaCode?PubsiteName='+this.envSiteName+'&PageName='+'interviews')
  .subscribe(
    items => {
      this.CSuitMetaTagData = items[0];
      this.CSuitMetaTagLoaded=true;
      //console.log('this.MetaTagData',this.CSuitMetaTagData);


        //seo code for tags
        this.titleService.setTitle(this.CSuitMetaTagData.PageTitle);

        this.metaTagService.updateTag(
        { name: 'og:description', content: this.CSuitMetaTagData.OgDescription}
        );

        this.metaTagService.updateTag(
          { name: 'og:title', content: this.CSuitMetaTagData.PageTitle}
        );

        this.metaTagService.updateTag(
          { name: 'og:image', content: this.CSuitMetaTagData.OgImageURL}
        );

        this.metaTagService.updateTag(
          { name: 'og:image:alt', content: this.CSuitMetaTagData.PageTitle}
        );
        this.metaTagService.updateTag(
          { name: 'twitter:description', content: this.CSuitMetaTagData.OgDescription}
        );
        this.metaTagService.updateTag(
          { name: 'twitter:title', content: this.CSuitMetaTagData.PageTitle}
        );
        this.metaTagService.updateTag(
          { name: 'twitter:image', content: this.CSuitMetaTagData.OgImageURL}
        );
        //seo code for tags end
      });
    }
}
}
