<!-- Modal -->
  <!-- <div class="modal fade news_main_modal algnmnt" id="leadGenerationModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true"
    aria-labelledby="LeadGenerationModalCenterTitle">
    <div class="sign_modal_center1 HellowWorldPopup">
        <div class="modal-dialog modal-dialog-centered sign_modal_center" role="document">
          <div class="modal-content news_modal_content">
            <button type="reset" class="close pop-close" data-dismiss="modal"  aria-label="Close" (click)="onReset()">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
                <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                    <p id="msg" class="text-center pop-para">Fill out the form and get your copy today!</p>
                    <p id="msg" class="text-danger">{{Message}}</p>
                        <div class="row model">
                            <div class="col-lg-12 form-group FNameBox">
                                <input type="text" formControlName="Name" required name="Name"
                                    [ngClass]="{ 'is-invalid': submitted && f.Name.errors }" class="form-control"
                                    id="Name" aria-describedby="emailHelp" placeholder="*Name">

                                <div *ngIf="submitted && f.Name.errors" class="invalid-feedback">
                                    <div *ngIf="f.Name.errors.required">*Required</div>
                                </div>

                            </div>
                            <div class="col-lg-12 form-group LNameBox">
                                <input type="text" formControlName="company" required name="company"
                                    [ngClass]="{ 'is-invalid': submitted && f.company.errors }" class="form-control "
                                    id="company" aria-describedby="emailHelp" placeholder="*Company">

                                <div *ngIf="submitted && f.company.errors" class="invalid-feedback">
                                    <div *ngIf="f.company.errors.required">*Required</div>
                                </div>
                            </div>
                            <div class="col-lg-12 form-group">
                        <input type="email" formControlName="email" required name="email"
                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" class="form-control"
                            id="email" aria-describedby="emailHelp" placeholder="*Email">

                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">*Required</div>
                            <div *ngIf="f.email.errors.email">*Invalid Email </div>
                        </div>

                            </div>
                            <div class="col-lg-12 form-group NewPassBox">
                                <input type="Phone" formControlName="Phone" class="form-control" required name="Phone"
                                    [ngClass]="{ 'is-invalid': submitted && f.Phone.errors }" id="Phone"
                                    aria-describedby="PhoneHelp" placeholder="*Phone">
                                <div *ngIf="submitted && f.Phone.errors" class="invalid-feedback">
                                    <div *ngIf="f.Phone.errors.required">*Required</div>
                                </div>
                            </div>
                            <!-- <div class="col-lg-12 form-group ConfPassBox">

                                <input type="password" formControlName="confirmPassword" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }"
                                    id="confirmPasswordSignup" aria-describedby="emailHelp"
                                    placeholder="*Confirm Password">

                                <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                                    <div *ngIf="f.confirmPassword.errors.required">*Required</div>
                                    <div *ngIf="f.confirmPassword.errors.mustMatch">*Passwords must match</div>
                                </div>

                            </div> -->
                            <!-- <span>Are you an advertiser, agency or publisher?</span>
                            <div class="col-lg-12 form-group">
                              <select formControlName="role" placeholder="I would like to" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.role.errors }">
                                <option value="" [disabled]="true">I would like to</option>
                                <option value="Publisher">Publish</option>
                                <option value="GuestAuthor">Contribute</option>
                                <option value="Advertise">Advertise</option>
                              </select>
                              <div *ngIf="submitted && f.role.errors" class="invalid-feedback">
                                <div *ngIf="f.role.errors.required">*Required</div>
                              </div>
                            </div> -->
                        <!-- </div> -->
                    <!-- <div class="form-check text-center mb-3">
                        <input type="checkbox" formControlName="acceptTerms" id="accepttermsSignup"
                            class="form-check-input" [ngClass]="{ 'is-invalid': submitted && f.acceptTerms.errors }">

                        <label class=" pop-para" for="acceptTerms"> I agree to the 
                            <a target="_blank" routerlink="/terms-and-service" href=""  data-dismiss="modal" aria-label="Close">terms of
                                service</a> and <a target="_blank" routerlink="/privacy-policy" href="" data-dismiss="modal" aria-label="Close">privacy policy</a>.
                            </label>
                        <div *ngIf="submitted && f.acceptTerms.errors" class="invalid-feedback text-left">Required</div>
                    </div> -->
                    <!-- <div class="form-check text-center mb-3">
                        <input type="checkbox" class="form-check-input" id="accepttermsSignup" formControlName="acceptTerms" [ngClass]="{ 'is-invalid': submitted && f.acceptTerms.errors }">
                        <label class=" pop-para" for="acceptTerms"> I agree to the <a href="" data-dismiss="modal" aria-label="Close" routerLink='/terms-and-service'>terms of service</a> and <a href="" data-dismiss="modal" aria-label="Close"
                            routerLink='/privacy-policy'>privacy policy</a>.</label>

                        <div *ngIf="submitted && f.acceptTerms.errors" class="invalid-feedback text-left">*Required</div>

                    </div> -->
                    <!-- <button class="text-uppercase thm-btn btn-theme-success w-100 pt-3 pb-3"> Submit</button>
                </form>
            </div>
        </div>
      </div>
     </div>
   </div> --> 
<!-- Modal -->

<div class="modal fade news_main_modal" id="leadGenerationModalCenter" tabindex="-1" role="dialog" aria-labelledby="leadGenerationModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered news_modal_center" role="document">
        <div class="modal-content news_modal_content">
            <button type="button" class="close pop-close" data-dismiss="modal" #closebutton aria-label="Close" (click)="onReset()">
                <span aria-hidden="true">&times;</span>
              </button>
            <div class="modal-body">
                <form [formGroup]="advertiseregisterForm" (ngSubmit)="onSubmit()">
                    <p id="msg" class="text-center pop-para">Fill out the form and get your copy today!</p>
                    <!-- <p id="msg" class="text-danger">{{Message}}</p> -->
                        <div class="row model">
                            <div class="col-lg-12 form-group FNameBox">
                                <input type="text" formControlName="Name" required name="Name"
                                    [ngClass]="{ 'is-invalid': submitted && f.Name.errors }" class="form-control"
                                    id="Name" aria-describedby="emailHelp" placeholder="*Name">

                                <div *ngIf="submitted && f.Name.errors" class="invalid-feedback">
                                    <div *ngIf="f.Name.errors.required">*Required</div>
                                </div>

                            </div>
                            <div class="col-lg-12 form-group LNameBox">
                                <input type="text" formControlName="company" required name="company"
                                    [ngClass]="{ 'is-invalid': submitted && f.company.errors }" class="form-control "
                                    id="company" aria-describedby="emailHelp" placeholder="*Company">

                                <div *ngIf="submitted && f.company.errors" class="invalid-feedback">
                                    <div *ngIf="f.company.errors.required">*Required</div>
                                </div>
                            </div>
                            <div class="col-lg-12 form-group">
                        <input type="email" formControlName="email" required name="email"
                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" class="form-control"
                            id="email" aria-describedby="emailHelp" placeholder="*Email">

                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">*Required</div>
                            <div *ngIf="f.email.errors.email">*Invalid Email </div>
                        </div>

                            </div>
                            <div class="col-lg-12 form-group NewPassBox">
                                <input type="Phone" formControlName="Phone" class="form-control" required name="Phone"
                                    [ngClass]="{ 'is-invalid': submitted && f.Phone.errors }" id="Phone"
                                    aria-describedby="PhoneHelp" placeholder="*Phone">
                                <div *ngIf="submitted && f.Phone.errors" class="invalid-feedback">
                                    <div *ngIf="f.Phone.errors.required">*Required</div>
                                </div>
                            </div>
                            <!-- <div class="col-lg-12 form-group ConfPassBox">

                                <input type="password" formControlName="confirmPassword" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }"
                                    id="confirmPasswordSignup" aria-describedby="emailHelp"
                                    placeholder="*Confirm Password">

                                <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                                    <div *ngIf="f.confirmPassword.errors.required">*Required</div>
                                    <div *ngIf="f.confirmPassword.errors.mustMatch">*Passwords must match</div>
                                </div>

                            </div> -->
                            <!-- <span>Are you an advertiser, agency or publisher?</span>
                            <div class="col-lg-12 form-group">
                              <select formControlName="role" placeholder="I would like to" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.role.errors }">
                                <option value="" [disabled]="true">I would like to</option>
                                <option value="Publisher">Publish</option>
                                <option value="GuestAuthor">Contribute</option>
                                <option value="Advertise">Advertise</option>
                              </select>
                              <div *ngIf="submitted && f.role.errors" class="invalid-feedback">
                                <div *ngIf="f.role.errors.required">*Required</div>
                              </div>
                            </div> -->
                        </div>
                    <!-- <div class="form-check text-center mb-3">
                        <input type="checkbox" formControlName="acceptTerms" id="accepttermsSignup"
                            class="form-check-input" [ngClass]="{ 'is-invalid': submitted && f.acceptTerms.errors }">

                        <label class=" pop-para" for="acceptTerms"> I agree to the 
                            <a target="_blank" routerlink="/terms-and-service" href=""  data-dismiss="modal" aria-label="Close">terms of
                                service</a> and <a target="_blank" routerlink="/privacy-policy" href="" data-dismiss="modal" aria-label="Close">privacy policy</a>.
                            </label>
                        <div *ngIf="submitted && f.acceptTerms.errors" class="invalid-feedback text-left">Required</div>
                    </div> -->
                    <!-- <div class="form-check text-center mb-3">
                        <input type="checkbox" class="form-check-input" id="accepttermsSignup" formControlName="acceptTerms" [ngClass]="{ 'is-invalid': submitted && f.acceptTerms.errors }">
                        <label class=" pop-para" for="acceptTerms"> I agree to the <a href="" data-dismiss="modal" aria-label="Close" routerLink='/terms-and-service'>terms of service</a> and <a href="" data-dismiss="modal" aria-label="Close"
                            routerLink='/privacy-policy'>privacy policy</a>.</label>

                        <div *ngIf="submitted && f.acceptTerms.errors" class="invalid-feedback text-left">*Required</div>

                    </div> -->
                    <button class="text-uppercase thm-btn btn-theme-success w-100 pt-3 pb-3"> Submit</button>
                </form>
            </div>
        </div>
    </div>
</div>