import { ChangeDetectorRef, Component, KeyValueDiffer, KeyValueDiffers, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { HttpclientService } from '../../../application/httpclient/httpclient.service';
import { environment } from '../../../../../../src/environments/environment';
import { SharedDataService } from 'src/app/modules/application/shared-data/shared-data.service';

@Component({
  selector: 'app-all-conferences',
  templateUrl: './all-conferences.component.html',
  styleUrls: ['./all-conferences.component.scss']
})
export class AllConferencesComponent implements OnInit {
  href: any;
  lastString: string;
  MetaTagLoadedPast: boolean;
  MetaTagDataPast: any;
  envSiteName: any;
  currentPageName: string;
  private differ: KeyValueDiffer<any, any>;

  constructor(private router: Router, private titleService: Title, private metaTagService: Meta, private httpclientService: HttpclientService,
    public sharedDataService: SharedDataService,
    private keyValueDiffers: KeyValueDiffers,
    private changeDetector: ChangeDetectorRef
  ) {
    this.envSiteName = environment.siteName;
  }

  ngOnInit(): void {
    this.href = this.router.url;
    this.lastString = this.href.substring(this.href.lastIndexOf('/') + 1);
    this.differ = this.keyValueDiffers.find(this.sharedDataService).create();
    //this.AllMetaTag();
  }

  ngDoCheck() {
    if (this.differ.diff(this.sharedDataService) != null) {
      this.DoClick();
    }
  }

  ngAfterViewInit(): void {
    this.DoClick();
  }

  DoClick(): void {
    if ((!this.sharedDataService.upComingConferencesFound) && this.sharedDataService.pastConferencesFound) {
      this.pastConferencesLink?.nativeElement.click();
    }
    if (this.sharedDataService.upComingConferencesFound && (!this.sharedDataService.pastConferencesFound)) {
      this.upcomingConferencesLink?.nativeElement.click();
    }
  }

  @ViewChild('PastConferences') pastConferencesLink: ElementRef;
  @ViewChild('UpcomingConferences') upcomingConferencesLink: ElementRef;

  AllMetaTag(): void {
    if (this.lastString == "past-conferences") {
      //this.currentPageName='PastEvents';
      this.currentPageName = 'PastConferences';
    } else if (this.lastString == "upcoming-conferences") {
      this.currentPageName = 'UpcomingConferences';
    }

    if (this.currentPageName) {
      this.MetaTagLoadedPast = false;
      //this.httpclientService.getHttpclient('https://api.nathanark.com/api/Home/GetMetaCode?PubsiteName='+this.envSiteName+'&PageName='+this.currentPageName)
      this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/Home/GetMetaCode?PubsiteName=' + this.envSiteName + '&PageName=' + this.currentPageName)
        .subscribe(
          items => {
            this.MetaTagDataPast = items[0];
            this.MetaTagLoadedPast = true;
            //console.log('this.MetaTagDataPast',this.MetaTagDataPast);


            //seo code for tags
            this.titleService.setTitle(this.MetaTagDataPast.PageTitle);

            this.metaTagService.updateTag(
              { name: 'og:description', content: this.MetaTagDataPast.OgDescription }
            );

            this.metaTagService.updateTag(
              { name: 'og:title', content: this.MetaTagDataPast.PageTitle }
            );

            this.metaTagService.updateTag(
              { name: 'og:image', content: this.MetaTagDataPast.OgImageURL }
            );

            this.metaTagService.updateTag(
              { name: 'og:image:alt', content: this.MetaTagDataPast.PageTitle }
            );
            this.metaTagService.updateTag(
              { name: 'twitter:description', content: this.MetaTagDataPast.OgDescription }
            );
            this.metaTagService.updateTag(
              { name: 'twitter:title', content: this.MetaTagDataPast.PageTitle }
            );
            this.metaTagService.updateTag(
              { name: 'twitter:image', content: this.MetaTagDataPast.OgImageURL }
            );
            //seo code for tags end
          });
    }
  }

}
