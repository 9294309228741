import { Component, OnInit } from '@angular/core';
import { HttpclientService } from 'src/app/modules/application/httpclient/httpclient.service';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { environment } from '../../../../../../../src/environments/environment';
import { Title, Meta } from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';
import { CanonicalService } from 'src/app/canonical.service';

const STATE_KEY_ITEMS = makeStateKey('items');

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss']
})
export class ArticlesComponent implements OnInit {
  items: any = [];
  dumArray:any=[1,2,3,4,5,6,7,8,9];

  tutorials: any;
  currentTutorial = null;
  currentIndex = -1;
  title = '';

  page = 1;
  count = 0;
  pageSize = 9;

resourcesArticleDatas: any;
resourceArticleLoaded: boolean;
envSiteName:any;
  scrollToTop: number;
  pos: number;
  MetaTagLoadedArticle: boolean;
  MetaTagDataArticle: any;
  HomeADAfterWhitepaper:any;
  url1:string='https://'+environment.siteName+'/HomeADAfterWhitepaper.html'

  constructor(private state: TransferState,private titleService: Title,private metaTagService: Meta,private httpclientService: HttpclientService,private canonical:CanonicalService,@Inject(PLATFORM_ID) private platformId: Object, private sanitizer:DomSanitizer,
  @Inject(APP_ID) private appId: string) {
    this.envSiteName=environment.siteName;
   }
  

  ngOnInit(): void {
   this.resourcesArticleData();
   //this.AllMetaTagArticle();
   this.HomeADAfterWhitepaper=this.sanitizer.bypassSecurityTrustResourceUrl(this.url1);
  }
  
  resourcesArticleData(): void
  {
    this.items = this.state.get(STATE_KEY_ITEMS, <any> []);
    this.resourceArticleLoaded = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/GlobalReources/GetAllGlobalReourcesArticle?siteName='+this.envSiteName+'&pageNumber='+this.page)
    .subscribe(
      items => {
        const platform = isPlatformBrowser(this.platformId) ?
            'in the browser' : 'on the server';
        this.resourcesArticleDatas = items;
        this.count = this.resourcesArticleDatas[0].TotalDataCount;
        this.resourceArticleLoaded = true;
        this.state.set(STATE_KEY_ITEMS, <any> items);
        //console.log("ResourcesArticle Datas", this.resourcesArticleDatas);
        window.scrollTo(0,0);
      });
  }

  resetUsers(): void {
    this.resourcesArticleDatas = null;
    this.resourceArticleLoaded = true;
  }

  handlePageChange(event): void {
    this.resetUsers();
    this.page = event;
    this.resourcesArticleData();
    this.onActivate();
  }

  onActivate() {
    this.scrollToTop = window.setInterval(() => {
        this.pos = window.pageYOffset;
        //console.log("pos value",this.pos)
        if (this.pos > 0) {
            window.scrollTo(0, this.pos - 40); // how far to scroll on each step
        } 
        else {
            window.clearInterval(this.scrollToTop);  
        }
    }, 2);
}
AllMetaTagArticle(): void {
  if(this.MetaTagLoadedArticle!=true){
  this.MetaTagLoadedArticle = false;
  this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/Home/GetMetaCode?PubsiteName='+this.envSiteName+'&PageName='+'Articles')
  .subscribe(
    items => {
      this.MetaTagDataArticle = items[0];
      this.MetaTagLoadedArticle=true;
      //console.log('this.MetaTagDataArticle',this.MetaTagDataArticle);


        //seo code for tags
        this.canonical.CreateCanonicalLink();

        this.titleService.setTitle(this.MetaTagDataArticle.PageTitle);

        this.metaTagService.updateTag(
          {name:'robots',content:'index,follow'}
        );

        this.metaTagService.updateTag(
        { name: 'og:description', content: this.MetaTagDataArticle.OgDescription}
        );

        this.metaTagService.updateTag(
          { name: 'og:title', content: this.MetaTagDataArticle.PageTitle}
        );

        this.metaTagService.updateTag(
          { name: 'og:keywords', content: this.MetaTagDataArticle.OgKeyWords}
        );

        this.metaTagService.updateTag(
          { name: 'og:image', content: this.MetaTagDataArticle.OgImageURL}
        );

        this.metaTagService.updateTag(
          { name: 'og:image:alt', content: this.MetaTagDataArticle.PageTitle}
        );

        this.metaTagService.updateTag(
          { name: 'description', content: this.MetaTagDataArticle.OgDescription}
          );
  
          this.metaTagService.updateTag(
            { name: 'title', content: this.MetaTagDataArticle.PageTitle}
          );
  
          this.metaTagService.updateTag(
            { name: 'keywords', content: this.MetaTagDataArticle.OgKeyWords}
          );
  
          this.metaTagService.updateTag(
            { name: 'image', content: this.MetaTagDataArticle.OgImageURL}
          );
  
          this.metaTagService.updateTag(
            { name: 'image:alt', content: this.MetaTagDataArticle.PageTitle}
          );

        this.metaTagService.updateTag(
          { name: 'twitter:description', content: this.MetaTagDataArticle.OgDescription}
        );
        this.metaTagService.updateTag(
          { name: 'twitter:title', content: this.MetaTagDataArticle.PageTitle}
        );
        this.metaTagService.updateTag(
          { name: 'twitter:keywords', content: this.MetaTagDataArticle.OgKeyWords}
        );
        this.metaTagService.updateTag(
          { name: 'twitter:image', content: this.MetaTagDataArticle.OgImageURL}
        );
        //seo code for tags end
      });
    }
}

}
