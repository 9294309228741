import { Component, OnInit } from '@angular/core';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { HttpclientService } from 'src/app/modules/application/httpclient/httpclient.service';
import { Router } from '@angular/router';
import { environment } from '../../../../../../../src/environments/environment';
import { Title, Meta } from '@angular/platform-browser';
import { CanonicalService } from 'src/app/canonical.service';

const STATE_KEY_ITEMS = makeStateKey('items');

@Component({
  selector: 'app-whitepaper',
  templateUrl: './whitepaper.component.html',
  styleUrls: ['./whitepaper.component.scss']
})
export class WhitepaperComponent implements OnInit {
  items: any = [];
  dumArray:any=[1,2,3,4,5,6,7,8,9];

  tutorials: any;
  currentTutorial = null;
  currentIndex = -1;
  title = '';
  firstString:string;
  page = 1;
  count = 0;
  pageSize = 9;
  resourceswhitepaperDatas: any;
  resourcewhitepaperLoaded: boolean;
  envSiteName:any;
  scrollToTop: number;
  pos: number;
  MetaTagLoadedWhitePaper: boolean;
  MetaTagDataWhitePaper: any;

  constructor(private state: TransferState,private titleService: Title,private metaTagService: Meta,private httpclientService: HttpclientService,private canonical:CanonicalService,@Inject(PLATFORM_ID) private platformId: Object,
  @Inject(APP_ID) private appId: string,private routeUrl: Router) {
    this.envSiteName=environment.siteName;
   }

  ngOnInit(): void {
    this.resourceswhitepaperData();
    //this.AllMetaTagWhitePaper();
  }
  resourceswhitepaperData(): void
  {
    this.items = this.state.get(STATE_KEY_ITEMS, <any> []);
    this.resourcewhitepaperLoaded = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/GlobalReources/GetAllGlobalReourcesWhitePapers?siteName='+this.envSiteName+'&pageNumber='+this.page)
    .subscribe(
      items => {
        const platform = isPlatformBrowser(this.platformId) ?
            'in the browser' : 'on the server';
        this.resourceswhitepaperDatas = items;
        this.count=this.resourceswhitepaperDatas[0].TotalDataCount;
        this.resourcewhitepaperLoaded = true;
        this.state.set(STATE_KEY_ITEMS, <any> items);
        //console.log("resources whitepaper Datas", this.resourceswhitepaperDatas);
        window.scrollTo(0,0);
      }
    );
  }
  redirect(getUrl:any,getId:any){
    this.firstString = getUrl.substring(0,5);
    if(this.firstString ==="https"){
      window.open(getUrl, "_blank");
    }else{
      this.routeUrl.navigate(['/resources/whitepapers/'+getUrl+"/"+getId]);
    }
  }

  resetUsers(): void {
    this.resourceswhitepaperDatas = null;
    this.resourcewhitepaperLoaded = true;
  }
  handlePageChange(event): void {
    this.resetUsers();
    this.page = event;
    this.resourceswhitepaperData();
    this.onActivate();
  }
  onActivate() {
    this.scrollToTop = window.setInterval(() => {
        this.pos = window.pageYOffset;
        //console.log("pos value",this.pos)
        if (this.pos > 0) {
            window.scrollTo(0, this.pos - 40); // how far to scroll on each step
        } 
        else {
            window.clearInterval(this.scrollToTop);  
        }
    }, 2);
}
AllMetaTagWhitePaper(): void {
  if(this.MetaTagLoadedWhitePaper!=true){
  this.MetaTagLoadedWhitePaper = false;
  this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/Home/GetMetaCode?PubsiteName='+this.envSiteName+'&PageName='+'Whitepapers')
  .subscribe(
    items => {
      this.MetaTagDataWhitePaper = items[0];
      this.MetaTagLoadedWhitePaper=true;
      //console.log('this.MetaTagDataWhitePaper',this.MetaTagDataWhitePaper);


        //seo code for tags
        this.canonical.CreateCanonicalLink();

        this.titleService.setTitle(this.MetaTagDataWhitePaper.PageTitle);

        this.metaTagService.updateTag(
          {name:'robots',content:'index,follow'}
        );

        this.metaTagService.updateTag(
        { name: 'og:description', content: this.MetaTagDataWhitePaper.OgDescription}
        );

        this.metaTagService.updateTag(
          { name: 'og:title', content: this.MetaTagDataWhitePaper.PageTitle}
        );

        this.metaTagService.updateTag(
          { name: 'og:keywords', content: this.MetaTagDataWhitePaper.OgKeyWords}
        );

        this.metaTagService.updateTag(
          { name: 'og:image', content: this.MetaTagDataWhitePaper.OgImageURL}
        );

        this.metaTagService.updateTag(
          { name: 'og:image:alt', content: this.MetaTagDataWhitePaper.PageTitle}
        );

        this.metaTagService.updateTag(
          { name: 'description', content: this.MetaTagDataWhitePaper.OgDescription}
          );
  
          this.metaTagService.updateTag(
            { name: 'title', content: this.MetaTagDataWhitePaper.PageTitle}
          );
  
          this.metaTagService.updateTag(
            { name: 'keywords', content: this.MetaTagDataWhitePaper.OgKeyWords}
          );
  
          this.metaTagService.updateTag(
            { name: 'image', content: this.MetaTagDataWhitePaper.OgImageURL}
          );
  
          this.metaTagService.updateTag(
            { name: 'image:alt', content: this.MetaTagDataWhitePaper.PageTitle}
          );

        this.metaTagService.updateTag(
          { name: 'twitter:description', content: this.MetaTagDataWhitePaper.OgDescription}
        );
        this.metaTagService.updateTag(
          { name: 'twitter:title', content: this.MetaTagDataWhitePaper.PageTitle}
        );
        this.metaTagService.updateTag(
          { name: 'twitter:keywords', content: this.MetaTagDataWhitePaper.OgKeyWords}
        );
        this.metaTagService.updateTag(
          { name: 'twitter:image', content: this.MetaTagDataWhitePaper.OgImageURL}
        );
        //seo code for tags end
      });
    }
}
}
