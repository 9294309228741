import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { KeyValueDiffer, KeyValueDiffers, ChangeDetectorRef } from '@angular/core';
import { HttpclientService } from '../../../application/httpclient/httpclient.service';
import { environment } from '../../../../../../src/environments/environment';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { CanonicalService } from 'src/app/canonical.service';
import { SharedDataService } from '../../../application/shared-data/shared-data.service'

@Component({
  selector: 'app-all-events',
  templateUrl: './all-events.component.html',
  styleUrls: ['./all-events.component.scss']
})
export class AllEventsComponent implements OnInit, AfterViewInit {

  EventNewsDatas: any;
  EventLoaded: boolean;
  WebinarDatas: any;
  WebinarLoaded: boolean;
  ConferenceDatas: any;
  ConferenceLoaded: boolean;
  OnDemandWebinarDatas: any;
  OnDemandWebinarLoaded: boolean;
  pastEventsDatas: any;
  pastEventsLoaded: boolean;
  envSiteName: any;
  firstString: string;
  EventMetaTagData: any;
  EventMetaTagLoaded: boolean;
  private differ: KeyValueDiffer<any, any>;
  constructor(private httpclientService: HttpclientService, private routeUrl: Router, private titleService: Title, private metaTagService: Meta, private canonical: CanonicalService, public sharedDataService: SharedDataService,
    private keyValueDiffers: KeyValueDiffers,
    private changeDetector: ChangeDetectorRef) {

    this.envSiteName = environment.siteName;
  }

  ngOnInit(): void {
    this.AllEvents();
    this.AllLiveWebinars();
    this.AllUpComingConferences();
    this.AllOnDemandWebinars();
    //this.AllMetaTagEvent();
    this.differ = this.keyValueDiffers.find(this.sharedDataService).create();
  }

  @ViewChild('OnDemandWebinar') onDemandWebinarLink: ElementRef;
  @ViewChild('PastConferences') pastConferencesLink: ElementRef;

  ngAfterViewInit(): void {
    this.DoClick();
  }

  DoClick() : void {
    if ((!this.sharedDataService.liveWebinarFound) && this.sharedDataService.onDemandWebinarFound) {
      this.onDemandWebinarLink?.nativeElement.click();
    }
    if ((!this.sharedDataService.upComingConferencesFound) && this.sharedDataService.pastConferencesFound) {
      this.pastConferencesLink?.nativeElement.click();
    }
  }

  ngDoCheck() {
    if (this.differ.diff(this.sharedDataService) != null) {
      this.DoClick();
    }
  }
  AllUpComingConferences(): void {
    if (this.ConferenceLoaded != true) {
      this.ConferenceLoaded = false;
      this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/GlobalEvents/GetGlobalEventTop3UpCommingConferences?siteName=' + this.envSiteName)
        .subscribe(
          items => {
            this.ConferenceDatas = items;
            this.ConferenceLoaded = true;
            console.log("Conference AllUpComingConferences", this.ConferenceDatas)
            window.scrollTo(0, 0);
          });
    }
  }

  AllEvents(): void {
    if (this.EventLoaded != true) {
      this.EventLoaded = false;
      this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/GlobalEvents/GetGlobalEventTop3PastConferences?siteName=' + this.envSiteName)
        .subscribe(
          items => {
            this.EventNewsDatas = items;
            this.EventLoaded = true;
            // console.log("events Past Conference AllEvents",this.EventNewsDatas)
            window.scrollTo(0, 0);
          });
    }
  }

  AllLiveWebinars(): void {
    if (this.WebinarLoaded != true) {
      this.WebinarLoaded = false;
      this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/GlobalEvents/GetGlobalEventTop3LiveWebinars?siteName=' + this.envSiteName)
        .subscribe(
          items => {
            this.WebinarDatas = items;
            this.WebinarLoaded = true;
            //console.log("webinar AllWebinars",this.WebinarDatas)
            window.scrollTo(0, 0);
          });
    }
  }



  AllOnDemandWebinars(): void {
    if (this.OnDemandWebinarLoaded != true) {
      this.OnDemandWebinarLoaded = false;
      this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/GlobalEvents/GetGlobalEventTop3OnDemandWebinars?siteName=' + this.envSiteName)
        .subscribe(
          items => {
            this.OnDemandWebinarDatas = items;
            this.OnDemandWebinarLoaded = true;
            //console.log("OnDemandWebinar AllOnDemandWebinarDatas",this.OnDemandWebinarDatas)
            window.scrollTo(0, 0);
          });
    }
  }
  redirect(getUrl: any, getId: any) {
    this.firstString = getUrl.substring(0, 5);
    if (this.firstString === "https") {
      window.open(getUrl, "_blank");
    } else {
      this.routeUrl.navigate(['/events/on-demand-webinars/' + getUrl + "/" + getId]);
    }
  }

  AllMetaTagEvent(): void {
    if (this.EventMetaTagLoaded != true) {
      this.EventMetaTagLoaded = false;
      this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/Home/GetMetaCode?PubsiteName=' + this.envSiteName + '&PageName=' + 'Events')
        .subscribe(
          items => {
            this.EventMetaTagData = items[0];
            this.EventMetaTagLoaded = true;
            //console.log('this.EventMetaTagData',this.EventMetaTagData);


            //seo code for tags
            this.canonical.CreateCanonicalLink();

            this.titleService.setTitle(this.EventMetaTagData.PageTitle);

            this.metaTagService.updateTag(
              { name: 'robots', content: 'index,follow' }
            );

            this.metaTagService.updateTag(
              { name: 'og:description', content: this.EventMetaTagData.OgDescription }
            );

            this.metaTagService.updateTag(
              { name: 'og:title', content: this.EventMetaTagData.PageTitle }
            );

            this.metaTagService.updateTag(
              { name: 'og:keywords', content: this.EventMetaTagData.OgKeyWords }
            );

            this.metaTagService.updateTag(
              { name: 'og:image', content: this.EventMetaTagData.OgImageURL }
            );

            this.metaTagService.updateTag(
              { name: 'og:image:alt', content: this.EventMetaTagData.PageTitle }
            );

            this.metaTagService.updateTag(
              { name: 'description', content: this.EventMetaTagData.OgDescription }
            );

            this.metaTagService.updateTag(
              { name: 'title', content: this.EventMetaTagData.PageTitle }
            );

            this.metaTagService.updateTag(
              { name: 'keywords', content: this.EventMetaTagData.OgKeyWords }
            );

            this.metaTagService.updateTag(
              { name: 'image', content: this.EventMetaTagData.OgImageURL }
            );

            this.metaTagService.updateTag(
              { name: 'image:alt', content: this.EventMetaTagData.PageTitle }
            );

            this.metaTagService.updateTag(
              { name: 'twitter:description', content: this.EventMetaTagData.OgDescription }
            );
            this.metaTagService.updateTag(
              { name: 'twitter:title', content: this.EventMetaTagData.PageTitle }
            );
            this.metaTagService.updateTag(
              { name: 'twitter:keywords', content: this.EventMetaTagData.OgKeyWords }
            );
            this.metaTagService.updateTag(
              { name: 'twitter:image', content: this.EventMetaTagData.OgImageURL }
            );
            //seo code for tags end
          });
    }
  }
}
