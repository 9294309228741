import { Directive, ElementRef, HostListener } from '@angular/core';
import { Router } from "@angular/router";
import { CookieService } from 'ngx-cookie-service';
import { HttpclientService } from '../../application/httpclient/httpclient.service';
import { environment } from '../../../../environments/environment'
import { Guid } from 'guid-typescript';

@Directive({
  selector: '[click-tracker]'
})
export class ClickTrackerDirective {
  private envSiteName: string;
  public id: Guid;
  public apiUrl: string = 'https://newapi.nathanark.com/api';
  constructor(private cookieService: CookieService, private httpclientService: HttpclientService) {
    this.envSiteName = environment.siteName;
  }

  @HostListener('click', ['$event'])
  onClick($event: any) {
    let htmlTag = $event.target;
    let url = window.location.href;
    let details = this.GetClickedDetails(htmlTag);
    if (!details) {
      let htmlAnchorTag = htmlTag.closest('A');
      details = this.GetClickedDetails(htmlAnchorTag);
    }
    if (!details) {
      let htmlButtonTag = htmlTag.closest('BUTTON');
      details = this.GetClickedDetails(htmlButtonTag);
    }

    if (!details)
      return;

    let rawData = {
      "userID": this.getUserTrackingCookie(),
      "siteName": this.envSiteName,
      "url": url,
      "Details": details
    }
    this.sendRequest('/session/NavigationBegin', rawData);
  }

  GetClickedDetails(htmlTag): string {

    let tagName = htmlTag?.tagName;
    let details = undefined;
    switch (tagName) {
      case 'INPUT':
        details = htmlTag.id;
        break;
      case 'BUTTON':
        details = htmlTag.textContent;
        if (htmlTag.id) {
          details = details + '; id : ' + htmlTag.id;
        }
        break;
      case 'A':
        details = htmlTag.innerText + '; href :' + htmlTag.href;
        break;
    }

    return details;
  }

  sendRequest(url: string, rawData: any) {
    url = this.apiUrl + url;
    this.httpclientService.postHttpclient(url, window.JSON.stringify(rawData)).subscribe(e => {
    })
  }

  getUserTrackingCookie(): string {
    const userTrackingCookieName: string = "usertracking_userid";
    let cookieValue = this.cookieService.get(userTrackingCookieName);
    if (!cookieValue) {
      cookieValue = Guid.create().toString();
      this.cookieService.set(userTrackingCookieName, cookieValue, 365)
    }

    return cookieValue
  }
}
