import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { EventsComponent } from './events.component';
import { AllConferencesComponent } from './all-conferences/all-conferences.component';
import { AllEventsComponent } from './all-events/all-events.component';
import { AllWebinersComponent } from './all-webiners/all-webiners.component';
import { EventsSinglePageComponent } from './events-single-page/events-single-page.component';
import { WebinarSinglePageComponent } from './webinar-single-page/webinar-single-page.component';
import { OnDemandwebinarSinglePageComponent } from './on-demandwebinar-single-page/on-demandwebinar-single-page.component';
import { PastConferenceSinglePageComponent } from './past-conference-single-page/past-conference-single-page.component';



const routes: Routes = [
  { path: '', component: EventsComponent, children: [
    {
      path: 'events',
      loadChildren: () => import('./events.module')
      .then(mod => mod.EventsModule)
    },
    {path: '', component: AllEventsComponent},
    { path: 'upcoming-conferences', component: AllConferencesComponent },
    { path: 'past-conferences', component: AllConferencesComponent },
    { path: 'live-webinars', component: AllWebinersComponent },
    { path: 'on-demand-webinars', component: AllWebinersComponent },
    { path: 'upcoming-conferences/:title/:id', component: EventsSinglePageComponent },
    { path: 'past-conferences/:title/:id', component: PastConferenceSinglePageComponent },
    { path: 'live-webinars/:title/:id', component: WebinarSinglePageComponent },
    { path: 'on-demand-webinars/:title/:id', component: OnDemandwebinarSinglePageComponent },
    {
      path: '',
      redirectTo: '/events',
      pathMatch: 'full'
    }
  ]
},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EventsRoutingModule { }
