import { Component, OnInit } from '@angular/core';
import { HttpclientService } from '../../../application/httpclient/httpclient.service';
import { environment } from '../../../../../../src/environments/environment';

@Component({
  selector: 'app-spotlight-events',
  templateUrl: './spotlight-events.component.html',
  styleUrls: ['./spotlight-events.component.scss']
})
export class SpotlightEventsComponent implements OnInit {
  TopSpotlightDatas:any;
  TopSpotlightLoaded:boolean;
  envSiteName:any;

  constructor(private httpclientService: HttpclientService) { 
    this.envSiteName=environment.siteName;
  }

  ngOnInit(): void {
    this.TopSpotlights();
  }

  

  TopSpotlights(): void {
    if(this.TopSpotlightLoaded!=true){
    this.TopSpotlightLoaded = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/GlobalReources/GetTop1GlobalReourcesSpotLight?siteName='+this.envSiteName)
      .subscribe(
        items => {
          this.TopSpotlightDatas = items;
          this.TopSpotlightLoaded = true;
          //console.log("TopSpotlightDatas",this.TopSpotlightDatas)
        });
      }
  }

}
