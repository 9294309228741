import { Component, OnInit } from '@angular/core';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { HttpclientService } from 'src/app/modules/application/httpclient/httpclient.service';
import { environment } from '../../../../../environments/environment';
import { Title, Meta } from '@angular/platform-browser';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CanonicalService } from 'src/app/canonical.service';
const STATE_KEY_ITEMS = makeStateKey('items');

@Component({
  selector: 'advertise',
  templateUrl: './advertise.component.html',
  styleUrls: ['./advertise.component.scss']
})
export class AdvertiseComponent implements OnInit {

  items: any = [];
  industry_outreach: any;
  href:string;
  firstString: string;
  envSiteName:any;
  siteName:any;
  scrollToTop: number;
  pos: number;
  CompaniesMetaTagLoaded: boolean;
  CompaniesMetaTagData: any;

  constructor(private state: TransferState,private titleService: Title,private metaTagService: Meta,private httpclientService: HttpclientService,private canonical:CanonicalService,@Inject(PLATFORM_ID) private platformId: Object,private sanitizer:DomSanitizer,
  @Inject(APP_ID) private appId: string,public router:Router) { 
    this.envSiteName=environment.siteName;
  }

  ngOnInit(): void {
    this.siteName=this.envSiteName.replace("."," ");
    switch(this.envSiteName)
    {
        case 'abm.report':
          this.industry_outreach="Digital Advertising for Marketing, Advertising and Public Relations";
          break;
        case 'advertising.report':
          this.industry_outreach="Digital Advertising for Marketing, Advertising and Public Relations";
          break;
        case 'aviation.report':
          this.industry_outreach="Digital Advertising for Transportation and Logistics";
          break;
        case 'biotechnology.report':
          this.industry_outreach="Digital Advertising for Healthcare and Pharmaceuticals";
          break;
        case 'biotech.report':
          this.industry_outreach="Digital Advertising for Healthcare and Pharmaceuticals";
          break;
        case 'capital.report':
          this.industry_outreach="Digital Advertising for Banking, Accounting and Financial Services";
          break;
        case 'channel.report':
          this.industry_outreach="Digital Advertising for Marketing, Advertising and Public Relations";
          break;
        case 'chemical.report':
          this.industry_outreach="Digital Advertising for Chemicals and Plastics";
          break;
        case 'cloud.report':
          this.industry_outreach="Digital Advertising for IT and Technology";
          break;
        case 'dataanalytics.report':
          this.industry_outreach="Digital Advertising for IT and Technology";
          break;
        case 'education.report':
          this.industry_outreach="Digital Advertising for Education, Training and Development";
          break;
        case 'engineering.report':
          this.industry_outreach="Digital Advertising for Engineering and Manufacturing";
          break;
        case 'entertainment.report':
          this.industry_outreach="Digital Advertising for Entertainment, Travel and Hospitality";
          break;
        case 'government.report':
          this.industry_outreach="Digital Advertising for Central, State and Local Government";
          break;
        case 'greenenergy.report':
          this.industry_outreach="Digital Advertising for Renewable Energy";
          break;
        case 'healthcare.report':
          this.industry_outreach="Digital Advertising for Healthcare and Pharmaceuticals";
          break;
        case 'humanresources.report':
          this.industry_outreach="Digital Advertising for Human Resources, Compensation and Benefits";
          break;
        case 'informationsecurity.report':
          this.industry_outreach="Digital Advertising for IT and Technology";
          break;
        case 'infotech.report':
          this.industry_outreach="Digital Advertising for IT and Technology";
          break;
        case 'itinfrastructure.report':
          this.industry_outreach="Digital Advertising for IT and Technology";
          break;
        case 'manufacturing.report':
          this.industry_outreach= "Digital Advertising for Engineering and Manufacturing";
          break;
        case 'networking.report':
          this.industry_outreach="Digital Advertising for IT and Technology";
          break;
        case 'nonprofit.report':
          this.industry_outreach="Digital Advertising for NonProfits and Non-Governmental Organizations";
          break;
        case 'policy.report':
          this.industry_outreach="Digital Advertising for Insurance";
          break;
        case 'pos.report':
          this.industry_outreach="Digital Advertising for Retail and Wholesale Trade";
          break;
        case 'pharmaceutical.report':
          this.industry_outreach="Digital Advertising for Healthcare and Pharmaceuticals";
          break;
        case 're.report':
          this.industry_outreach="Digital Advertising for Real Estate and Construction";
          break;
        case 'smallbusiness.report':
          this.industry_outreach="Digital Advertising for Retail and Wholesale Trade";
          break;
        case 'theinternetofthings.report':
          this.industry_outreach="Digital Advertising for IT and Technology";
          break;
        case 'travel.report':
          this.industry_outreach="Digital Advertising for Entertainment, Travel and Hospitality";
          break;
        case 'virtualization.report':
          this.industry_outreach="Digital Advertising for IT and Technology";
          break;
        case 'wheels.report':
          this.industry_outreach="Digital Advertising for Transportation and Logistics";
          break;
        // default:
        //   this.industry_outreach=this.siteName;
        //   break;
    }
  }

}
