<p class="mb-3 mt-2 para-2-c-2" *ngIf="singleWhitePaperLoaded">
    <a routerLink="/">Home</a> >
    <a routerLink="/resources">Resources</a> >
    <a routerLink="/resources/whitepapers">Whitepapers</a> >

    <span class="para-2-c-1" [innerHTML]=singleWhitePaperDatas.WhitePaperTitle></span>
</p>
<div class="row">
    <div class="col-md-9">
        <div *ngIf="singleWhitePaperLoaded" class="mb-5">
            <p class="para-2 mb-0">{{singleWhitePaperDatas.Tag}}</p>
            <h1 [innerHTML]="singleWhitePaperDatas.WhitePaperTitle"></h1>
            <p class="para-4-bold">{{singleWhitePaperDatas.PublishingDate1}}</p>
            <div class="row">
                <div class="col-md-5">
                    <img class="img-fluid w-100" src="{{singleWhitePaperDatas.ImageUrl}}" alt="Responsive image">
                </div>
                <div class="col-md-7 mb-0">
                    <p class="para-6 mb-0 text-justify text-height-4" [innerHTML]="singleWhitePaperDatas.Description"></p>

                    <div class="pt-0">
                        <div class="modal-content">
                            <div class="pt-5 pb-5 pl-5 pr-5">
                                <app-single-whitepaper-downloadform [pDFUrl]="pDFUrl" [resourceID]="resourceid" [resourceType]="resourcetype"></app-single-whitepaper-downloadform>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <app-single-whitepaper-others (refreshEmitter)="receiveRefresh($event)"></app-single-whitepaper-others>
            </div>
            <div class="share-div" (click)="shareClick()">
                <i class="fa fa-share-alt" aria-hidden="true"></i>
            </div>
            <div class="share-in" [@openClose]="isOpen ? 'open' : 'closed'">
                <a href="https://www.facebook.com/sharer.php?u={{fullUrl}}" target="_blank">
                    <div class="share-inner f-icon">
                        <i class="fab fa-facebook-f"></i>
                    </div>
                </a>
                <a href="https://www.linkedin.com/shareArticle?mini=true&url={{fullUrl}}" target="_blank">
                    <div class="share-inner l-icon">
                        <i class="fab fa-linkedin-in"></i>
                    </div>
                </a>
                <a href="https://twitter.com/share?url={{fullUrl}}" target="_blank">
                    <div class="share-inner t-icon">
                        <i class="fab fa-twitter"></i>
                    </div>
                </a>
            </div>

        </div>
        <div class="text-center w-100">
            <button class="text-uppercase thm-btn mt-4 btn-theme-success w-50 pt-3 pb-3 white-color" routerLink="/resources/whitepapers">More Whitepapers</button>
        </div>
    </div>
    <div class="col-md-3">
        <app-single-whitepaper-spotlight></app-single-whitepaper-spotlight>
        <app-single-whitepaper-events></app-single-whitepaper-events>
    </div>

</div>