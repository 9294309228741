<h2 class="text-uppercase text-center mb-0 mt-3">
    Spotlight</h2>
<div class="text-center pt-3" *ngIf="Loaded">
  
    <div class="cursor-p" >
         <a href="{{CompanyWebsite}}" target="_blank">
            <img [src]="CompanyDetailsByAuthorsSpotLightDatas.CompanyNameLogo" class="img-fluid" alt="Responsive image">
            <h4  class="text-uppercase mt-2 text-center">{{CompanyDetailsByAuthorsSpotLightDatas.CompanyName}}</h4>
         </a> 
    </div>
    <p class="text-justify para-1 spotlight-text-height" [innerHTML]="CompanyDetailsByAuthorsSpotLightDatas.CompanyNameDescription"></p>
</div>