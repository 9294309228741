import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';



const httpOptions = {
  headers: new HttpHeaders(
    {
      'Content-Type': 'application/json',
    }
  )
};


const httpPostOptions = {
  headers: new HttpHeaders(
    {
      'Content-Type': 'application/json',
    }
  )
};


const httpPostDataOptions = {
  headers: new HttpHeaders(
    {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  )
};

@Injectable({
  providedIn: 'root'
})
export class HttpclientService {

  constructor(private http: HttpClient) { }

  getHttpclient(url: string): Observable<object> {
    return this.http.get(url, httpOptions);
  }
  
  postHttpclient(url: string, rawData : any): Observable<object> {
    return this.http.post(url, rawData, httpPostOptions);
  }

  postFormData(url: string,rawData : any ): Observable<any> {
    return this.http.post(url, rawData, httpPostDataOptions);
  }


}
