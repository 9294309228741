import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'; //import router
import { HttpclientService } from '../../../application/httpclient/httpclient.service';
import { ActivatedRoute } from '@angular/router';
import { state, trigger, transition, style, animate } from '@angular/animations';
import { Title, Meta } from '@angular/platform-browser';
import { environment } from '../../../../../../src/environments/environment';

@Component({
  selector: 'app-past-conference-single-page',
  templateUrl: './past-conference-single-page.component.html',
  styleUrls: ['./past-conference-single-page.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        width: '200px',
        opacity: 1,
        display:'block',
      })),
      state('closed', style({
        width: '0px',
        opacity: 0,
        display:'none',
      })),
      transition('open => closed', [
        animate('0.5s')
      ]),
      transition('closed => open', [
        animate('0.5s')
      ]),
    ]),
  ],

})
export class PastConferenceSinglePageComponent implements OnInit {


  href:string;
  lastString:string;
  receiveRefData:any;
  singleEventLoaded:boolean;
  singleWPastEvents:any;
  eventsEventID:any;
  pastconferencesid:any;
  fullUrl:any;
  isOpen = false;
  isOverlay = false;
  envSiteName:any;
  shortSiteName:string;
  titlePrefix:string;
  loaded:boolean;
  iData:any;

  constructor(private httpclientService: HttpclientService,public router:Router,private titleService: Title,private metaTagService: Meta, private activatedRoute: ActivatedRoute) { 
    this.envSiteName=environment.siteName;
  }

  ngOnInit(): void {
    this.singlePastEventsData();
    this.LogoData();
    this.fullUrl=window.location.href;
    this.pastconferencesid = this.activatedRoute.snapshot.params.id;
  }
  LogoData(): void {
    this.loaded = false;
    this.httpclientService.getHttpclient('https://api.nathanark.com/api/Home/GetMasterMetaCode?PubsiteName='+window.location.hostname)
      .subscribe(
        (  items: any) => {
          this.iData = items;
          this.titlePrefix = this.iData.PreSite;
          this.loaded = true;
        });
  }
  receiveRefresh($event){
    this.resetUsers();
    this.eventsEventID=$event;
    //console.log("receiveRefData",this.lastString);
    this.singlePastEventsData();
    //console.log("receiveRefData",this.lastString);
  }
  
  singlePastEventsData(){
    this.shortSiteName=this.envSiteName.substr(0, this.envSiteName.indexOf('.')); 
    if(this.eventsEventID != null){
      this.pastconferencesid=this.eventsEventID;
  }else{
    this.pastconferencesid =  this.activatedRoute.snapshot.params.id;
  }


    this.singleEventLoaded=false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/SingleConference/GetSinglePastConferenceById?SiteName='+this.shortSiteName+'&EventID='+this.pastconferencesid)
      .subscribe(
        items => {
          this.singleWPastEvents = items[0];

          this.singleEventLoaded=true;
          //console.log(this.singleWPastEvents);

          this.titleService.setTitle(this.singleWPastEvents.Name+' | '+this.titlePrefix);
          this.metaTagService.updateTag(
            { name: 'og:description', content: this.singleWPastEvents.Details}
          );
          this.metaTagService.updateTag(
            { name: 'og:title', content: this.singleWPastEvents.Name+' | '+this.titlePrefix}
          );
          this.metaTagService.updateTag(
            { name: 'og:image', content: this.singleWPastEvents.ImageUrl}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:description', content: this.singleWPastEvents.Details}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:title', content: this.singleWPastEvents.Name+' | '+this.titlePrefix}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:image', content: this.singleWPastEvents.ImageUrl}
          );
        });

  }
  shareClick(){
    this.isOpen = !this.isOpen;
  }
  
  resetUsers(): void {
    this.singleWPastEvents = null;
    this.singleEventLoaded = false;
    this.lastString=null;
    this.href=null;
  }

}
