import { Component, OnInit, Output, EventEmitter} from '@angular/core';
import { Router } from '@angular/router'; //import router
import { HttpclientService } from '../../../../../application/httpclient/httpclient.service';
import { environment } from '../../../../../../../../src/environments/environment';


@Component({
  selector: 'app-other-videos',
  templateUrl: './other-videos.component.html',
  styleUrls: ['./other-videos.component.scss']
})
export class OtherVideosComponent implements OnInit {

  refreshEmit:any;
  @Output() refreshEmitter= new EventEmitter<string>();

  
  moreVideosLoaded:boolean;
  moreVideosDatas:any;
  href:any;
  lastString:any;
  envSiteName:any;

  constructor(private httpclientService: HttpclientService,public router:Router) {
    this.envSiteName=environment.siteName;
   }

  ngOnInit(): void {
    this.moreVideosData();
  }

  refreshReq(videosId:any){
    
    this.resetUsers();
    
    this.refreshEmit= videosId;
    this.refreshEmitter.emit(this.refreshEmit);
    this.lastString= videosId;
    this.moreVideosData();
    
  }

  moreVideosData(){
    if(this.lastString==null){
    this.href = this.router.url;
    this.lastString = this.href.substring(this.href.lastIndexOf('/') + 1);
  }
    //console.log("other videos:",this.lastString)
    this.moreVideosLoaded=false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/SingleVideos/GetTop4SingleOtherVideos?siteName='+this.envSiteName+'&ID='+this.lastString)
      .subscribe(
        items => {
          this.moreVideosDatas = items;
          this.moreVideosLoaded=true;
          //console.log("videosDatas:",this.moreVideosDatas)
        });
  }

  resetUsers(): void {
    this.moreVideosDatas = null;
    this.moreVideosLoaded = false;
    this.lastString=null;
    this.href=null;
  }

}
