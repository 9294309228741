import { Component, HostListener, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpclientService } from '../../../application/httpclient/httpclient.service';

@Component({
  selector: 'advertise-thank-you-page',
  templateUrl: './advertise-thank-you-page.component.html',
  styleUrls: ['./advertise-thank-you-page.component.scss']
})
export class AdvertiseThankYouPageComponent implements OnInit {
  scrollToTop: number;
  pos: number;
  preSite: any;
  loaded:boolean;
  iData:any;
  previousRouteService: any;
  constructor(private httpclientService: HttpclientService,private route: Router) {
   }

  ngOnInit(): void {
    this.onActivate();
    this.LogoData();
    //location.reload();
    setTimeout(() => {
      /** spinner ends after 3 seconds */
      
      //this.route.navigateByUrl('/');
      window.location.href="/";
    }, 3000);
  }
  
  LogoData(): void {
    this.loaded = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/Home/GetMasterMetaCode?PubsiteName='+window.location.hostname)
      .subscribe(
          items=> {
          this.iData = items[0];
          this.preSite = this.iData.PreSite;
          this.loaded = true;
        });
        //location.reload();
  }
  onActivate() {
    this.scrollToTop = window.setInterval(() => {
        this.pos = window.pageYOffset;
        //console.log("pos value",this.pos)
        if (this.pos > 0) {
            window.scrollTo(0, this.pos - 40); // how far to scroll on each step
        } 
        else {
            window.clearInterval(this.scrollToTop);  
        }
    }, 2);
}


}
