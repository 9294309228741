import { Component, OnInit,Output,EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder,  Validators  } from '@angular/forms';
import { HttpclientService } from '../../../application/httpclient/httpclient.service';
import { environment } from '../../../../../../src/environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-member-login-pop',
  templateUrl: './member-login-pop.component.html',
  styleUrls: ['./member-login-pop.component.scss']
})
export class MemberLoginPopComponent implements OnInit {

  envSiteName:any;
  private isConsented: boolean=false;
  isShowDivIf:boolean=false;
  name:string="cookieconsent_status";

  @Output() cookies:EventEmitter<boolean>=new EventEmitter();
  constructor(private formBuilder: FormBuilder, private httpclientService: HttpclientService,private cookieService : CookieService) {
    this.envSiteName=environment.siteName;
    this.createLoginForm();
   }

   loginForm: FormGroup;
   submitted = false;
   loginUser:any;
   Message:any;

   get f() { return this.loginForm.controls; }

  ngOnInit(): void {
    let acceptcheck = this.cookieService.check('cookieconsent_status');
    //let declinecheck = this.cookieService.check('decline');
   
    if(this.cookieService.check('cookieconsent_status')){
      this.isShowDivIf = !this.isShowDivIf;
      this.cookies.emit(this.isShowDivIf);
    }
    else
    {
      this.isShowDivIf = this.isShowDivIf;
      this.cookies.emit(this.isShowDivIf);
    }
  }


  onSubmit() {

    this.submitted = true;
    if (this.loginForm.invalid) {
      this.isShowDivIf = this.isShowDivIf;
        return;
    }     
    if (this.loginForm.valid) {
      let cookieHide = document.getElementById('cookieId');
      //cookieHide.style.display = "none";
     }
    var formData: any = new FormData();

    formData.email = this.loginForm.get('email').value;     
    formData.password=this.loginForm.get('password').value;
    formData.acceptTerms=this.loginForm.get('acceptTerms').value;
    formData.siteName=this.envSiteName;

    

    this.loginUser = false;    
    this.httpclientService.postFormData('https://newapi.nathanark.com/api/AllForms/Login',  this.getFormUrlEncodedData(formData))
      .subscribe(
        items => {
          this.loginUser = items;
          //this.loginUser = true;
          //console.log("log in",this.loginUser)
 
          switch(this.loginUser)
          {
              case 'Agency':
              window.open("https://media7.io/new/Login.aspx?site="+this.envSiteName+"&user="+this.loginForm.get('email').value, "_self")
              this.isShowDivIf = !this.isShowDivIf;
              this.setCookie(this.name, "accept", 1);
              break;
              case 'Advertiser':
              window.open("https://media7.io/new/Login.aspx?site="+this.envSiteName+"&user="+this.loginForm.get('email').value, "_self")
              this.isShowDivIf = !this.isShowDivIf;
              this.setCookie(this.name, "accept", 1);
              break;
              case 'Other':
              window.open("https://media7.io/new/Login.aspx?site="+this.envSiteName+"&user="+this.loginForm.get('email').value, "_self")
              this.isShowDivIf = !this.isShowDivIf;
              this.setCookie(this.name, "accept", 1);
              break;
              case 'Publisher':
              window.open("https://media7.io/new/Login.aspx?site="+this.envSiteName+"&user="+this.loginForm.get('email').value, "_self")
              this.isShowDivIf = !this.isShowDivIf;
              this.setCookie(this.name, "accept", 1);
              break;
              case 'GuestAuthor':
              window.open("https://media7.io/new/Login.aspx?site="+this.envSiteName+"&user="+this.loginForm.get('email').value, "_self")
              this.isShowDivIf = !this.isShowDivIf;
              this.setCookie(this.name, "accept", 1);
              break;
              case 'not active':

              this.Message='You need to active your account for ' +this.envSiteName+', please check on your URL sent to you for account activation on signup';
              this.isShowDivIf = this.isShowDivIf;
              break;

              case 'not exist':
                this.Message = 'You need to signup for ' +this.envSiteName;
                this.isShowDivIf = this.isShowDivIf;
              break;

              default:
                this.Message = '*Invalid Email or Password';
                let cookieHide = document.getElementById('cookieId');
                cookieHide.style.display = "block";
                //alert("hiii");
                //this.isShowDivIf = this.isShowDivIf;
              break;
          };

        });

     ////////////////////cookies///////////////


    // setTimeout(() => {
    //   // this.isShowDivIf = !this.isShowDivIf;
    //   window.location.reload();
    // }, 1000);
  }
 
  setCookie(cName, cValue, expDays) {
    let date = new Date();
   //date.setTime(date.getTime() + (expDays * 60 * 60 * 60 * 1000));
   date.setFullYear(date.getFullYear() + 2);
   const expires = "expires=" + date.toUTCString();
   document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";

   if(new Date()>=date){
    this.isShowDivIf = this.isShowDivIf;
   }
  }
  
  createLoginForm(){
    this.loginForm = this.formBuilder.group({               
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(4)]],
      acceptTerms: [false, Validators.requiredTrue]
    });
  }


  getFormUrlEncodedData(toConvert) {
		const formBody = [];
		for (const property in toConvert) {
			const encodedKey = encodeURIComponent(property);
			const encodedValue = encodeURIComponent(toConvert[property]);
			formBody.push(encodedKey + '=' + encodedValue);
		}
		return formBody.join('&');
  }


  onReset() {
    this.submitted = false;
    this.loginForm.reset();
  }

}
