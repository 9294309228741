import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpclientService } from '../../../application/httpclient/httpclient.service';
import { environment } from '../../../../../../src/environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  bData: any;
  loaded: boolean;
  firstString: string;
  envSiteName:any;

  constructor(private httpclientService: HttpclientService, private routeUrl: Router) {
    this.envSiteName=environment.siteName;
   }


  ngOnInit(): void {
    this.bannerData();  
  }
  
  bannerData(): void {
    this.loaded = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/Home/GetHomeInterview?siteName='+this.envSiteName)
      .subscribe(
        items => {
          this.bData = items[0];
          this.loaded = true;
          //console.log("home Data",this.bData)
        });
  }
  redirect(getUrl:any,getId:any){
    this.firstString = getUrl.substring(0,5);
    if(this.firstString ==="https"){
      window.open(getUrl, "_blank");
    }
    else if(this.bData["InterviewType"]=="Thought_Leadership"){
      this.routeUrl.navigate(['/companies/thought-leaders/'+getUrl+"/"+getId]);
     }
   else{
      this.routeUrl.navigate(['/companies/all-c-suiteondeck/'+getUrl]);
    }
  }

}
