import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpclientService } from 'src/app/modules/application/httpclient/httpclient.service';
import { state, trigger, transition, style, animate } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../../../src/environments/environment';
import { Title, Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-single-infographics-page',
  templateUrl: './single-infographics-page.component.html',
  styleUrls: ['./single-infographics-page.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        width: '200px',
        opacity: 1,
        display:'block',
      })),
      state('closed', style({
        width: '0px',
        opacity: 0,
        display:'none',
      })),
      transition('open => closed', [
        animate('0.5s')
      ]),
      transition('closed => open', [
        animate('0.5s')
      ]),
    ]),
  ],
})
export class SingleInfographicsPageComponent implements OnInit {

  resourceid : number;
  newInfographicsId:any;
  resourcetype : string;
  pDFUrl: string;
  singleInfographicsDatas: any;
  singleInfographicsLoaded: boolean;
  href:string;
  lastString:string;
  isOpen = false;
  isOverlay = false;
  fullUrl:any;
  infographicsid:any;
  envSiteName:any;
  scrollToTop: number;
  pos: number;
  titlePrefix:string;
  loaded:boolean;
  iData:any;

  constructor(private httpclientService: HttpclientService,public router:Router,private titleService: Title,private metaTagService: Meta, private activatedRoute: ActivatedRoute) {
    this.envSiteName=environment.siteName;
  }

  ngOnInit(): void {
    this.singleInfographicsData();
    this.LogoData();
    this.fullUrl=window.location.href;
    this.infographicsid = this.activatedRoute.snapshot.params.id;
  }

  LogoData(): void {
    this.loaded = false;
    this.httpclientService.getHttpclient('https://api.nathanark.com/api/Home/GetMasterMetaCode?PubsiteName='+window.location.hostname)
      .subscribe(
        (  items: any) => {
          this.iData = items;
          this.titlePrefix = this.iData.PreSite;
          this.loaded = true;
        });
  }

  receiveRefresh($event){
    this.resetUsers();
    this.newInfographicsId=$event;
    //console.log("receiveRefData",this.lastString);
    this.singleInfographicsData();
    //console.log("receiveRefData",this.lastString);
  }
  

  singleInfographicsData(): void
  {
    if(this.newInfographicsId != null){
      this.infographicsid=this.newInfographicsId;
      //console.log("lastString newWInfographicsId",this.lastString);
  }else{
    this.infographicsid =  this.activatedRoute.snapshot.params.id;
  }
    this.singleInfographicsLoaded=false;
    
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/SingleInfographics/GetSingleInfographicById?siteName='+this.envSiteName+'&id='+this.infographicsid)
      .subscribe(
        items => {
          this.singleInfographicsDatas = items[0];
          this.resourceid = items[0].ID;
          this.resourcetype = items[0].ResourceType;
          this.pDFUrl = items[0].PDFUrl;
          this.singleInfographicsLoaded=true;
          //console.log("Single Infographics Datas ",this.singleInfographicsDatas);

          //seo code for tags
        this.titleService.setTitle(this.singleInfographicsDatas.WhitePaperTitle+' | '+this.titlePrefix);
        this.metaTagService.updateTag(
          { name: 'og:description', content: this.singleInfographicsDatas.Description}
        );
        this.metaTagService.updateTag(
          { name: 'og:title', content: this.singleInfographicsDatas.WhitePaperTitle+' | '+this.titlePrefix}
        );
        this.metaTagService.updateTag(
          { name: 'og:image', content: this.singleInfographicsDatas.imageurl}
        );
        this.metaTagService.updateTag(
          { name: 'twitter:description', content: this.singleInfographicsDatas.Description}
        );
        this.metaTagService.updateTag(
          { name: 'twitter:title', content: this.singleInfographicsDatas.WhitePaperTitle+' | '+this.titlePrefix}
        );
        this.metaTagService.updateTag(
          { name: 'twitter:image', content: this.singleInfographicsDatas.imageurl}
        );
        //seo code for tags end

        });
  }

  resetUsers(): void {
    this.singleInfographicsDatas = null;
    this.singleInfographicsLoaded = false;
    this.lastString=null;
    this.href=null;
    this.onActivate();
  }

  onActivate() {
    this.scrollToTop = window.setInterval(() => {
        this.pos = window.pageYOffset;
        //console.log("pos value",this.pos)
        if (this.pos > 0) {
            window.scrollTo(0, this.pos - 40); // how far to scroll on each step
        } 
        else {
            window.clearInterval(this.scrollToTop);  
        }
    }, 2);
}
 

  shareClick(){
    this.isOpen = !this.isOpen;
  }
}
