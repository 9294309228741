import { Injectable,Inject, EventEmitter } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FooterEmailServices {

  private Newsletter_Email =new BehaviorSubject("");
  //Newsletter_Email:any;

  constructor(@Inject(DOCUMENT) private dom) { }

  setNewsletter_Email(NewsletterEmail:string){
     //this.Newsletter_Email=NewsletterEmail;
     this.Newsletter_Email.next(NewsletterEmail);
  }

  getNewsletterEmail(){
    return this.Newsletter_Email.asObservable();
  }
}
