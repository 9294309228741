<p class="mb-3 para-2-c-2 p-0" *ngIf="allsingleGuestAuthorsProfileloaded">
    <a routerLink="/">Home</a> >
    <a routerLink="/companies">Companies</a> >
    <a routerLink="/companies/globalGuestAuthors">Guest Contributors</a> >
    <!-- <span [innerHTML]="allsingleGuestAuthorsProfileDatas.AuthorName | titlecase"></span> > -->
    <span class="para-2-c-1" [innerHTML]=allsingleGuestAuthorsProfileDatas.AuthorName></span>
</p>
<div class="row">
    <div class="col-md-9">
        <div *ngIf="allsingleGuestAuthorsProfileloaded" class="mb-5">
            <!-- <p class="text-uppercase">Account Based Data</p> -->
            
           
            <div class="row">

                <div class="col-md-3">
                   
                    <img class="rounded-circle" height="224" width="224" src="{{allsingleGuestAuthorsProfileDatas.UserImageprofile}}" alt="articles image">
               
                </div>
                <div class="col-md-9">
                    <h2 class="mb-0" [innerHTML]="allsingleGuestAuthorsProfileDatas.AuthorName"></h2>
                    <p class="para-4-bold mb-0">{{allsingleGuestAuthorsProfileDatas.UserJobTitle | titlecase }}</p>
                    <p class="para-6 mb-0 text-justify" [innerHTML]="allsingleGuestAuthorsProfileDatas.UserBio"></p>

                </div>
            </div>
           
        </div>
        <app-resources-guest-authors *ngIf="AuthorEmail" [AuthorEmail]="AuthorEmail" ></app-resources-guest-authors>
    </div>
    <div class="col-md-3">
       
        <app-single-guest-author-spotlight *ngIf="AuthorEmail" [AuthorEmailID]="AuthorEmail"></app-single-guest-author-spotlight>
        
        <app-guest-other-authers *ngIf="authorId" [AuthorID]="authorId"></app-guest-other-authers>
    </div>
</div>
 
        <div class="col-md-9">
        
        </div>
    





<!-- <div class="share-div" (click)="shareClick()">
    <i class="fa fa-share-alt" aria-hidden="true"></i>
</div>
<div class="share-in" [@openClose]="isOpen ? 'open' : 'closed'">
    <a href="https://www.facebook.com/sharer.php?u={{fullUrl}}" target="_blank">
        <div class="share-inner f-icon">
            <i class="fab fa-facebook-f"></i>
        </div>
    </a>
    <a href="https://www.linkedin.com/shareArticle?mini=true&url={{fullUrl}}" target="_blank">
        <div class="share-inner l-icon">
            <i class="fab fa-linkedin-in"></i>
        </div>
    </a>
    <a href="https://twitter.com/share?url={{fullUrl}}" target="_blank">
        <div class="share-inner t-icon">
            <i class="fab fa-twitter"></i>
        </div>
    </a>
</div> -->