<!-- Modal -->
<div class="accountInfo">
 <div class="register">
  <div class="" id="memberSignupModalCenter" tabindex="-1"
    aria-labelledby="memberSignupModalCenterTitle">
    <div class="sign_modal_center1">
      <div class="" role="document">
        <div class="modal-content news_modal_content">
            <div class="modal-body">
                <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                    <p class="pop-title text-capitalize text-center pop_title_news">Sign up</p>
                    <p class="text-center pop-para">Sign up to contribute and publish your news, events, brand, and
                        content with the community for <b>FREE</b></p>
                    <p id="msg" class="text-danger">{{Message}}</p>
                        <div class="row">
                            <div class="col-lg-12 form-group FNameBox">
                                <input type="text" formControlName="firstName"
                                    [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" class="form-control"
                                    id="firstnameSignup" aria-describedby="emailHelp" placeholder="*First Name">

                                <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                                    <div *ngIf="f.firstName.errors.required">*Required</div>
                                </div>

                            </div>
                            <div class="col-lg-12 form-group LNameBox">
                                <input type="text" formControlName="lastName"
                                    [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" class="form-control "
                                    id="lastnameSignup" aria-describedby="emailHelp" placeholder="*Last Name">

                                <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                                    <div *ngIf="f.lastName.errors.required">*Required</div>
                                </div>
                            </div>
                            <div class="col-lg-12 form-group">
                        <input type="email" formControlName="email"
                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" class="form-control"
                            id="emailSignup" aria-describedby="emailHelp" placeholder="*Email">

                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">*Required</div>
                            <div *ngIf="f.email.errors.email">*Invalid Email </div>
                        </div>

                            </div>
                            <div class="col-lg-12 form-group NewPassBox">
                                <input type="password" formControlName="password" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.password.errors }" id="passwordSignup"
                                    aria-describedby="emailHelp" placeholder="*Password">
                                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                    <div *ngIf="f.password.errors.required">*Required</div>
                                    <div *ngIf="f.password.errors.minlength">*Password must be at least 4 characters
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 form-group ConfPassBox">

                                <input type="password" formControlName="confirmPassword" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }"
                                    id="confirmPasswordSignup" aria-describedby="emailHelp"
                                    placeholder="*Confirm Password">

                                <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                                    <div *ngIf="f.confirmPassword.errors.required">*Required</div>
                                    <div *ngIf="f.confirmPassword.errors.mustMatch">*Passwords must match</div>
                                </div>

                            </div>
                            <span>Are you an advertiser, agency or publisher?</span>
                            <div class="col-lg-12 form-group">
                              <select formControlName="role" placeholder="Select" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.role.errors }">
                                <option value="" [disabled]="true">Select</option>
                                <option value="Advertise">Advertiser</option>
                                <option value="Agency">Agency</option>
                                <option value="Publisher">Publisher</option>
                                <option value="Publisher">Other</option>
                              </select>
                              <div *ngIf="submitted && f.role.errors" class="invalid-feedback">
                                <div *ngIf="f.role.errors.required">*Required</div>
                              </div>
                            </div>
                        </div>
                    <!-- <div class="form-check text-center mb-3">
                        <input type="checkbox" formControlName="acceptTerms" id="accepttermsSignup"
                            class="form-check-input" [ngClass]="{ 'is-invalid': submitted && f.acceptTerms.errors }">

                        <label class=" pop-para" for="acceptTerms"> I agree to the 
                            <a target="_blank" routerlink="/terms-and-service" href=""  data-dismiss="modal" aria-label="Close">terms of
                                service</a> and <a target="_blank" routerlink="/privacy-policy" href="" data-dismiss="modal" aria-label="Close">privacy policy</a>.
                            </label>
                        <div *ngIf="submitted && f.acceptTerms.errors" class="invalid-feedback text-left">Required</div>
                    </div> -->
                    <div class="form-check text-center mb-3">
                        <input type="checkbox" class="form-check-input" id="accepttermsSignup" formControlName="acceptTerms" [ngClass]="{ 'is-invalid': submitted && f.acceptTerms.errors }">
                        <label class=" pop-para" for="acceptTerms"> I agree to the <a href="" data-dismiss="modal" aria-label="Close" routerLink='/terms-and-service'>terms of service</a> and <a href="" data-dismiss="modal" aria-label="Close"
                            routerLink='/privacy-policy'>privacy policy</a>.</label>

                        <div *ngIf="submitted && f.acceptTerms.errors" class="invalid-feedback text-left">*Required</div>

                    </div>
                    <button class="text-uppercase thm-btn btn-theme-success w-100 pt-3 pb-3"> Submit</button>
                </form>
            </div>
            <div class="free-service">
                <h3 id="FreeServices" class="bannermobile maincolor">
                    Free Services</h3>
                <p>
                    Whether you are an advertiser, agency, or a publisher (author, contributor), these
                    FREE services are available to all members.
                </p>
                <ul>
                    <li>Create your <strong>company</strong> page and get listed in the companies directory</li>
                    <li>Share <strong>news</strong>, make announcements, and distribute press releases</li>
                    <li>Add your <strong>events</strong> like live webcasts, conferences, seminars, and
                        trade shows </li>
                    <li>Upload your company's promotional <strong>content</strong> like whitepapers,
                        webinars,videos, infographics, and blog articles</li>
                </ul>
                <p>
                    You can also have your content promoted on social media (like LinkedIn, Facebook,
                    Twitter) and on the Google and Bing search for free.</p>
            </div>
            <div class="discover">
                <!-- <h3 class="bannermobile">
                    Discover Our</h3>
                <h2 class="bannermobile">
                    Digital Advertising Services</h2>
                <a style="cursor: pointer">
                    <img src="https://media7.io/Advertise/PubSite/images/media7-mediakit.png" id="imgBannerClick" style="width: 90%;" onclick="return imgBannerClick_onclick()"> 
                </a> 
                <h3 class="bannermobile">
                    Discover Our</h3>
                <h2 class="bannermobile">
                    Lead Generation Services</h2>
                <a style="cursor: pointer">
                    <img src="https://media7.io/Advertise/PubSite/images/deck7-mediakit.png" id="imgBanner2Click" onclick="return imgBanner2Click_onclick()">
                </a>-->
                <h3 class="bannermobile">
                    Discover Our</h3>
                <h2 class="bannermobile">
                    Digital Advertising Services</h2>
                <a style="cursor: pointer">
                    <img src="https://media7.io/Advertise/PubSite/images/media7-mediakit_no_shadow.png" id="imgBanner2Click" data-toggle="modal" data-target="#leadGenerationModalCenter">
                </a>
            </div>
        </div>
      </div>
     </div>
   </div>
  </div>
</div>
<lead-generation-popup></lead-generation-popup>