<p class="mb-3 mt-2 para-2-c-2" *ngIf="singleOnDemandLoaded">
    <a routerLink="/">Home</a> > <a routerLink="/events">Events</a> >
    <a routerLink="/events/{{singleOnDemand.EventDifferentType}}" class="text-capitalize"> on-demand webinars</a> >
    <span class="para-2-c-1" [innerHTML]=singleOnDemand.Name></span>
</p>

<div class="row">
    <div class="mb-5 col-md-12">
        <div *ngIf="singleOnDemandLoaded">
            <h1 [innerHTML]="singleOnDemand.Name"></h1>

            <p class="para-4-bold">{{singleOnDemand.DateWithStartTime}} | EST </p>
            <img class="img-fluid mb-3 image-hight-webinar-single" src="{{singleOnDemand.ImageUrl}}" alt="{{singleOnDemand.Name}}">
            <p class="para-2 mb-1" innerHTML="singleOnDemand.Details"> </p>
            <p class="para-1 text-justify mb-1 text-height-4 card-header-height-4" [innerHTML]="singleOnDemand.Details"></p>
            <a class="para-2 para-2-c-2" target="_blank" href="{{singleOnDemand.URL}}">Watch Now</a>
        </div>

        <div class="share-div" (click)="shareClick()">
            <i class="fa fa-share-alt" aria-hidden="true"></i>
        </div>
        <div class="share-in" [@openClose]="isOpen ? 'open' : 'closed'">
            <a href="https://www.facebook.com/sharer.php?u={{fullUrl}}" target="_blank">
                <div class="share-inner f-icon">
                    <i class="fab fa-facebook-f"></i>
                </div>
            </a>
            <a href="https://www.linkedin.com/shareArticle?mini=true&url={{fullUrl}}" target="_blank">
                <div class="share-inner l-icon">
                    <i class="fab fa-linkedin-in"></i>
                </div>
            </a>
            <a href="https://twitter.com/share?url={{fullUrl}}" target="_blank">
                <div class="share-inner t-icon">
                    <i class="fab fa-twitter"></i>
                </div>
            </a>
        </div>
    </div>

    <app-other-on-demand (refreshEmitter)="receiveRefresh($event)"></app-other-on-demand>
    <div class="text-center w-100">
        <button class="text-uppercase thm-btn mt-4 btn-theme-success w-50 pt-3 pb-3" routerLink="/events/on-demand-webinars">MORE ON-DEMAND WEBINARS</button>
    </div>
</div>