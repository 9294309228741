<!-- <div class="row">
    <div class="col-md-9">
        <app-globel-resources></app-globel-resources>
    </div>
    <div class="col-md-3">
        <app-resources-spotlight></app-resources-spotlight>
        <app-square-ad-videos></app-square-ad-videos>
        <app-resources-event></app-resources-event>
        <app-square-ad-videos></app-square-ad-videos>
    </div>
</div> -->

<app-globel-resources></app-globel-resources>