<h5>OTHER WHITEPAPERS</h5>
<div class="row d-none d-md-flex" *ngIf="singleWhitePaperOthersLoaded">
    <div class="col-md-4 mb-5" *ngFor="let singleWhitePaperOthersData of singleWhitePaperOthersDatas">
         <!-- <div class="cursor-p" (click)="refreshReq(singleWhitePaperOthersData.ID)" routerLink='/resources/whitepapers/{{singleWhitePaperOthersData.RouteURL}}/{{singleWhitePaperOthersData.ID}}'> -->
        <a target="_blank" href='{{singleWhitePaperOthersData.WebPageURL | lowercase}}' >
            <img class="img-fluid pb-2" [src]="singleWhitePaperOthersData.ImageUrl" alt="news image">
            <h4 class="text-height-4 card-header-height-1 text-uppercase" [innerHTML]="singleWhitePaperOthersData.WhitePaperTitle"></h4>
        </a>
            <p class="para-2 mb-2">{{singleWhitePaperOthersData.ResourceType|titlecase}} | {{singleWhitePaperOthersData.PublishingDate1}}</p>
            <p class="para-1 text-justify mb-1 text-height-4 text-justify" [innerHTML]="singleWhitePaperOthersData.Description"></p>
            <a target="_blank" href='{{singleWhitePaperOthersData.WebPageURL | lowercase}}' class="para-2 para-2-c-2 ">Read More</a>
        <!-- </div>  -->
    </div>
</div>
