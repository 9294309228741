
    <h2 class="text-uppercase mb-0">Other Authors</h2>
    <div class="row d-md-flex mt-3" *ngIf="guestAuthorsloaded">
        
        <div class="col-md-12" *ngFor="let otherAuthorsData of guestAuthorsDatas.slice(0, 3) | paginate : 
        {
            itemsPerPage: pageSize,
            currentPage: page,
            totalItems: count 
          };
    let i = index" [class.active]="i == currentIndex">
         
    <!-- <div class="cursor-p" (click)="redirect(otherAuthorsData.UserBio,'/resources/single-guest-authors-profile/',otherAuthorsData.AuthorNameForUrl,otherAuthorsData.UserDetailsId)"> -->
        <a target="_blank" routerLink='/guest-contributors/{{otherAuthorsData.AuthorNameForUrl}}' >
            <img class="pb-2" height="205" width="205" src="{{otherAuthorsData.UserImageprofile}}" alt="articles image">
       
                <!-- <img class="img-fluid pb-2" [src]="otherAuthorsData.ImageUrl" alt="Author" > -->
                <h4 class="text-height-2 card-header-height-2 text-uppercase" [innerHTML]="otherAuthorsData.AuthorName"></h4>
                <!-- <p class="para-2 mb-1">{{otherAuthorsData.JobTitle}}</p> -->
            <!-- </div> -->
                <!-- <p  class="para-2 mb-1" [innerHTML]="otherAuthorsData.UserJobTitle"></p> -->
                <p class="para-2 mb-1">{{otherAuthorsData.UserJobTitle}}</p>
        </a>
        </div>
    </div>




