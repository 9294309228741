import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router'; //import router
import { HttpclientService } from '../../../../application/httpclient/httpclient.service';
import { environment } from '../../../../../../../src/environments/environment';

@Component({
  selector: 'app-other-on-demand',
  templateUrl: './other-on-demand.component.html',
  styleUrls: ['./other-on-demand.component.scss']
})
export class OtherOnDemandComponent implements OnInit {

  refreshEmit:any;
  @Output() refreshEmitter= new EventEmitter<string>();

  moreOnDemandwebinarLoaded:boolean;
  moreDemandEventDatas:any;
  href:any;
  lastString:any;
  envSiteName:any;
  shortSiteName:string;
  firstString: any;


  constructor(private httpclientService: HttpclientService,public router:Router) {
    this.envSiteName=environment.siteName;
   }

  ngOnInit(): void {
    this.moreOnDemandWebinartData();
  }

  refreshReq(EventID:any){
    
    this.resetUsers();
    
    this.refreshEmit=EventID;
    this.refreshEmitter.emit(this.refreshEmit);
    this.lastString=EventID;
    this.moreOnDemandWebinartData();


}


  moreOnDemandWebinartData(){
    this.shortSiteName=this.envSiteName.substr(0, this.envSiteName.indexOf('.')); 
    if(this.lastString==null){
    this.href = this.router.url;
    this.lastString = this.href.substring(this.href.lastIndexOf('/') + 1);
  }
  
  //console.log(this.lastString)
    this.moreOnDemandwebinarLoaded=false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/SingleLiveWebinar/GetSingleOnDemandWebinarTop4?SiteName='+this.shortSiteName+'&EventID='+this.lastString)
      .subscribe(
        items => {
          this.moreDemandEventDatas = items;
          this.moreOnDemandwebinarLoaded=true;
          //console.log("events moreEventDatas",this.moreDemandEventDatas)
          
        });
  }
  resetUsers(): void {
    this.moreDemandEventDatas = null;
    this.moreOnDemandwebinarLoaded = false;
    this.lastString=null;
    this.href=null;
  }

  redirect(getUrl:any,getId:any)
  {
    this.firstString = getUrl.substring(0,5);
    if(this.firstString ==="https")
    {
      window.open(getUrl, "_blank");
    }else
    {
      this.router.navigate(['/events/on-demand-webinars/'+getUrl+"/"+getId]);
    }
  }

}
