<div class="row">
    <div class="col-md-9">
        <!-- <h1 class="text-uppercase mb-0 mt-2">Resources</h1> -->
        <!-- <h1 class="text-uppercase mb-0 mt-2">{{this.tag}} Resources</h1> -->
        <h1 class="text-uppercase mb-0 mt-2">Resources</h1>
        <!-- <ul class="nav nav-tabs mobTab" id="myTab" role="tablist">
            <li class="nav-item">
                <a class="nav-link pl-4 pr-4 pl-md-5 pr-md-5 text-uppercase border-right-none active" id="home-tab" data-toggle="tab" routerLink="/resources/articles" role="tab" aria-controls="home" aria-selected="false">Articles</a>
            </li>
            <li class="nav-item">
                <a class="nav-link pl-4 pr-4 pl-md-5 pr-md-5 text-uppercase border-right-none" id="profile-tab" data-toggle="tab" routerLink="/resources/videos" role="tab" aria-controls="profile" aria-selected="false">Videos</a>
            </li>
            <li class="nav-item">
                <a class="nav-link pl-4 pr-4 pl-md-5 pr-md-5 text-uppercase border-right-none" id="profile-tab" data-toggle="tab" routerLink="/resources/whitepapers" role="tab" aria-controls="profile" aria-selected="false">Whitepapers</a>
            </li>
            <li class="nav-item">
                <a class="nav-link pl-4 pr-4 pl-md-5 pr-md-5 text-uppercase border-right-none" id="profile-tab" data-toggle="tab" routerLink="/resources/infographics" role="tab" aria-controls="profile" aria-selected="false">Infographics</a>
            </li>
            <li class="nav-item">
                <a class="nav-link pl-4 pr-4 pl-md-5 pr-md-5 text-uppercase" id="profile-tab" data-toggle="tab" routerLink="/resources/webinars" role="tab" aria-controls="profile" aria-selected="false">Webinars</a>
            </li>
        </ul> -->
        <div class="row mt-5" *ngIf='resourceArticleLoaded==false'>
            <div class="col-md-4 pb-5" *ngFor="let d of dumArray">
                <!-- <list-content-loader></list-content-loader> -->
                <list-content-loder></list-content-loder>
            </div>
        </div>
        <div class="row d-md-flex mt-3 WrapArt" *ngIf="resourceArticleLoaded">
            <div class="col-md-4 pb-5" *ngFor=" let resourcesArticleData of resourcesArticleDatas | paginate : {
            itemsPerPage: pageSize,
            currentPage: page,
            totalItems: count
          };
    let i = index
  " [class.active]="i == currentIndex">

                <!-- <div class="cursor-p" routerLink='{{resourcesArticleData.RouteURL}}/{{resourcesArticleData.ID}}'> -->
                    <a target="_blank" href='{{resourcesArticleData.RouteURL}}' >
                    <img class="img-fluid pb-2 image-hight-news" [src]="resourcesArticleData.ImageUrl" alt="articles image">
                    </a>
                    <!--<h6 class="text-height-4 card-header-height-1 text-uppercase" [innerHTML]="resourcesArticleData.Tag"></h6>-->
                    <p class="para-2 mb-0 CatTag" *ngIf="resourcesArticleData.Tag!=''; else BlankTag">{{resourcesArticleData.Tag}}</p>
                    <ng-template #BlankTag>
                      <p class="para-2 mb-0 tag"></p>
                    </ng-template>
                    <a target="_blank" href='{{resourcesArticleData.RouteURL}}' >
                    <h4 class="text-height-4 card-header-height-1 text-uppercase MainTitle" [innerHTML]="resourcesArticleData.WhitePaperTitle">
                    </h4>
                    </a>
                    <p class="para-2 mb-1 sponDate">{{resourcesArticleData.ResourceType}} <span *ngIf='resourcesArticleData.ResourceType!="" && resourcesArticleData.PublishingDate1!=""'>|</span> {{resourcesArticleData.PublishingDate1}} 
                        <span *ngIf='resourcesArticleData.IsSponcered==true'>| <font>Sponsored</font></span>  
                    </p>
                    <p class="para-1 text-justify text-height-4 DescPara" [innerHTML]="resourcesArticleData.Description"></p>
                    <a target="_blank" href='{{resourcesArticleData.RouteURL}}' >
                    <a class="para-2 para-2-c-2 Read_More">Read More</a>
                </a>
                <!-- </div> -->
            </div>
        </div>
        <div class="col-12 p-0 text-center mt-md-0" *ngIf="count>9">
            <pagination-controls previousLabel="Prev" nextLabel="Next" responsive="true" (pageChange)="handlePageChange($event)"></pagination-controls>
        </div>
    </div>
    <div class="col-md-3">
        <app-tag-spotlight></app-tag-spotlight>
        <app-category-tag-event></app-category-tag-event>
        <!-- <div class="text-center ADdiv" style="display:flex; justify-content:center; align-items:center">
            <iframe scrolling="none" frameborder="0" marginwidth="0" marginheight="0" style='min-height:266px; border: none;' ng-include [src]="HomeADAfterWhitepaper"></iframe></div> -->
        <app-square-ad-videos></app-square-ad-videos>
        <app-resources-event></app-resources-event>
        <app-square-ad-videos></app-square-ad-videos>
    </div>
</div>