import { isPlatformBrowser } from '@angular/common';
import { APP_ID, Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { strict } from 'assert';
import { HttpclientService } from 'src/app/modules/application/httpclient/httpclient.service';
import { environment } from '../../../../../../../src/environments/environment';
const STATE_KEY_ITEMS = makeStateKey('items');
@Component({
  selector: 'app-sigle-guest-author-news',
  templateUrl: './sigle-guest-author-news.component.html',
  styleUrls: ['./sigle-guest-author-news.component.scss']
})
export class SigleGuestAuthorNewsComponent implements OnInit {
  @Input() guestAuthorsNews:string;

  items: any = [];
  dumArray:any=[1,2,3,4,5,6,7,8,9];
  currentIndex = -1;
  title = '';
  page = 1;
  count = 0;
  pageSize = 8;
href: string;
lastString: string;
allNewsGuestAuthorsLoaded: boolean;
allNewsGuestAuthorsDatas: any;
envSiteName:any;
  scrollToTop: number;
  pos: number;

  constructor(private state: TransferState,private httpclientService: HttpclientService,@Inject(PLATFORM_ID) private platformId: Object,
  @Inject(APP_ID) private appId: string,public router: Router) {
    this.envSiteName=environment.siteName;
   }

  ngOnInit(): void {
    this.getAllNewsByAuthorData();
  }
  getAllNewsByAuthorData(): void{
    this.items = this.state.get(STATE_KEY_ITEMS, <any> []);
    this.href = this.router.url;
    this.lastString = this.href.substring(this.href.lastIndexOf('/') + 1);

    this.allNewsGuestAuthorsLoaded = false;
    //let re = /%20/gi;
    let str1: string =this.guestAuthorsNews;
    let result = str1.replace(' ', '-');
    this.httpclientService.getHttpclient('https://api.nathanark.com/api/GlobalGuestAuthors/GetAllNewsGuestAuthorProfileByName?siteName='+this.envSiteName+'&authorName='+result+'&pageNumber='+this.page)
    .subscribe(
      items => {
        
        const platform = isPlatformBrowser(this.platformId) ?
        'in the browser' : 'on the server';
        this.allNewsGuestAuthorsDatas = items;
        this.count=this.allNewsGuestAuthorsDatas[0].TotalDataCount;

        this.allNewsGuestAuthorsLoaded = true;
        this.state.set(STATE_KEY_ITEMS, <any> items);
        //console.log("allNewsGuestAuthorsDatas", this.allNewsGuestAuthorsDatas);
        
      }
    );

  }
  resetUsers(): void {
    this.allNewsGuestAuthorsDatas = null;
    this.allNewsGuestAuthorsLoaded = false;
    this.lastString = null;
    this.href = null;
  }

  handlePageChange(event): void {
    this.resetUsers();
    this.getAllNewsByAuthorData();
    this.onActivate();
  }
  onActivate() {
    this.scrollToTop = window.setInterval(() => {
        this.pos = window.pageYOffset;
        //console.log("pos value",this.pos)
        if (this.pos > 0) {
            window.scrollTo(0, this.pos - 40); // how far to scroll on each step
        } 
        else {
            window.clearInterval(this.scrollToTop);  
        }
    }, 2);
}
}
