<p class="mb-0 BreadcbTop">
    <a href="/">Home</a> >
     <a href="/news">News</a> > 
     <span class="para-2-c-1">Latest</span>
</p>
<h1 class="text-uppercase">Latest</h1>
<div class="row" *ngIf='trendingNewsLoaded==false'>
    <div class="col-md-4 pb-5" *ngFor="let d of dumArray">
        <list-content-loader></list-content-loader>
    </div>
</div>
<div class="row d-md-flex WrapArt" *ngIf="trendingNewsLoaded">
    <div class="col-md-4 pb-5" *ngFor="
    let trendingNewsData of trendingNewsDatas | paginate : {
            itemsPerPage: pageSize,
            currentPage: page,
            totalItems: count
          };
    let i = index
  " [class.active]="i == currentIndex">
        <!-- <div class="col-md-4 pt-3" *ngFor="let trendingNewsData of trendingNewsDatas"> -->
        <!-- <div class="cursor-p" routerLink='{{trendingNewsData.RouteURL}}/{{trendingNewsData.ID}}'> -->
            <!-- <a target="_blank"  routerLink='{{trendingNewsData.RouteURL}}/{{trendingNewsData.ID}}'> -->
        <a target="_blank"  href='https://{{envSiteName}}/{{trendingNewsData.EventDifferentType | lowercase }}/{{trendingNewsData.RouteURL}}'>
            
            <img class="img-fluid pb-2 image-hight-news" [src]="trendingNewsData.ImageUrl" alt="news image">
        </a>    
            <p class="para-2 mb-0 CatTag" *ngIf='trendingNewsData.Tag!=" " && trendingNewsData.Tag!="" && trendingNewsData.Tag!=null; else BlankTag'>{{trendingNewsData.Tag}}</p>
            <ng-template #BlankTag>
                <p class="para-2 mb-0 tag"></p>
            </ng-template>
        <a target="_blank"  href='https://{{envSiteName}}/{{trendingNewsData.EventDifferentType | lowercase }}/{{trendingNewsData.RouteURL}}'>    
            <h4 class="text-height-4 card-header-height-1 TagTitle" [innerHTML]="trendingNewsData.Title">
            </h4>
        </a>
            <p class="para-2 mb-1 sponDate">{{trendingNewsData.CompanyName}} <span *ngIf='trendingNewsData.CompanyName!="" && trendingNewsData.CompanyName!=" " && trendingNewsData.CompanyName!=null 
                && trendingNewsData.PublishingDate!="" && trendingNewsData.PublishingDate!=" " && trendingNewsData.PublishingDate!=null'> |</span> {{trendingNewsData.PublishingDate}}
                <span *ngIf="trendingNewsData.IsSponsored"> | <font class="para-2-c-2">Sponsored</font></span>
             </p>
            <p class="para-1 text-justify text-height-4 DescPara" [innerHTML]="trendingNewsData.Description"></p>
            <!-- <a class="para-2 para-2-c-2 " target="_blank" href='news/trending-news/{{trendingNewsData.RouteURL}}/{{trendingNewsData.ID}}'>Read More</a> -->
            <a class="para-2 para-2-c-2 Read_More" target="_blank" href='https://{{envSiteName}}/{{trendingNewsData.EventDifferentType  | lowercase}}/{{trendingNewsData.RouteURL}}'>Read More</a>
            <!-- </div> -->
    </div>
</div>
<div class="row">
    <div class="col-12 p-0 text-center mt-md-0" *ngIf="count>9">
        <pagination-controls previousLabel="Prev" nextLabel="Next" responsive="true" (pageChange)="handlePageChange($event)"></pagination-controls>
    </div>
</div>