import { ThrowStmt } from '@angular/compiler';
import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { HttpclientService } from '../../src/app/modules/application/httpclient/httpclient.service';
import { environment } from '../../src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent {
  href:any;
  lastString:string;
  lastStringFlag:boolean=true;
  iData: any;
  loaded: boolean;
  envSiteName:any;
  preSite: any;
  public constructor(private titleService: Title,private router:Router,private metaTagService: Meta,private httpclientService: HttpclientService ) {
    this.envSiteName=environment.siteName;
   }
 
  ngOnInit(): void {
    this.LogoData();
    // this.changeColor();
    //this.titleService.setTitle( "The "+this.preSite+ " | Latest News, Articles, Events & Insights");
    //get current url
    this.href = this.router.url;
    this.lastString = this.href.substring(this.href.lastIndexOf('/') + 1);
  }

  cookies(isShowDivIf){
    console.warn(isShowDivIf);
  }
  LogoData(): void {
    this.loaded = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/Home/GetMasterMetaCode?PubsiteName='+this.envSiteName)
      .subscribe(
        (  items: any) => {
          this.iData = items[0];
          this.preSite = this.iData.PreSite;
          //this.titleService.setTitle( this.preSite+ " | Latest News, Articles, Events & Insights");
          document.documentElement.style.setProperty('--MainColor', this.iData.color);   
          document.documentElement.style.setProperty('--HoverColor', this.iData.ColorHover);
          this.loaded = true;
          //console.log("i data app component",this.iData)
        });
  }
  // changeColor(){
  //   document.documentElement.style.setProperty('--MainColor', this.iData.color);   
  //   document.documentElement.style.setProperty('--HoverColor', this.iData.ColorHover);
  // }
  
}
