 
 <h2 class="text-uppercase text-center mb-0 spotn SpotEveRes spotn" >
    Spotlight</h2>
    <div class="text-center spotDiv spotGDiv" *ngIf="loaded"  >
        <!-- <img [src]="sData.logo" class="logo-img-sportlight" alt="Responsive image"> -->
        <div class="cursor-p">

            <a href="{{spotLightDatas.URL}}" target="_blank">

                <img [src]="spotLightDatas.ImageUrl" class="img-fluid home-spotlight-logo" alt="Responsive image">
                <h4  class="text-uppercase mt-2 text-center">{{spotLightDatas.WhitePaperTitle}}</h4>
            </a>
        </div>
        <p class="text-justify para-1 spotlight-text-height spotlightPara" [innerHTML]=" spotLightDatas.Description"></p>
</div>


