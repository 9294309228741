import { APP_ID, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { TransferState } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { HttpclientService } from 'src/app/modules/application/httpclient/httpclient.service';
import { environment } from '../../../../../../../src/environments/environment';


@Component({
  selector: 'app-sigle-guest-authors-related-articles',
  templateUrl: './sigle-guest-authors-related-articles.component.html',
  styleUrls: ['./sigle-guest-authors-related-articles.component.scss']
})
export class SigleGuestAuthorsRelatedArticlesComponent implements OnInit {

  href: string;
  lastString: string;
  relatedArticlesLoaded: boolean;
  relatedArticlesDatas: any;
  envSiteName:any;
  scrollToTop: number;
  pos: number;

  constructor(private state: TransferState,private httpclientService: HttpclientService,@Inject(PLATFORM_ID) private platformId: Object,
  @Inject(APP_ID) private appId: string,public router: Router) {
    this.envSiteName=environment.siteName;
   }

  ngOnInit(): void {
    this.relatedArticlesData();
  }

  relatedArticlesData(): void{
    
    this.href = this.router.url;
    this.lastString = this.href.substring(this.href.lastIndexOf('/') + 1);

    this.relatedArticlesLoaded = false;
    
    let str1: string =this.relatedArticlesDatas;

    this.httpclientService.getHttpclient('https://api.nathanark.com/api/SingleResources/GetTop4SingleResourceOtherArticles?siteName='+this.envSiteName)
    .subscribe(
      items => {
        this.relatedArticlesDatas = items;
        this.relatedArticlesLoaded = true;
        //console.log("related ArticlesDatas:", this.relatedArticlesDatas);
        
      });
  }

  resetUsers(): void {
    this.relatedArticlesDatas = null;
    this.relatedArticlesLoaded = false;
    this.lastString = null;
    this.href = null;
  }

  handlePageChange(event): void {
    this.resetUsers();
    this.relatedArticlesData();
    this.onActivate();
  }

  onActivate() {
    this.scrollToTop = window.setInterval(() => {
        this.pos = window.pageYOffset;
        //console.log("pos value",this.pos)
        if (this.pos > 0) {
            window.scrollTo(0, this.pos - 40); // how far to scroll on each step
        } 
        else {
            window.clearInterval(this.scrollToTop);  
        }
    }, 2);
}
}