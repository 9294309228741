<p class="mb-0 BreadcbTop">
    <a routerLink="/">Home</a> >
     <a routerLink="/news">News</a> >
      <a  class="para-2-c-1 Breadpara">Top Stories</a>
      
</p>
<h1 class="text-uppercase">Top Stories</h1>
<div class="row" *ngIf='featureLoaded==false'>
    <div class="col-md-4 pb-5" *ngFor="let d of dumArray">
        <list-content-loader></list-content-loader>
    </div>
</div>
<div class="row d-md-flex WrapArt" *ngIf="featureLoaded">
    <div class="col-md-4 pb-5" *ngFor="
    let featureNewsData of featureNewsDatas | paginate : {
            itemsPerPage: pageSize,
            currentPage: page,
            totalItems: count
          };
    let i = index
  " [class.active]="i == currentIndex">
  <div>
        <!-- <div class="cursor-p" routerLink='{{featureNewsData.RouteURL}}/{{featureNewsData.ID}}'> -->
            <!-- <a routerLink='{{featureNewsData.RouteURL}}/{{featureNewsData.ID}}' target="_blank"> -->
        <a href='https://{{envSiteName}}/{{featureNewsData.EventDifferentType  | lowercase }}/{{featureNewsData.RouteURL}}' target="_blank">

            <img class="img-fluid pb-2 image-hight-news" [src]="featureNewsData.ImageUrl" alt="news image">
        </a>    
            <p class="para-2 mb-0 CatTag" *ngIf="featureNewsData.Tag!=' '; else BlankTag">{{featureNewsData.Tag}}</p>
            <ng-template #BlankTag>
                <p class="para-2 mb-0 tag"></p>
            </ng-template>
        <a href='https://{{envSiteName}}/{{featureNewsData.EventDifferentType  | lowercase }}/{{featureNewsData.RouteURL}}' target="_blank">
            <h4 class="text-height-4 card-header-height-1 text-uppercase TagTitle" [innerHTML]="featureNewsData.Title">

            </h4>
        </a>
            <p class="para-2 mb-1 sponDate">{{featureNewsData.CompanyName}} <span *ngIf='featureNewsData.CompanyName!="" && featureNewsData.PublishingDate!=""'>|</span> {{featureNewsData.PublishingDate}} 
                <span *ngIf="featureNewsData.IsSponsored"><a>| </a> <font class="para-2-c-2">Sponsored</font></span>
            </p>
            <p class="para-1 text-justify text-height-4 DescPara" [innerHTML]="featureNewsData.Description"></p>
            <!-- <a class="para-2 para-2-c-2 " target="_blank" href='news/featured-news/{{featureNewsData.RouteURL}}/{{featureNewsData.ID}}'>Read More</a> -->
            <a class="para-2 para-2-c-2 Read_More" target="_blank" href='https://{{envSiteName}}/{{featureNewsData.EventDifferentType  | lowercase }}/{{featureNewsData.RouteURL}}'>Read More</a>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-12 p-0 text-center mt-md-0" *ngIf="count>9">
        <pagination-controls previousLabel="Prev" nextLabel="Next" responsive="true" (pageChange)="handlePageChange($event)"></pagination-controls>
    </div>
</div>