import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpclientService } from '../../../../../src/app/modules/application/httpclient/httpclient.service';
import { Title, Meta } from '@angular/platform-browser';
import { CanonicalService } from 'src/app/canonical.service';

@Component({
  selector: 'app-terms-page',
  templateUrl: './terms-page.component.html',
  styleUrls: ['./terms-page.component.scss']
})
export class TermsPageComponent implements OnInit {
  scrollToTop: number;
  pos: number;
  envSiteName:any;
  Site_Domain:any;
  preSite: any;
  
  loaded:boolean;
  iData:any;
  currentYear:any;
  constructor(private httpclientService: HttpclientService,private metaTagService:Meta,private canonical:CanonicalService,private titleService: Title) {
    this.envSiteName=environment.siteName;
   }

  ngOnInit(): void {
    this.onActivate();
    this.LogoData();
    this.currentYear = new Date().getFullYear();
    this.metaTagService.updateTag(
      {name:'robots',content:'index,follow'}
    );
  }
  LogoData(): void {
    this.loaded = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/Home/GetMasterMetaCode?PubsiteName='+this.envSiteName)
      .subscribe(
          items=> {
          this.iData = items[0];
          this.preSite = this.iData.PreSite;
          this.Site_Domain=this.iData.Site_Domain;
          this.loaded = true;

          this.canonical.CreateCanonicalLink();

          this.titleService.setTitle('Terms of Services | '+this.Site_Domain);

          this.metaTagService.updateTag(
            {name:'title',content:'Terms of Services | '+this.Site_Domain}
          );
      
          this.metaTagService.updateTag(
            {name:'description',content:'Read about all terms of the information, content, services, and software displayed on the site.'}
          );
      
          this.metaTagService.updateTag(
            {name:'keywords',content:'terms of services'}
          );
        });
  }
  onActivate() {
    this.scrollToTop = window.setInterval(() => {
        this.pos = window.pageYOffset;
        if (this.pos > 0) {
            window.scrollTo(0, this.pos - 40); // how far to scroll on each step
        } 
        else {
            window.clearInterval(this.scrollToTop);  
        }
    }, 2);
}


}
