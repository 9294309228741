import { Component, OnInit } from '@angular/core';
import { HttpclientService } from '../../../application/httpclient/httpclient.service';
import { environment } from '../../../../../../src/environments/environment';

@Component({
  selector: 'app-resources-news',
  templateUrl: './resources-news.component.html',
  styleUrls: ['./resources-news.component.scss']
})
export class ResourcesNewsComponent implements OnInit {

  resDatas: any;
  loaded: boolean;
  envSiteName:any;

  constructor(private httpclientService: HttpclientService) {
    this.envSiteName=environment.siteName;
   }
  

  ngOnInit(): void {
    this.resourceData();
  }
  resourceData(): void {
    this.loaded = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/News/GetNewsResources?siteName='+this.envSiteName)
      .subscribe(
        items => {
          this.resDatas = items[0];
          this.loaded = true;
          //console.log("Resource News Data:", this.resDatas);
        });
  }

}
