<form [formGroup]="downloadForm" (ngSubmit)="onSubmit()">

    <p class="text-center pop-para">Enter your details once to access all our information and resources
    </p>
    <div class="form-group">
        <input formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" type="email" class="form-control" id="txtDownload" aria-describedby="emailHelp" placeholder="*Email">

        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.required">Required</div>
            <div *ngIf="f.email.errors.email">Invalid email address</div>
        </div>
    </div>


    <div class="form-group form-check">
        <input type="checkbox" formControlName="acceptTerms" id="acceptTerms" class="form-check-input" [ngClass]="{ 'is-invalid': submitted && f.acceptTerms.errors }" />
        <label for="acceptTerms" class="form-check-label pop-para" > By downloading this content, you give permission for your contect
            information to be shared with the content provider who may contact you in
            regards to the content.</label>
        <!-- <div *ngIf="submitted && f.acceptTerms.errors" class="invalid-feedback"></div> -->
    </div>

    <button class="text-uppercase thm-btn btn-theme-success w-100 pt-3 pb-3"> DOWNLOAD</button>

</form>