import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'; //import router
import { HttpclientService } from '../../../application/httpclient/httpclient.service';
import { state, trigger, transition, style, animate } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { environment } from '../../../../../../src/environments/environment';

@Component({
  selector: 'app-on-demandwebinar-single-page',
  templateUrl: './on-demandwebinar-single-page.component.html',
  styleUrls: ['./on-demandwebinar-single-page.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        width: '200px',
        opacity: 1,
        display:'block',
      })),
      state('closed', style({
        width: '0px',
        opacity: 0,
        display:'none',
      })),
      transition('open => closed', [
        animate('0.5s')
      ]),
      transition('closed => open', [
        animate('0.5s')
      ]),
    ]),
  ],
})
export class OnDemandwebinarSinglePageComponent implements OnInit {

  href:string;
  lastString:string;
  receiveRefData:any;
  singleOnDemandLoaded:boolean;
  singleOnDemand:any;
  eventsEventID:any;
  isOpen = false;
  isOverlay = false;
  fullUrl:any;
  ondemandwebinarsid:any;
  envSiteName:any;
  shortSiteName:string;
  firstString: any;
  titlePrefix:string;
  loaded:boolean;
  iData:any;

  constructor(private httpclientService: HttpclientService,public router:Router,private titleService: Title,private metaTagService: Meta,  private activatedRoute: ActivatedRoute) {
    this.envSiteName=environment.siteName;
   }

  ngOnInit(): void {
    this.singleOnDemandData();
    this.LogoData();
    this.fullUrl=window.location.href;
    this.ondemandwebinarsid = this.activatedRoute.snapshot.params.id;
  }
  LogoData(): void {
    this.loaded = false;
    this.httpclientService.getHttpclient('https://api.nathanark.com/api/Home/GetMasterMetaCode?PubsiteName='+window.location.hostname)
      .subscribe(
        (  items: any) => {
          this.iData = items;
          this.titlePrefix = this.iData.PreSite;
          this.loaded = true;
        });
  }

  receiveRefresh($event){
    this.resetUsers();
    this.eventsEventID=$event;
    //console.log("receiveRefData",this.lastString);
    this.singleOnDemandData();
    //console.log("receiveRefData",this.lastString);
  }
  singleOnDemandData(){
    this.shortSiteName=this.envSiteName.substr(0, this.envSiteName.indexOf('.')); 
    if(this.eventsEventID != null){
      this.ondemandwebinarsid=this.eventsEventID;
  }else{
    this.ondemandwebinarsid =  this.activatedRoute.snapshot.params.id;
  }
    
    this.singleOnDemandLoaded=false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/SingleLiveWebinar/GETSingleGetOnDemandWebinarByID?SiteName='+this.shortSiteName+'&EventID='+this.ondemandwebinarsid)
      .subscribe(
        items => {
          this.singleOnDemand = items[0];
          this.singleOnDemandLoaded=true;
          //console.log(this.singleOnDemand);

             //seo code for tags
        this.titleService.setTitle(this.singleOnDemand.Name+' | '+this.titlePrefix);
        this.metaTagService.updateTag(
          { name: 'og:description', content: this.singleOnDemand.Details}
        );
        this.metaTagService.updateTag(
          { name: 'og:title', content: this.singleOnDemand.Name+' | '+this.titlePrefix}
        );
        this.metaTagService.updateTag(
          { name: 'og:image', content: this.singleOnDemand.ImageUrl}
        );
        this.metaTagService.updateTag(
          { name: 'twitter:description', content: this.singleOnDemand.Details}
        );
        this.metaTagService.updateTag(
          { name: 'twitter:title', content: this.singleOnDemand.Name+' | '+this.titlePrefix}
        );
        this.metaTagService.updateTag(
          { name: 'twitter:image', content: this.singleOnDemand.ImageUrl}
        );
        //seo code for tags end
      });

  }
  resetUsers(): void {
    this.singleOnDemand = null;
    this.singleOnDemandLoaded = false;
    this.lastString=null;
    this.href=null;
  }

  shareClick(){
    this.isOpen = !this.isOpen;
  }

  redirect(getUrl:any,getId:any)
  {
    this.firstString = getUrl.substring(0,5);
    if(this.firstString ==="https")
    {
      window.open(getUrl, "_blank");
    }else
    {
      this.router.navigate(['/events/on-demand-webinars/'+getUrl+"/"+getId]);
    }
  }

}
