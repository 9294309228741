<h2 class="text-uppercase mb-0 GNewsResoEve SpotEveRes">
    Events</h2>
<div class="row EveGDiv" *ngIf="loaded">
    <div class="col-md-12 GNewsReEDiv" *ngFor="let eventData of eventDatas">
        <!-- <div class="cursor-p" (click)="redirect(eventData.RouteURL,eventData.EventId)"> -->
            
        <!-- <div class="cursor-p" routerLink='{{eventData.RouteURL}}'> -->
            <a target="_blank" routerLink="{{eventData.RouteURL}}">
            <img class="resources-img-h pb-2 pr-5" [src]='eventData.ImageUrl' alt="news image">
            <h4 class="text-uppercase EventTitle">{{eventData.Name}}
            </h4>
        </a>
            <!-- </div> -->
            <p class="para-3 text-capitalize">Conference</p>
       
</div>
</div>

