<h2 class="text-uppercase text-center mb-0 mt-3">
    Spotlight</h2>
<div class="text-center pt-3" *ngIf="loaded">
    
    <!-- <img class="img-fluid" [src]="spotLightDatas.ImageUrl"  alt="Responsive image">
    <h4 class="text-uppercase mt-2 text-center">{{spotLightDatas.Name}}</h4>
    <p class="text-justify para-1 spotlight-text-height" [innerHTML]=" spotLightDatas.Description"></p> -->


    <!-- <div class="cursor-p" routerLink="/companies/all-companies/{{spotLightDatas.RouteURL}}/{{spotLightDatas.ID}}"> -->
        <a  routerLink='/infographics/{{spotLightDatas.RouteURL}}' >
            <img [src]="spotLightDatas.ImageUrl" class="img-fluid" alt="Responsive image">
            <h4  class="text-uppercase mt-2 text-center">{{spotLightDatas.Name}}
                
            </h4>
        </a>
    <!-- </div> -->
    <p class="text-justify para-1 spotlight-text-height" [innerHTML]="spotLightDatas.Description"></p>
</div>

