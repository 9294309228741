import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CompaniesComponent } from './companies.component';
import { MarketplaceComponent } from './marketplace/marketplace.component';
import { AllCSuiteondeckComponent } from './all-c-suiteondeck/all-c-suiteondeck.component';
import { ThoughtLeadersComponent } from './thought-leaders/thought-leaders.component';
import { SingleCompanyPageComponent } from './single-company-page/single-company-page.component';
import { GlobalGuestAuthorsComponent} from '../resources/global-guest-authors/global-guest-authors.component';
//import { SingleCSiuteInterviewComponent } from './single-c-siute-interview/single-c-siute-interview.component';


import { SingleGuestAuthorsProfileComponent } from '../resources/single-guest-authors-profile/single-guest-authors-profile.component';
import { AllCompaniesComponent } from './all-companies/all-companies.component';
import { SingleCSuiteInterviewComponent } from './single-c-suite-interview/single-c-suite-interview.component';
import { SingleThoughtLeaderComponent } from './single-thought-leader/single-thought-leader.component';


const routes: Routes = [
  { path: '', component: CompaniesComponent, children:[
    {
      path: 'companies',
      loadChildren:() => import('./companies.module')
      .then(mod => mod.CompaniesModule)
    },
    {path: '',component: AllCompaniesComponent},
     //{path: '', component: MarketplaceComponent},
     {path: 'c-suite-on-deck', component: AllCSuiteondeckComponent},
     {path: 'thought-leaders', component: ThoughtLeadersComponent},
     {path: 'all-companies/:title/:id', component: SingleCompanyPageComponent},
     {path: 'all-c-suiteondeck/:title',component: SingleCSuiteInterviewComponent},
     {path:'single-guest-authors/:title/:id',component:SingleGuestAuthorsProfileComponent},
     {path: 'globalGuestAuthors', component: GlobalGuestAuthorsComponent},
     {path: 'thought-leaders/:title/:id', component: SingleThoughtLeaderComponent},
     
    {
      path: '',
      redirectTo: '/companies',
      pathMatch: 'full'
    }
  ] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CompaniesRoutingModule { }
