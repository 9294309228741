import { ChangeDetectorRef, Component, ElementRef, KeyValueDiffer, KeyValueDiffers, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SharedDataService } from 'src/app/modules/application/shared-data/shared-data.service';

@Component({
  selector: 'app-all-webiners',
  templateUrl: './all-webiners.component.html',
  styleUrls: ['./all-webiners.component.scss']
})
export class AllWebinersComponent implements OnInit {

  href:any;
  lastString:string;
  private differ: KeyValueDiffer<any, any>;
  constructor(private router:Router,public sharedDataService: SharedDataService,
    private keyValueDiffers: KeyValueDiffers,
    private changeDetector: ChangeDetectorRef
    ) { }

  ngOnInit(): void {
    this.href = this.router.url;
    this.lastString = this.href.substring(this.href.lastIndexOf('/') + 1)
    this.differ = this.keyValueDiffers.find(this.sharedDataService).create();
  }

  ngAfterViewInit(): void {
    this.DoClick();
  }

  ngDoCheck() {
    if (this.differ.diff(this.sharedDataService) != null) {
      this.DoClick();
    }
  }

  DoClick() : void {
    if ((!this.sharedDataService.liveWebinarFound) && this.sharedDataService.onDemandWebinarFound) {
      this.onDemandWebinarLink?.nativeElement.click();
    }
    if ((this.sharedDataService.liveWebinarFound) && (!this.sharedDataService.onDemandWebinarFound)) {
      this.liveWebinarLink?.nativeElement.click();
    }
  }

  @ViewChild('OnDemandWebinars') onDemandWebinarLink: ElementRef;
  @ViewChild('LiveWebinars') liveWebinarLink: ElementRef;

}
